import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ComunicadosService {
    constructor() {}

    getComunicado() {
        return {
            projeto_homologado: {
                projeto: 'MARIS TESTE',
                data: '08/08/2018'
            },
            projeto_aprovado: {
                projeto: 'MARIS TESTE',
                data: '08/08/2018'
            },
            leitura: '15/02/2018'
        };
    }
}
