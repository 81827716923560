import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { isSet } from 'src/app/shared/is/is-set';

@Component({
  selector: 'app-button-radio',
  templateUrl: './button-radio.component.html',
  styleUrls: ['./button-radio.component.scss'],
})

export class ButtonRadioComponent implements OnInit {
  @Output() onClick = new EventEmitter<boolean>();
  @Output() onFocus = new EventEmitter<boolean>();
  @Output() onBlur = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<string>();

  @Input() cor: string = 'primary';
  @Input() name: string;
  @Input() id: string;
  @Input() valor: string;
  @Input() texto: string;
  @Input() formGroup: FormGroup;

  @Input() desativado: boolean = false;
  @Input() selecionado: boolean = false;

  @Input() outline: boolean;
  @Input() square: boolean;
  @Input() rounded: boolean;
  @Input() glow: boolean;
  @Input() shadow: boolean;
  @Input() large: boolean;
  @Input() small: boolean;
  @Input() icon: boolean;

  classes: String[];

  constructor() {}

  ngOnInit() {
    this.adicionaClasses();
  }

  adicionaClasses(): void {
    this.classes = [`btn-${this.cor}`];
    if(isSet(this.outline)) this.classes = [`btn-outline-${this.cor}`];
    if(isSet(this.glow)) this.classes.push(`btn-glow-${this.cor}`);
    if(isSet(this.square)) this.classes.push(`btn-square`);
    if(isSet(this.rounded)) this.classes.push(`rounded`);
    if(isSet(this.shadow)) this.classes.push(`shadow-1`);
    if(isSet(this.large)) this.classes.push(`btn-lg`);
    if(isSet(this.icon)) this.classes.push(`btn-icon`);
  }

  isSet(item): boolean {
    return (item !== undefined || item === true);
  }

  eventoClick(): void {
    this.onClick.emit(true);
  }

  eventoFocus(): void {
    this.onFocus.emit(true);
  }

  eventoBlur(): void {
    this.onBlur.emit(true);
  }
  eventoChange(): void {
    this.onChange.emit(this.formGroup.controls[this.name].value);
  }
}
