<div class="card">
    <div
        class="d-flex card-header"
        *ngIf="buttons.length == 0 && (titulo || note)"
    >
        <h5 class="mr-auto">{{ titulo }}</h5>
        <small class="text-muted" *ngIf="note && !noteIcon">
            {{ note }}
        </small>
        <app-icone
            (click)="eventoClick()"
            [nome]="noteIcon"
            tamanho="50"
            posicao="right"
        ></app-icone>
    </div>
    <div class="card-header" *ngIf="buttons.length > 0 && (titulo || note)">
        <div class="d-flex card-header">
            <h5 class="mr-auto">{{ titulo }}</h5>
            <small class="text-muted" *ngIf="note && !noteIcon">
                {{ note }}
            </small>
            <app-icone
                [nome]="noteIcon"
                tamanho="50"
                posicao="right"
            ></app-icone>
        </div>
        <div class="buttons">
            <app-button *ngFor="let button of buttons" [url]="button.url">
                <app-icone [nome]="button.icone" posicao="left"></app-icone>
                {{ button.nome }}
            </app-button>
        </div>
    </div>
    <div class="card-block">
        <ng-content select="[body]"></ng-content>
    </div>
    <div class="card-footer">
        <small class="text-muted">
            <ng-content select="[footer]"></ng-content>
        </small>
    </div>
</div>
