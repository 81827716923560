import { Injectable } from '@angular/core';
import { TabelaCampo } from 'src/app/shared/tabela/tabela-campo';

@Injectable({
    providedIn: 'root'
})
export class MandadoETermosService {
    campos: TabelaCampo[];
    constructor() {}
    getAgenda() {
        return {
            campos: [
                {
                    nome: 'Data Inicio',
                    campo: 'inicio'
                },
                {
                    nome: 'Data Fim',
                    campo: 'fim'
                },
                {
                    nome: 'Agenda',
                    campo: 'agenda'
                },
                {
                    nome: 'Identificador',
                    campo: 'id'
                },
                {
                    nome: 'Atividade',
                    campo: 'atividade'
                },
                {
                    nome: 'Solicitante',
                    campo: 'solicitante'
                },
                {
                    nome: 'Tipo Medida de Gestão',
                    campo: 'tipo-medida-gestao'
                },
                {
                    nome: 'Situação',
                    campo: 'situacao'
                },
                {
                    nome: 'Aprovar',
                    campo: 'aprovar'
                },
                {
                    nome: 'Rejeitar',
                    campo: 'rejeitar'
                }
            ],
            dados: []
        };
    }
    getMatriculas() {
        return {
            campos: [
                {
                    nome: 'Nº da Matrícula',
                    campo: 'matricula'
                },
                {
                    nome: 'Nome',
                    campo: 'nome'
                }
            ],
            dados: []
        };
    }

    getMonitoramento() {
        return {
            campos: [
                {
                    nome: 'Visualizar',
                    campo: 'Visualizar'
                },
                {
                    nome: 'Tipo',
                    campo: 'Tipo'
                },
                {
                    nome: 'Nome',
                    campo: 'Nome'
                },
                {
                    nome: 'Número',
                    campo: 'Número'
                },
                {
                    nome: 'Contribuinte',
                    campo: 'Contribuinte'
                },
                {
                    nome: 'Órgão de Execução',
                    campo: 'Órgão de Execução'
                },
                {
                    nome: 'Supervisor',
                    campo: 'Supervisor'
                },
                {
                    nome: 'Agentes Fiscais',
                    campo: 'Agentes Fiscais'
                },
                {
                    nome: 'Situação da Ciência',
                    campo: 'Situação da Ciência'
                },
                {
                    nome: 'Data da Ciência',
                    campo: 'Data da Ciência'
                },
                {
                    nome: 'Vencimento Mandados e Termos',
                    campo: 'Vencimento Mandados e Termos'
                }
            ]
        };
    }

    getMonitoramentoEmpresa() {
        return {
            campos: [
                {
                    nome: 'Situação Anterior da empresa',
                    campo: 'Situação Anterior da empresa'
                },
                {
                    nome: 'Situação Atual da Empresa no Monitoramento',
                    campo: 'Tipo de Projeto'
                },
                {
                    nome: 'Inscrição Estadual',
                    campo: 'Inscrição Estadual'
                },
                {
                    nome: 'Não Contribuinte',
                    campo: 'Não Contribuinte'
                },
                {
                    nome: 'Razão Social',
                    campo: 'Razão Social'
                },
                {
                    nome: 'Nº PAM',
                    campo: 'Nº PAM'
                },
                {
                    nome: 'Agente Fiscal',
                    campo: 'Agente Fiscal'
                },
                {
                    nome: 'Supervisor',
                    campo: 'Supervisor'
                },
                {
                    nome: 'Órgão Executor do Monitoramento Fiscal',
                    campo: 'Órgão Executor do Monitoramento Fiscal'
                }
            ],
            dados: []
        };
    }
}
