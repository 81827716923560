    <div [id]="id">
        <ul>
            <li
            *ngFor="let item of itens">
                <a [routerLink]="item.url">
                    <h6>{{item.titulo}}</h6>
                    <p class="m-0">{{item.texto}}</p>
                </a>
            </li>
        </ul>
    </div>
    