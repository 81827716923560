import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { MenuService } from '../../shared/menu/menu.service';
import { Menu } from '../../shared/menu/menu';
import { UserService } from 'src/app/shared/user/user.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    menus: Menu[] = [];
    currentUrl: string;
    openMenu = true;
    showSubMenu = false;

    constructor(
        private menuService: MenuService,
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.getMenu();
    }

    setMenu(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url.split('/')[1];
                this.menus.forEach(menu => {
                    if (menu.url === event.url.replace('/', '')) {
                        this.setActive(menu);
                    }
                });
            }
        });
    }

    setActive(menu: object): void {
        this.menuService.setMenu(menu);
    }

    toggleMenu(): void {
        this.openMenu = !this.openMenu;
        this.menuService.setStatus(this.openMenu);
    }

    getMenu(): void {
        this.menuService.all().subscribe(menus => {
            this.menus = menus;
            this.setMenu();
        });
    }

    validarSaida() {
        this.userService.logout();
        this.router.navigate(['acesso']);
    }
}
