<app-conteudo titulo="Solicitação de Medidas de Gestão">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="d-flex card-header">
                            <h5 class="mr-auto">Solicitação</h5>
                        </div>
                        <div class="card-body">
                            <button type="button"
                                     class="p-0 px-2 btn btn-success form-control-sm"
                                     (click)="abrirSolicitacaos()"
                                     [disabled]="solicitacao == true"
                                     mask="00.000.000-0">
                                <span>Adicionar Solicitação</span>
                            </button>

                            <button [disabled]="solicitacao == true"
                                     (click)="cancelarSolicitacao()"
                                     type="submit"
                                     class="p-0 px-2 btn btn-success ml-2 form-control-sm">
                                 <span>Cancelar Solicitação</span>
                            </button>
                        </div>
                    </div>
                    <div class="card"
                         *ngIf="solicitacao == true">
                        <div class="d-flex card-header">
                            <h5 class="mr-auto">Adicionar Solicitação</h5>
                        </div>
                        <div class="card-body">
                                <form [formGroup]="formFiltro"
                                (ngSubmit)="filter()">
                            <div class="row">
                                <div class="form-group col-md-5">
                                    <label for="codSolicitacao">Cód. da Solicitação</label>
                                    <input type="text"
                                             class="form-control form-control-sm "
                                             id="codSolicitacao"
                                             formControlName="solicitacao"
                                              />
                                              <div
                                    *ngIf="
                                        formComunRec.controls.solicitacao.invalid &&
                                        (formComunRec.controls.solicitacao.dirty ||
                                            formComunRec.controls.solicitacao.touched)
                                    "
                                    class="invalid-feedback d-block"
                                >
                                    <div
                                        *ngIf="
                                            formComunRec.controls.solicitacao.errors
                                                .required
                                        "
                                    >
                                        O campo é obrigatório.
                                    </div>
                                </div>
                                </div>
                                <div class="form-group col-md-7">
                                    <label for="medidasGestao">
                                        Medidas de gestão</label>
                                    <select class="form-control form-control-sm"
                                            id="medidasGestao">
                                        <option>Fiscalização de Empresas</option>
                                        <option>Monitoramento Fiscal</option>
                                        <option>Descredenciamento</option>
                                        <option>Inclusão de Regime Especial de Fiscalização - Caixa</option>
                                        <option>Inclusão em Regime Especial de Fiscalização - Fronteira</option>
                                        <option>Exclusão do Simples Nacional</option>
                                        <option>Outras Medidas de Gestão</option>
                                        <option>Exclusão do Monitoramento Fiscal</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-5">
                                    <label for="filter"
                                           class="col-form-label">
                                           Inscrição Estadual
                                    </label>
                                    <div class="input-group mb-3">
                                        <input type="text"
                                               class="form-control form-control-sm"
                                               aria-label="Recipient's username"
                                               aria-describedby="button-addon2"
                                               mask="00.000.000-0"
                                                />
                                        <div class="input-group-append">
                                            <button (click)="
                                                    abrirModalPesquisaEmpresa()
                                                "
                                                    class="btn btn-success"
                                                    style="padding: 0px 20px;"
                                                    type="button"
                                                    id="button-addon2">
                                                Consultar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-7">
                                    <label for="filter"
                                           class="col-form-label">
                                        Razão Social
                                    </label>
                                    <input type="text"
                                           class="form-control form-control-sm"
                                           id="cgfDescricao"
                                            />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="filter"
                                           class="col-form-label">
                                        Justificativa
                                    </label>
                                    <input type="text"
                                             maxlength="100"
                                             class="form-control form-control-sm"
                                             id="cgf" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <button type="submit"
                                            class="p-0 btn btn-secondary form-control form-control-sm"
                                            (click)="cancelarSolicitacao()">
                                        <span>
                                            Fechar
                                        </span>
                                    </button>
                                </div>
                                <div class="col-md-4">
                                    <button type="button"
                                            class="p-0 btn btn-success  form-control form-control-sm"
                                            (click)="filter()">
                                        <span>
                                            Salvar Solicitação
                                        </span>
                                    </button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>

                    <div class="card"
                    *ngIf="visualizacao == true">
                   <div class="d-flex card-header">
                       <h5 class="mr-auto">Visualizar Solicitação</h5>
                   </div>
                   <div class="card-body">
                       <div class="row">
                           <div class="form-group col-md-5">
                               <label for="codSolicitacao">Cód. da Solicitação</label>
                               <input type="text"
                                        class="form-control form-control-sm "
                                        id="codSolicitacao"
                                        disabled
                                        placeholder="324749"
                                         />
                           </div>
                           <div class="form-group col-md-7">
                               <label for="medidasGestao">
                                   Medidas de gestão</label>
                                   <input type="text"
                                   class="form-control form-control-sm "
                                   id="codSolicitacao"
                                   disabled
                                   placeholder="Fiscalização de Empresas"
                                    />
                           </div>
                       </div>
                       <div class="row">
                           <div class="form-group col-md-5">
                               <label for="filter"
                                      class="col-form-label">
                                    Inscrição Estadual
                               </label>
                               <div class="input-group mb-3">
                                   <input type="text"
                                          class="form-control form-control-sm"
                                          aria-label="Recipient's username"
                                          aria-describedby="button-addon2"
                                          disabled
                                   placeholder="3743.333.23" />
                               </div>
                           </div>
                           <div class="form-group col-md-7">
                               <label for="filter"
                                      class="col-form-label">
                                   Razão Social
                               </label>
                               <input type="text"
                                     class="form-control form-control-sm"
                                     id="cgfDescricao"
                                     disabled
                                     placeholder="Sophia Rodrigues Confecções LTDA"
                                       />
                           </div>
                       </div>
                       <div class="row">
                           <div class="form-group col-md-12">
                               <label for="filter"
                                      class="col-form-label">
                                   Justificativa
                               </label>
                               <input type="text"
                                     maxlength="100"
                                     class="form-control form-control-sm"
                                     id="cgf"
                                     disabled
                                     placeholder="Prazo de validade vencido." />
                           </div>
                       </div>
                       <div class="row">
                           <div class="col-md-4">
                               <button type="submit"
                                       class="p-0 btn btn-secondary form-control form-control-sm"
                                       (click)="cancelarVisualizacao()">
                                   <span>
                                       Fechar Visualização da Solicitação
                                   </span>
                               </button>
                           </div>
                       </div>
                   </div>
               </div>

                    <div class="card">
                        <div class="d-flex card-header">
                            <h5 class="mr-auto">
                                Solicitação de Medidas de Gestão
                            </h5>
                        </div>
                        <div class="card-body">
                            <app-tabela [dados]="headerTable.dados"
                                        [campos]="headerTable.campos"
                                        (openOptions)="action($event)"
                                     >
                            </app-tabela>
                        </div>
                    </div>

                    <div class="card">
                        <div class="d-flex card-header">
                            <h5 class="mr-auto">
                                 Legenda de Situações das Solicitações
                            </h5>
                        </div>
                        <div class="row p-2"
                             footer>
                            <div class="d-flex justify-content-center col-12">
                                <div class="col-sm">
                                    <i class="circle-ligth-blue"></i>
                                    Solicitação em Aberto
                                </div>
                                <div class="col-sm">
                                    <i class="circle-yellow"></i>
                                    Solicitação Homologada
                                </div>
                                <div class="col-sm">
                                    <i class="circle-purple"></i>
                                    Homologado Parciamente
                                </div>
                            </div>
                            <div class="d-flex justify-content-center p-t-15 col-12">
                                <div class="col-sm">
                                    <i class="circle-green"></i>
                                    Solicitação Autorizada
                                </div>
                                <div class="col-sm">
                                    <i class="circle-blue"></i>
                                    Autorizado Parcialmente
                                </div>
                                <div class="col-sm">
                                    <i class="circle-red"></i>
                                    Solicitação Rejeitada
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-conteudo>

<app-modal id="PesquisaEmpresaModal"
           titulo="Pesquisa Empresa"
           tamanho="lg">
    <div class="container">
        <form>
            <div class="form-row ">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="matricula">Inscrição Estadual:</label>
                        <input type="text"
                               class="form-control form-control-sm"
                               id="matricula" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nome">Razão Social:</label>
                        <input type="text"
                                 class="form-control form-control-sm"
                                 id="nome" />
                    </div>
                </div>
                <div class="col-md-4">
                    <label style="color:white">.</label>
                    <button type="submit"
                            class="p-0 btn btn-success form-control form-control-sm">
                        <span>Pesquisar</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-tabela [dados]="empresaTable.dados"
                                 [campos]="empresaTable.campos"></app-tabela>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button disabled
                             type="button"
                             class="p-0 btn btn-success form-control col-sm-2 form-control-sm float-right">
                        <span>Selecionar</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</app-modal>