import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AgenteFiscalService {
    constructor() {}

    getAgenda() {
        return {
            campos: [
                {
                    nome: 'Agenda',
                    campo: 'Agenda'
                },
                {
                    nome: 'Data Inicio',
                    campo: 'Data Inicio'
                },
                {
                    nome: 'Data Fim',
                    campo: 'Data Fim'
                },
                {
                    nome: 'Identificador',
                    campo: 'Identificador'
                },
                {
                    nome: 'Atividade',
                    campo: 'Atividade'
                },
                {
                    nome: 'Tipo Medida de Gestão',
                    campo: 'Tipo Medida de Gestão'
                },
                {
                    nome: 'Solicitante',
                    campo: 'Solicitante'
                },
                {
                    nome: 'Situação',
                    campo: 'Situação'
                },
                {
                    nome: 'Aprovar',
                    campo: 'Aprovar'
                },
                {
                    nome: 'Rejeitar',
                    campo: 'Rejeitar'
                },
                {
                    nome: 'Ordem',
                    campo: 'Ordem'
                },
            ],
            dados: []
        };
    }

    getMatriculas() {
        return {
            campos: [
                {
                    nome: 'Nº da Matrícula',
                    campo: 'matricula'
                },
                {
                    nome: 'Nome',
                    campo: 'nome'
                }
            ],
            dados: []
        };
    }

    getMonitoramento() {
        return {
            campos: [
                {
                    nome: 'Rejeitar Designação',
                    campo: 'rejeitar'
                },
                {
                    nome: 'Situação do Monitoramento Fiscal',
                    campo: 'situacao'
                },
                {
                    nome: 'Inscrição Estadual',
                    campo: 'inscricao_estadual'
                },
                {
                    nome: 'Não Contribuinte',
                    campo: 'nao_contribuinte'
                },
                {
                    nome: 'Razão Social',
                    campo: 'razao_social'
                },
                {
                    nome: 'Nº do PAM',
                    campo: 'pam'
                },
                {
                    nome: 'Nome do Projeto',
                    campo: 'projeto'
                }
            ],
            dados: []
        };
    }
}
