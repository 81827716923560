import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

declare var Slider: any;

@Component({
  selector: 'app-ranger-slider',
  templateUrl: './ranger-slider.component.html',
  styleUrls: ['./ranger-slider.component.scss'],
})

export class RangerSliderComponent implements OnInit {

  @Input() name: string;
  @Input() id: string;
  @Input() idSlider: string;
  @Input() min: number;
  @Input() max: number;
  @Input() step: string;
  @Input() value: any;

  @Input() desativado: boolean = false;

  constructor() {}

  ngOnInit() {
    const slider = new Slider('#' + this.id, {
        step: this.step,
        min: this.min,
        max: this.max,
        value: this.value
    });
  }
}
