import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['progress-bar.component.scss']
})
export class ProgressBarComponent {

  @Input() total: number;
  @Input() parcial: number;
  @Input() cor: string;
  @Input() height: string = '12px';
  @Input() mostrarPorcentagem: boolean;
  @Input() mostrarListras: boolean;
  @Input() mostrarAnimacao: boolean;

  get porcentagem(): number {
    const porcentagem = (this.parcial / this.total) * 100;
    return porcentagem;
  }
}
