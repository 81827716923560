import { Component, Input, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    dataPublicacao: string = '20190401';
    numeroPublicacao: number = 1;
    version: string;

    classes: any[] = [];
    @Input() full: boolean;

    constructor() {
        this.version = environment.version;
    }

    ngOnInit(): void {
        if (this.full) {
            this.classes.push('navbar-login-page');
        }
    }
}
