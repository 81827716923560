import { Component, Input, OnInit } from '@angular/core';

import { IconeTamanho } from './icone.tamanho.enum';
// import { IconeFlip } from './icone.flip.enum';
import { isSet } from 'src/app/shared/is/is-set';

@Component({
  selector: 'app-icone',
  templateUrl: './icone.component.html',
  styleUrls: ['./icone.component.scss']
})
export class IconeComponent implements OnInit {
  @Input()
  nome: String = 'home';

  @Input()
  tamanho: IconeTamanho = '';

  // @Input()
  // flip: IconeFlip = '';

  @Input()
  posicao: String = '';
  classes: String[] = [];

  ngOnInit(): void {
    this.gerarClasses();
  }

  gerarClasses(): void {
    this.classes = [`icon-${this.nome}`];

    if (isSet(this.tamanho)) this.classes.push(`icon-${this.tamanho}`);
    // if (isSet(this.flip)) this.classes.push(`icon-flip-${this.flip}`);

    if (this.posicao) {
      if (this.posicao === 'rigth') this.classes.push('ml');
      if (this.posicao === 'left') this.classes.push('mr');
    }
  }
}
