<app-conteudo titulo="Dashboard">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="row">
                <div class="col-md-12 col-xl-12">
                    <app-card titulo="Mapa">
                        <div body>
                            <app-map
                                lat="21.217319"
                                lng="72.866472"
                                id="mapaTeste"
                            ></app-map>
                        </div>
                    </app-card>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-xl-4">
                    <app-card titulo="Empresas">
                        <div class="row  align-items-center" body>
                            <div class="col-8">
                                <h2 class=" m-0">690</h2>
                                <span class="text-muted">Cadastradas</span>
                            </div>
                        </div>
                    </app-card>
                </div>

                <div class="col-md-6 col-xl-4">
                    <app-card titulo="Usuários">
                        <div class="row align-items-center" body>
                            <div class="col-8">
                                <h2 class=" m-0">313</h2>
                                <span class="text-muted">Online</span>
                            </div>
                        </div>
                    </app-card>
                </div>

                <div class="col-md-6 col-xl-4">
                    <app-card titulo="Custos">
                        <div class="row align-items-center" body>
                            <div class="col-10">
                                <h2 class=" m-0">R$ 10.495</h2>
                                <span class="text-muted">Gastos do mês</span>
                            </div>
                        </div>
                    </app-card>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-xl-6">
                    <app-chart
                        [altura]="50"
                        #chart1
                        [elemento]="chart1"
                        [rotulo]="label"
                    >
                    </app-chart>
                </div>

                <div class="col-md-6 col-xl-6">
                    <app-chart
                        [altura]="50"
                        #chart2
                        [elemento]="chart2"
                        [rotulo]="label"
                        tipo="pie"
                    >
                    </app-chart>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-xl-6">
                    <app-chart
                        [altura]="50"
                        #chart3
                        [elemento]="chart3"
                        [rotulo]="label"
                        tipo="radar"
                    >
                    </app-chart>
                </div>

                <div class="col-md-6 col-xl-6">
                    <app-chart
                        #chart4
                        [altura]="50"
                        tipo="horizontalBar"
                        [elemento]="chart4"
                        [rotulo]="label"
                    >
                    </app-chart>
                </div>
            </div>
        </div>
    </div>
</app-conteudo>
