import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { ModalService } from 'src/app/shared/modal/modal.service';
import { PageLoaderService } from 'src/app/components/page-loader/page-loader.service';
import { CertificadoService } from 'src/app/services/certificado/certificado.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SweetAlertService } from 'src/app/services/sweet-alert/sweet-alert.service';

declare var LacunaWebPKI: any;
@Component({
    templateUrl: './login-certificado.component.html',
    styleUrls: ['./login-certificado.component.scss']
})
export class LoginCertificadoComponent implements OnInit {
    pki: any;
    certificados: any[] = [];
    loading: boolean;

    constructor(
        private zone: NgZone,
        private router: Router,
        private modalService: ModalService,
        private pageLoaderService: PageLoaderService,
        private certificadoService: CertificadoService,
        private authService: AuthService,
        private alertService: SweetAlertService
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.pageLoaderService.exibir();

        this.pki = new LacunaWebPKI(environment.lacuna.license);
        this.pki.init({
            ready: this.carregarCertificados.bind(this),
            notInstalled: this.sugerirInstalacao.bind(this),
            defaultError: this.carregarErros.bind(this)
        });
    }

    login(certificado) {
        if (!certificado.thumbprint) {
            this.carregarErros(
                'Não foi possível efetuar a leitura do seu certificado.'
            );
            return;
        }

        this.pki.readCertificate(certificado.thumbprint).success(encoding => {
            // const nonce = this.certificadoService.gerarNonce();
            // const assinatura = this.certificadoService.gerarAssinatura(encoding).toPromise();
            this.authService.autenticarComCertificado().subscribe(
                () => {
                    this.zone.run(() => {
                        this.router.navigate(['']);
                    });
                },
                err => {
                    this.carregarErros(
                        'Não foi possível efetuar a login com seu certificado.'
                    );
                }
            );
        });
    }

    redirecionarParaInstalacao(): void {
        this.pki.redirectToInstallPage();
    }

    fecharModalInstalacao(): void {
        this.modalService.fecha('instalacao');
    }

    private listarCertificados(certificados: any): void {
        if (certificados.length) {
            const certs = certificados.map(certificado => {
                return {
                    nome: certificado.pkiBrazil.responsavel,
                    empresa: certificado.pkiBrazil.companyName,
                    email: certificado.email,
                    thumbprint: certificado.thumbprint
                };
            });

            this.zone.run(() => {
                this.certificados = certs;
                this.pageLoaderService.esconder();
                this.loading = false;
            });
        }
    }

    private async carregarCertificados() {
        const self = this;
        this.pki
            .listCertificates()
            .success(function(certs) {
                certs = certs.concat(self.certificadoService.gerarMock());
                self.listarCertificados(certs);
            })
            .fail(function(ex) {
                this.carregarErros(ex.message, ex.error, ex.origin);
            });
    }

    private sugerirInstalacao(): void {
        this.zone.run(() => {
            this.modalService.abre('instalacao');
            this.pageLoaderService.esconder();
            this.loading = false;
        });
    }

    private carregarErros(
        message: any,
        error: any = null,
        origin: any = null
    ): void {
        this.zone.run(() => {
            this.alertService.mostrar(
                message,
                'error',
                'Erro ao carregar certificado'
            );
            this.pageLoaderService.esconder();
            this.loading = false;
        });
    }
}
