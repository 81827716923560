    <div class="custom-control custom-checkbox">
        <input 
        (blur)="eventoBlur()"
        [disabled]="desativado"
        [checked]="selecionado"
        (change)="eventoChange()"
        class="custom-control-input"
        (click)="eventoClick()"
        (focus)="eventoFocus()" 
        [name]="name" 
        [id]="id" 
        type="checkbox" >
        <label class="custom-control-label" [for]="id">{{texto}}</label>
    </div>