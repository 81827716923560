<div class="card">
  <div class="d-flex card-header">
    <h5>Filtro de Pesquisa</h5>
  </div>
  <div class="row mb-2">
    <label class="mt-3 ml-5 mr-2 bold">Pesquisar por:</label>
    <div class="col-3 mt-2">
      <select [(ngModel)]="filtro" class="form-control form-control-sm" (change)="getTipoFiltro($event)">
        <option selected disabled value="">Escolha uma opção...</option>
        <option *ngFor="let item of opcoesFiltro; let i = index" [value]="i">
          {{ item.descricao }}
        </option>
      </select>
    </div>
    <div *ngIf="filtroQuantidade" class="mt-2 mr-4 filtro-quantidade">
      <span class="mr-1">Exibir</span>
      <select class="form-control form-control-sm" [(ngModel)]="tamanhoPagina" (change)="alterarTamanhoPagina()">
          <option value=10>
              10
          </option>
          <option value=20>
              20
          </option>
          <option value=50>
              50
          </option>
          <option value=100>
              100
          </option>
      </select>
  </div>
  </div>
  <div class="row mb-2 ml-3">
    <input 
      *ngIf="tipoFiltro == 'cpfCnpj'"
      type="text"
      placeholder="Digite o CPF ou CNPJ..."
      mask="CPF_CNPJ"
      [(ngModel)]="valorFiltro"
      class="form-control form-control-sm col-3 ml-3 mt-2"
    />
    <input
      *ngIf="tipoFiltro == 'ie'"
      type="text"
      placeholder="Digite a IE..."
      mask="00.000.000-0"
      [(ngModel)]="valorFiltro"
      class="form-control form-control-sm col-3 ml-3 mt-2"
    />
    <input
      *ngIf="tipoFiltro == 'texto'"
      type="text"
      placeholder="Digite o valor a ser filtrado..."
      [(ngModel)]="valorFiltro"
      class="form-control form-control-sm col-3 ml-3 mt-2"
    />
    <input 
    *ngIf="tipoFiltro == 'protocolo'"
    type="number"
    placeholder="Digite o Protocolo..."
    [(ngModel)]="valorFiltro"
    class="form-control form-control-sm col-3 ml-3 mt-2"
  />
    <ng-container *ngIf="tipoFiltro == 'periodo'">
      <div class="mt-2 ml-3">
        <span class="bold mr-2">De:</span>
        <dp-date-picker
          theme="dp-material"
          [config]="datePickerConfig"
          name="datInicio"
          placeholder="Data de início"
          [(ngModel)]="datInicio"
          (onChange)="getDataInicio($event)"
        >
        </dp-date-picker>
      </div>
      <div class="mt-2 ml-2">
        <span class="bold mr-2">Até:</span>
        <dp-date-picker
        theme="dp-material"
        [config]="datePickerConfig"
        name="datFim"
        placeholder="Data fim"
        [(ngModel)]="datFim"
        (onChange)="getDataFim($event)"
        >
        </dp-date-picker>
      </div>
    </ng-container>
    <ng-container *ngIf="tipoFiltro == 'dropdown'">
      <select class="form-control form-control-sm col-3 mt-2 ml-3" [(ngModel)]="valorFiltro">
        <option value="" selected disabled>Escolha uma opção...</option>
        <option *ngFor="let item of listaDropdown" [value]="item.codigo">
          {{ item.descricao }}
        </option>
      </select>
    </ng-container>
    <ng-container *ngIf="tipoFiltro == 'dropdown2'">
      <select class="form-control form-control-sm col-3 mt-2 ml-3" [(ngModel)]="valorFiltro">
        <option value="" selected disabled>Escolha uma opção...</option>
        <option *ngFor="let item of listaDropdown2" [value]="item.codigo">
          {{ item.descricao }}
        </option>
      </select>
    </ng-container>
    <ng-container *ngIf="tipoFiltro == 'dropdown3'">
      <select class="form-control form-control-sm col-3 mt-2 ml-3" [(ngModel)]="valorFiltro">
        <option value="" selected disabled>Escolha uma opção...</option>
        <option *ngFor="let item of listaDropdown3" [value]="item.codigo">
          {{ item.descricao }}
        </option>
      </select>
    </ng-container>
    <ng-container *ngIf="tipoFiltro == 'dropdown4'">
      <select class="form-control form-control-sm col-3 mt-2 ml-3" [(ngModel)]="valorFiltro">
        <option value="" selected disabled>Escolha uma opção...</option>
        <option *ngFor="let item of listaDropdown4" [value]="item.codigo">
          {{ item.descricao }}
        </option>
      </select>
    </ng-container>
    <app-button 
      *ngIf="filtro != ''" 
      [desativado]="valorFiltro == '' && !datInicio && !datFim" 
      class="mt-2 ml-3" 
      (click)="filtrarDados()"
    >Filtrar
    </app-button>
    <app-button
      *ngIf="filtroAtivo"
      class="mt-2 ml-3"
      cor="secondary"
      (click)="resetFiltro()"
    >Limpar Filtro
    </app-button>
  </div>
</div>