import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadcrumbItem } from './breadcrumb-item';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
    itens: BreadcrumbItem[];
    itensParent: BreadcrumbItem[];
    routesPathDefault: any = [
        { nome: 'Perfil do Contribuinte', path: '/painel-contribuinte' },
        {
            nome: 'Indicadores/Cruzamentos do Contribuinte',
            path: '/painel-contribuinte'
        },
        { nome: 'Comex', path: '/painel-contribuinte' },
        { nome: 'Simples Nacional', path: '/painel-contribuinte' },
        { nome: 'NF-e', path: '/painel-contribuinte' },
        { nome: 'EFD', path: '/painel-contribuinte' }
    ];

    constructor(
        private router: ActivatedRoute,
        private route: Router
    ) {}

    ngOnInit() {
        this.geraBreadcrumb();
    }

    geraBreadcrumb(): void {
        const home = new BreadcrumbItem('/', '', 'home');
        const itens: BreadcrumbItem[] = [home];

        if(this.hasParent()) {
            const routerParent = this.router.parent;
            const parents = this.routerParents(routerParent);

            if(parents) {
                parents.forEach(item => {
                    itens.push(item);
                })
            }

            if(this.router.routeConfig?.path !== '') {
                itens.push(
                    new BreadcrumbItem(
                        `/${this.router.routeConfig?.path}`,
                        this.router.routeConfig?.data?.title
                    )
                );    
            }
        }

        if(this.hasData() && !this.hasParent()) {
            itens.push(
                new BreadcrumbItem(
                    `/${this.router.routeConfig.path}`,
                    this.router.routeConfig.data.title
                )
            );
        }

        itens.forEach(item => {
            const urlItem = item.url.replace(/\/:[^/]+/g, '');
            const url = this.router.snapshot['_routerState'].url;
            const indexPath = url.indexOf(urlItem);
            item.url = url.substring(0, indexPath + urlItem.length);
        });
 
        this.itens = itens;
    }

    private routerParents(router): BreadcrumbItem[] {
        let itens = [];

        const existPathDefault = this.routesPathDefault.find(
            item =>
                item.nome ===
                (router.routeConfig !== null
                    ? router.routeConfig.data.title
                    : '')
        );

        if(router.parent !== null && router.parent.routeConfig !== null) {
            
            if (existPathDefault) {
                if(router?.parent?.routeConfig?.data?.title) {
                    const itensParent = this.routerParents(router.parent);
                    
                    if(itensParent !== null) {
                        itensParent.forEach(item => {
                            itens.push(item);
                        });
                    }
                }

                itens.push(new BreadcrumbItem(
                    `/${router.parent.routeConfig.path}`,
                    router.routeConfig.data.title
                ));  

            } else {
                this.itensParent = [];
                if(router?.parent?.routeConfig?.data?.title) {
                    const itensParent = this.routerParents(router.parent);
                    
                    if(itensParent !== null) {
                        itensParent.forEach(item => {
                            itens.push(item);
                        })
                    }
                }
                
                const titleContribuinte = sessionStorage.getItem("contribuinteSelecionado");
                if(router.routeConfig.path.includes('analise-fiscal') && titleContribuinte) {
                    itens.push(new BreadcrumbItem(
                        `/${router.routeConfig.path}`,
                        titleContribuinte
                    ));
                }

                itens.push(new BreadcrumbItem(
                    `/${router.routeConfig.path}`,
                    router.routeConfig.data.title
                ));
                
            }

            return itens
        } else {
            itens.push(new BreadcrumbItem(
                `/${router.routeConfig.path}`,
                router.routeConfig.data.title
            ));
    
            return itens
        }

    }

    private hasParent(): boolean {
        return (
            this.router.parent &&
            this.router.parent.routeConfig &&
            this.router.parent.routeConfig.path &&
            this.router.parent.routeConfig.data &&
            this.router.parent.routeConfig.data.title
        );
    }

    private hasData(): boolean {
        return (
            this.router.routeConfig &&
            this.router.routeConfig.path &&
            this.router.routeConfig.data &&
            this.router.routeConfig.data.title
        );
    }
}
