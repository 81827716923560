import { Component, Input, Output, EventEmitter, OnInit, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})

export class CheckboxComponent implements OnInit {
  @Output() onClick = new EventEmitter<boolean>();
  @Output() onFocus = new EventEmitter<boolean>();
  @Output() onBlur = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<string>();

  @Input() cor: string = 'primary';
  @Input() name: string;
  @Input() id: string;
  @Input() valor: string;
  @Input() texto: string;
  @Input() formGroup: FormGroup;

  @Input() desativado: boolean = false;
  @Input() selecionado: boolean = false;

  constructor(private el: ElementRef) {}

  ngOnInit() {
  }

  eventoClick(): void {
    this.onClick.emit(true);
  }

  eventoFocus(): void {
    this.onFocus.emit(true);
  }

  eventoBlur(): void {
    this.onBlur.emit(true);
  }

  eventoChange(): void {
    this.onChange.emit(this.formGroup.controls[this.name].value);
  }
}
