<app-conteudo titulo="Mandado e Termos">
    <div class="main-body">
       <div class="page-wrapper">
          <div class="row">
             <div class="col-md-12">
                <div class="card">
                   <div class="d-flex card-header">
                      <h5 class="mr-auto">
                         Pesquisa
                      </h5>
                   </div>
                   <div class="card-body">
                      <form [formGroup]="validadorForm" (ngSubmit)="validacao()">
                      <div class="row">
                         <div class="col-md-3">
                            <div class="form-group">
                               <div
                                  class="custom-control custom-checkbox"
                                  >
                                  <input
                                     (change)="clearFiltro()"
                                     formControlName="tipo"
                                     value="inscricaoEstadual"
                                     type="checkbox"
                                     id="cfg"
                                     name="tipo"
                                     class="custom-control-input"
                                     />
                                  <label
                                     class="custom-control-label"
                                     for="cfg"
                                     >
                                  Mandado de Monitoramento
                                  </label>
                               </div>
                            </div>
                         </div>
                         <div class="col-md-3">
                            <div class="form-group">
                               <div
                                  class="custom-control custom-checkbox custom-control-inline"
                                  >
                                  <input
                                     (change)="clearFiltro()"
                                     formControlName="tipo"
                                     value="cnpj-basico"
                                     type="checkbox"
                                     id="cnpj-basico"
                                     name="tipo"
                                     class="custom-control-input"
                                     />
                                  <label
                                     class="custom-control-label"
                                     for="cnpj-basico"
                                     >
                                  Termo de Notificação
                                  </label>
                               </div>
                            </div>
                         </div>
                         <div class="col-md-3">
                            <div class="form-group">
                               <div
                                  class="custom-control custom-checkbox custom-control-inline"
                                  >
                                  <input
                                     (change)="clearFiltro()"
                                     formControlName="tipo"
                                     value="razao-social"
                                     type="checkbox"
                                     id="razao-social"
                                     name="tipo"
                                     class="custom-control-input"
                                     />
                                  <label
                                     class="custom-control-label"
                                     for="razao-social"
                                     >
                                  Termo de Intimação
                                  </label>
                               </div>
                            </div>
                         </div>
                         <div class="col-md-3">
                            <div class="form-group">
                               <div
                                  class="custom-control custom-checkbox custom-control-inline"
                                  >
                                  <input
                                     (change)="clearFiltro()"
                                     formControlName="tipo"
                                     value="nome-fantasia"
                                     type="checkbox"
                                     id="nome-fantasia"
                                     name="termoFantasia"
                                     class="custom-control-input"
                                     checked
                                     />
                                  <label
                                     class="custom-control-label"
                                     for="nome-fantasia"
                                     >
                                  Termo de encerramento
                                  </label>
                               </div>
                            </div>
                            <div
                               *ngIf="
                               validadorForm.controls.tipo
                               .invalid &&
                               (validadorForm.controls.tipo
                               .dirty ||
                               validadorForm.controls.tipo
                               .touched)
                               "
                               class="invalid-feedback d-block"
                               >
                               <div
                                  *ngIf="
                                  validadorForm.controls.tipo
                                  .errors.required
                                  "
                                  >
                                  Campo Obrigatório
                               </div>
                            </div>
                         </div>
                         <div class="col-md-12">
                            <div class="row">
                               <div class="col-md-4">
                                  <div class="form-group">
                                     <label
                                        class="col-form-label"
                                        for="filter"
                                        >
                                     Número do MMF/Termo
                                     </label>
                                     <input
                                        formControlName="numero"
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="mmf"
                                        />
                                  </div>
                                  <div
                                     *ngIf="
                                     validadorForm.controls.numero
                                     .invalid &&
                                     (validadorForm.controls.numero
                                     .dirty ||
                                     validadorForm.controls.numero
                                     .touched)
                                     "
                                     class="invalid-feedback d-block"
                                     >
                                     <div
                                        *ngIf="
                                        validadorForm.controls.numero
                                        .errors.required
                                        "
                                        >
                                        O campo obrigatório.
                                     </div>
                                  </div>
                               </div>
                               <div class="col-md-3">
                                  <div class="form-group">
                                     <label
                                        for="filter"
                                        class="col-form-label"
                                        >
                                     Número do PAM
                                     </label>
                                     <input
                                        formControlName="pam"
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="pam"
                                        />
                                  </div>
                                  <div
                                     *ngIf="
                                     validadorForm.controls.pam
                                     .invalid &&
                                     (validadorForm.controls.pam
                                     .dirty ||
                                     validadorForm.controls.pam
                                     .touched)
                                     "
                                     class="invalid-feedback d-block"
                                     >
                                     <div
                                        *ngIf="
                                        validadorForm.controls.pam
                                        .errors.required
                                        "
                                        >
                                        O campo é obrigatório.
                                     </div>
                                  </div>
                               </div>
                               <div class="col-md-5">
                                  <div class="form-group">
                                     <label
                                        class="col-form-label"
                                        for="filter"
                                        >
                                        Agência
                                     </label>
                                     <select
                                        class="form-control form-control-sm"
                                        formControlName="orgaoMonitoramento"
                                        >
                                        <option value="todos">
                                           Todos
                                        </option>
                                        <option
                                           value="nucleo-de-fiscalizacao"
                                           >
                                           NÚCLEO DE FISCALIZAÇÃO E MONITORAMENTO
                                        </option>
                                        <option
                                           value="nucleo-setorial-comunica"
                                           >
                                           NÚCLEO SETORIAL DE COMUNICAÇÃO E ENERGIA ELÉTRICA
                                        </option>
                                        <option
                                           value="nucleo-setorial-combustivel"
                                           >
                                           NÚCLEO SETORIAL DE COMBUSTÍVEL
                                        </option>
                                        <option
                                           value="celular-benef-fisc"
                                           >
                                           CELULAR DE BENEF. FISC. E CONTR. DO GASTO TRIBUT.
                                        </option>
                                        <option
                                           value="nucleo-setorial-aliment"
                                           >
                                           NÚCLEO SETORIAL DE ALIMENTOS
                                        </option>
                                        <option
                                           value="nucleo-setorial-produtos."
                                           >
                                           NÚCLEO SETORIAL DE PRODUTOS AUTOMOTIVOS
                                        </option>
                                     </select>
                                  </div>
                                  <div
                                     *ngIf="
                                     validadorForm.controls.orgaoMonitoramento
                                     .invalid &&
                                     (validadorForm.controls.orgaoMonitoramento
                                     .dirty ||
                                     validadorForm.controls.orgaoMonitoramento
                                     .touched)
                                     "
                                     class="invalid-feedback d-block"
                                     >
                                     <div
                                        *ngIf="
                                        validadorForm.controls.orgaoMonitoramento
                                        .errors.required
                                        "
                                        >
                                        O campo Agência é obrigatório.
                                     </div>
                                  </div>
                               </div>
                            </div>
                            <div class="row">
                               <div class="col-md-4">
                                  <div class="form-group">
                                     <label
                                        class="col-form-label"
                                        for="filter"
                                        >
                                     Situação da Ciência
                                     </label>
                                     <select
                                        class="form-control form-control-sm"
                                        formControlName="situacaoCiencia"
                                        >
                                        <option value="todos">
                                           Todos
                                        </option>
                                        <option
                                           value="sem-ciencia"
                                           >
                                           Sem Ciência
                                        </option>
                                        <option
                                           value="ciencia-pessoal"
                                           >
                                           Ciência Pessoal
                                        </option>
                                        <option
                                           value="ciencia-ar"
                                           >
                                           Ciência AR
                                        </option>
                                        <option
                                           value="ciencia-edital"
                                           >
                                           Ciência Edital
                                        </option>
                                        <option
                                           value="ciencia-automatica"
                                           >
                                           Ciência Automática
                                        </option>
                                     </select>
                                  </div>
                                  <div
                                     *ngIf="
                                     validadorForm.controls.situacaoCiencia
                                     .invalid &&
                                     (validadorForm.controls.situacaoCiencia
                                     .dirty ||
                                     validadorForm.controls.situacaoCiencia
                                     .touched)
                                     "
                                     class="invalid-feedback d-block"
                                     >
                                     <div
                                        *ngIf="
                                        validadorForm.controls.situacaoCiencia
                                        .errors.required
                                        "
                                        >
                                        O campo é obrigatório.
                                     </div>
                                  </div>
                               </div>
                               <div class="col-md-4">
                                  <div class="form-group">
                                     <label
                                        class="col-form-label"
                                        for="filter"
                                        >
                                     Matrícula Auditor
                                     </label>
                                     <input
                                        formControlName="matriculaAuditor"
                                        class="form-control form-control-sm"
                                        type="text"
                                        id="matricula"
                                        />
                                  </div>
                                  <div
                                     *ngIf="
                                     validadorForm.controls.matriculaAuditor
                                     .invalid &&
                                     (validadorForm.controls.matriculaAuditor
                                     .dirty ||
                                     validadorForm.controls.matriculaAuditor
                                     .touched)
                                     "
                                     class="invalid-feedback d-block"
                                     >
                                     <div
                                        *ngIf="
                                        validadorForm.controls.matriculaAuditor
                                        .errors.required
                                        "
                                        >
                                        O campo é obrigatório.
                                     </div>
                                  </div>
                               </div>
                               <div class="col-md-4">
                                  <div class="form-group">
                                     <label
                                        class="col-form-label"
                                        for="filter"
                                        >
                                     Nome do Projeto
                                     </label>
                                     <select
                                        class="form-control form-control-sm"
                                        formControlName="nomeProjeto"
                                        >
                                        <option value="todos">
                                           Todos
                                        </option>
                                        <option
                                           value="projeto-1"
                                           >
                                           Projeto 1
                                        </option>
                                        <option
                                           value="projeto-2"
                                           >
                                           Projeto 2
                                        </option>
                                        <option
                                           value="projeto-3"
                                           >
                                           Projeto 3
                                        </option>
                                        <option
                                           value="projeto-4"
                                           >
                                           Projeto 4
                                        </option>
                                     </select>
                                  </div>
                                  <div
                                     *ngIf="
                                     validadorForm.controls.nomeProjeto
                                     .invalid &&
                                     (validadorForm.controls.nomeProjeto
                                     .dirty ||
                                     validadorForm.controls.nomeProjeto
                                     .touched)
                                     "
                                     class="invalid-feedback d-block"
                                     >
                                     <div
                                        *ngIf="
                                        validadorForm.controls.nomeProjeto
                                        .errors.required
                                        "
                                        >
                                        O campo Nome do Projeto é obrigatório.
                                     </div>
                                  </div>
                               </div>
                            </div>
                            <!-- < ver com filipe -->
                            <div class="row">
                               <div class="col-md-8">
                                  <div class="form-group">
                                     <label
                                        class="col-form-label"
                                        for="filter"
                                        >
                                        Período
                                     </label>
                                     <app-datepicker-interval
                                     [id]="
                                     'searchDatePicker'
                                     "
                                     (range)="
                                     setRange($event)
                                     "
                                     >
                                     </app-datepicker-interval>
                                     <div
                                        *ngIf="
                                        validadorForm.controls.data
                                        .invalid &&
                                        (validadorForm.controls.data
                                        .dirty ||
                                        validadorForm.controls.data
                                        .touched)
                                        "
                                        class="invalid-feedback d-block"
                                        >
                                        <div
                                           *ngIf="
                                           validadorForm.controls.data
                                           .errors.required
                                           "
                                           >
                                           O campo é obrigatório.
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div class="col-md-4">
                                  <div class="form-group">
                                     <label
                                        for="filter"
                                        class="col-form-label"
                                        >
                                        Inscrição Estadual
                                     </label>
                                     <input
                                        formControlName="inscricaoEstadual"
                                        class="form-control form-control-sm"
                                        id="inscricaoEstadual"
                                        type="text"
                                        mask="00.000.000-0"
                                        />
                                  </div>
                                  <div
                                     *ngIf="
                                     validadorForm.controls.inscricaoEstadual
                                     .invalid &&
                                     (validadorForm.controls.inscricaoEstadual
                                     .dirty ||
                                     validadorForm.controls.inscricaoEstadual
                                     .touched)
                                     "
                                     class="invalid-feedback d-block"
                                     >
                                     <div
                                        *ngIf="
                                        validadorForm.controls.inscricaoEstadual
                                        .errors.required
                                        "
                                        >
                                        O campo é obrigatório.
                                     </div>
                                  </div>
                               </div>
                               <button
                                  type="submit"
                                  class="p-0 btn btn-success ml-2 form-control col-sm-2 form-control-sm"
                                  >
                               <span>Consultar</span>
                               </button>
                            </div>
                         </div>
                      </div>
                      </form>
                   </div>
                </div>
             </div>
          </div>
          <div class="card">
             <!--<div class="d-flex card-header">
                <h5 class="mr-auto">Monitoramento Projeto</h5>
                </div>-->
             <div class="card-body">
                <app-tabela
                (openOptions)="setContributor($event)"
                [dados]="monitoramento.dados"
                [campos]="monitoramento.campos"
                [calcColumn]="'all'"
                ></app-tabela>
             </div>
          </div>
          <div class="card">
             <div class="d-flex card-header">
                <h5 class="mr-auto">
                   Situação da Ciência
                </h5>
             </div>
             <div class="row p-2" footer>
                <div class="d-flex justify-content-center col-12">
                   <div class="col-sm">
                      <i class="circle-red"></i>
                      Sem Ciência
                   </div>
                   <div class="col-sm">
                      <i class="circle-black"></i>
                      Ciência Pessoal
                   </div>
                   <div class="col-sm">
                      <i class="circle-ligth-blue"></i>
                      Ciência Eletrônica
                   </div>

                </div>
                <div class="d-flex justify-content-center p-t-15 col-12">
                  <div class="col-sm">
                     <i class="circle-ligth-green"></i>
                     Ciência AR
                  </div>
                  <div class="col-sm">
                     <i class="circle-yellow"></i>
                     Ciência Edital
                  </div>
                  <div class="col-sm">
                     <i class="circle-orange"></i>
                     Ciência Automática
                  </div>

                </div>
             </div>
          </div>
          <div class="card" *ngIf="contributorId != ''">
             <div class="d-flex card-header">
                <h5 class="mr-auto">Filtrar</h5>
             </div>
             <div class="card-body">
                <form
                [formGroup]="formMatriculaEmpresa"
                (ngSubmit)="filter()"
                >
                <div class="form-group row">
                   <div
                      class="custom-control custom-radio custom-control-inline mt-1"
                      >
                      <input
                         formControlName="projetosTipos"
                         value="inscricaoEstadual"
                         type="radio"
                         id="cfg"
                         name="projetosTipos"
                         class="custom-control-input"
                         />
                      <label class="custom-control-label" for="cfg">
                        Inscrição Estadual
                      </label>
                   </div>
                   <div
                      class="custom-control custom-radio custom-control-inline mt-1"
                      >
                      <input
                         formControlName="projetosTipos"
                         value="cnpj-basico"
                         type="radio"
                         id="cnpj-basico"
                         name="projetosTipos"
                         class="custom-control-input"
                         />
                      <label
                         class="custom-control-label"
                         for="cnpj-basico"
                         >
                      CNPJ Básico
                      </label>
                   </div>
                   <div
                      class="custom-control custom-radio custom-control-inline mt-1"
                      >
                      <input
                         formControlName="projetosTipos"
                         value="razao-social"
                         type="radio"
                         id="razao-social"
                         name="projetosTipos"
                         class="custom-control-input"
                         />
                      <label
                         class="custom-control-label"
                         for="razao-social"
                         >
                      Razão Social
                      </label>
                   </div>
                   <div
                      class="custom-control custom-radio custom-control-inline mt-1"
                      >
                      <input
                         formControlName="projetosTipos"
                         value="nome-fantasia"
                         type="radio"
                         id="nome-fantasia"
                         name="projetosTipos"
                         class="custom-control-input"
                         />
                      <label
                         class="custom-control-label"
                         for="nome-fantasia"
                         >
                      Nome Fantasia
                      </label>
                   </div>
                   <div
                      class="custom-control custom-radio custom-control-inline mt-1"
                      >
                      <input
                         formControlName="projetosTipos"
                         value="cnpj"
                         type="radio"
                         id="cnpj"
                         name="projetosTipos"
                         class="custom-control-input"
                         />
                      <label class="custom-control-label" for="cnpj">
                      CNPJ
                      </label>
                   </div>
                   <label
                      for="filter"
                      class="mx-3 col-form-label"
                      style="margin-top:-2px;"
                      >
                   St. Monitoramento Fiscal:
                   </label>
                   <select
                      class="col-sm-2 form-control form-control-sm"
                      formControlName="situacaoMonitoramento"
                      >
                      <option value="todos"> Todos </option>
                      <option value="">
                         Planejada
                      </option>
                      <option value="">
                         Designada
                      </option>
                      <option value="">
                         Devolvida
                      </option>
                      <option value="">
                         Em Execução
                      </option>
                      <option value="">
                         Vetada
                      </option>
                      <option value="">
                         Anulada
                      </option>
                      <option value="">
                         Vencida
                      </option>
                      <option value="">
                         Concluída
                      </option>
                      <option value="">
                         Pendente
                      </option>
                      <option value="">
                         Em Aberto
                      </option>
                      <option value="">
                         Homologada
                      </option>
                   </select>
                   <button
                      type="submit"
                      class="p-0 btn btn-success ml-2 form-control col-sm-2 form-control-sm"
                      >
                   <span>Buscar</span>
                   </button>
                </div>
                </form>
             </div>
          </div>
          <div class="card" *ngIf="contributorId != ''">
             <div class="d-flex card-header">
                <h5 class="mr-auto">{{ contributorId }}</h5>
             </div>
             <div class="card-body">
                <app-tabela
                [dados]="monitoramentoEmpresa.dados"
                [campos]="monitoramentoEmpresa.campos"
                [calcColumn]="'all'"
                ></app-tabela>
             </div>
          </div>
          <div class="card" *ngIf="contributorId != ''">
             <div class="d-flex card-header">
                <h5 class="mr-auto">
                   Legenda da Situação da Empresa no Monitoramento Fiscal
                </h5>
             </div>
             <div class="row p-2" footer>
                <div class="d-flex justify-content-center col-12">
                   <div class="col-sm">
                      <i class="circle-planejada"></i>
                      Planejada
                   </div>
                   <div class="col-sm">
                      <i class="circle-homologada"></i>
                      Homologada
                   </div>
                   <div class="col-sm">
                      <i class="circle-pendente"></i>
                      Pendente
                   </div>
                   <div class="col-sm">
                      <i class="circle-designada"></i>
                      Designada
                   </div>
                   <div class="col-sm">
                      <i class="circle-vetada"></i>
                      Vetada
                   </div>
                   <div class="col-sm">
                      <i class="circle-anulada"></i>
                      Anulada
                   </div>
                </div>
                <div class="d-flex justify-content-center col-12">
                  <div class="col-sm">
                     <i class="circle-devolvida"></i>
                     Devolvida
                  </div>
                  <div class="col-sm">
                     <i class="circle-emAberto"></i>
                     Em aberto
                  </div>
                  <div class="col-sm">
                     <i class="circle-emExecucao"></i>
                     Em execução
                  </div>
                  <div class="col-sm">
                     <i class="circle-vencida"></i>
                     Vencida
                  </div>
                  <div class="col-sm">
                     <i class="circle-concluida"></i>
                     Concluida
                  </div>
                  <div class="col-sm">
                     <i
                        (click)="help2()"
                        class="feather icon-help-circle ml-3"
                        ></i>
                  </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </app-conteudo>
 <!--<app-modal id="comunicadoModal" titulo="Gerar Comunicado" tamanho="lg">
    <div class="container">
        <form [formGroup]="formComunicado" (ngSubmit)="enviarComunicado()">
            <div class="form-row">
               <div class="form-group col-12 mt-2">
                   <label for="para">Para:</label>
                   <div class="d-flex">
                       <input
                           type="text"
                           class="form-control form-control-sm"
                           id="para"
                           formControlName="para"
                       />
                       <i
                           (click)="pesquisarMatriculaNome()"
                           class="feather icon-search input-group-text ml-2"
                       ></i>
                   </div>
               </div>
           </div>
           <div class="form-row">
               <div class="form-group col-12">
                   <label for="assunto">Assunto:</label>
                   <div class="d-flex">
                       <input
                           type="text"
                           class="form-control form-control-sm"
                           id="assunto"
                           formControlName="assunto"
                       />
                   </div>
               </div>
           </div>
           <div class="form-row">
               <div class="col-12">
                   <label for="texto">Texto:</label>
                   <textarea
                       class="form-control"
                       id="textarea"
                       rows="3"
                       formControlName="texto"
                       minlength="15"
                       maxlength="255"
                   ></textarea>
               </div>
           </div>
           <button
               type="submit"
               class="p-0 btn btn-success form-control col-sm-2 form-control-sm mt-3 float-right mb-2 mr-0"
           >
               <span>Enviar</span>
           </button>
           <button
               type="button"
               class="p-0 btn btn-secondary form-control col-sm-2 form-control-sm mt-3 float-right mb-2 mr-2"
           >
               <span>Cancelar</span>
           </button>
       </form>
    </div>
    </app-modal>

    <app-modal
    (close)="gerarComunicado()"
    id="matriculaNomeModal"
    titulo="Pesquisa Matrícula/Nome"
    tamanho="xg"
    >
    <div class="container">
       <form
           [formGroup]="formMatriculaNome"
           (ngSubmit)="filtraMatriculaNome()"
       >
           <div class="form-row align-items-end mt-2 mb-2">
               <div class="form-group mb-0 col-4 mt-2">
                   <label for="matricula">Matrícula:</label>
                   <div class="d-flex">
                       <input
                           type="text"
                           class="form-control form-control-sm"
                           id="matricula"
                           formControlName="matricula"
                       />
                   </div>
               </div>

               <div class="form-group mb-0 col-4 mt-2">
                   <label for="nome">Nome:</label>
                   <div class="d-flex">
                       <input
                           type="text"
                           class="form-control form-control-sm"
                           id="nome"
                           formControlName="nome"
                       />
                   </div>
               </div>
               <button
                   type="submit"
                   class="p-0 btn btn-success form-control col-sm-2 form-control-sm mb-0 ml-3"
               >
                   <span>Pesquisar</span>
               </button>
           </div>
           <div class="row">
               <div class="col-12">
         <app-tabela
             [dados]="matriculas.dados"
                       [campos]="matriculas.campos"
                   ></app-tabela>
               </div>
           </div>
           <button
               disabled
               type="button"
               class="p-0 btn btn-success form-control col-sm-2 form-control-sm mt-3 mb-0 mr-0 float-right"
           >
               <span>Selecionar</span>
           </button>
       </form>
    </div>
    </app-modal>-->
