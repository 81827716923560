import { Component, OnInit } from '@angular/core';

import { MandadoETermosService } from './mandado-e-termos.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Router } from '@angular/router';

import { ComunicadosService } from '../../services/comunicados/comunicados.service';
import { SweetAlertService } from '../../services/sweet-alert/sweet-alert.service';

import { PageLoaderService } from 'src/app/components/page-loader/page-loader.service';
import { ModalService } from 'src/app/shared/modal/modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mandado-e-termos',
  templateUrl: './mandado-e-termos.component.html',
  styleUrls: ['./mandado-e-termos.component.scss']
})

export class MandadoETermosComponent implements OnInit {
  searchFilter: FormGroup;
  comunicado: any;
  agenda: any = [];
  matriculas: any = [];
  monitoramento: any = [];
  monitoramentoEmpresa: any = [];
  gerarDados: any = [];
  dados: any[] = [];
  contributorId = '';
  formFiltro: FormGroup;
  filtroForm: FormGroup;
  carregado = false;

  dateRange: object;
  validadorForm: FormGroup;



  constructor(
    private mandadoETermosService: MandadoETermosService,
    private comunicadosService: ComunicadosService,
    private loaderService: PageLoaderService,
    private sweetAlert: SweetAlertService,
    private modalService: ModalService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {

    //this.mensagemErro();

    this.validadorForm = this.formBuilder.group({
      tipo: ['checked', [Validators.required]],
      termoFantasia: ['',],
      numero: ['', [Validators.required]],
      pam: ['', [Validators.required]],
      orgaoMonitoramento: ['todos',],
      situacaoCiencia: ['todos',],
      matriculaAuditor: ['', [Validators.required]],
      nomeProjeto: ['todos',],
      data: ['', Validators.required],
      inscricaoEstadual: ['', [Validators.required]],
    });

    this.agenda = this.mandadoETermosService.getAgenda();
    this.comunicado = this.comunicadosService.getComunicado();
    this.matriculas = this.mandadoETermosService.getMatriculas();
    this.monitoramento = this.mandadoETermosService.getMonitoramento();
    this.monitoramentoEmpresa = this.mandadoETermosService.getMonitoramentoEmpresa();
    this.gerarDadosTabela();
  }

  mensagemErro() {
    Swal.fire({
      title: 'Desculpe',
      text: 'Recursos indisponíveis para o seu perfil de acesso.',
      type: 'error',
      confirmButtonText: 'OK'
    }).then(() => {
      this.router.navigate(['/painel-contribuinte']);
    });
  }

  help() {
    const html = `<div class="help-content">
                  <div class="row">
                    <div class="col-12">
                      <h6>EM DESENVOLVIMENTO</h6>
                      <p>Situação de criação de um novo projeto onde se está na fase de
                       inclusão/seleção/exclusão a refinamento de filtros de empresa;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>AGUARDO APROVAÇÃO</h6>
                      <p>Projeto enviado à CATRI/Coordenador para que seja aprovado;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>NÃO APROVADO</h6>
                      <p>Projeto analisado pela CATRI/Coordenador e não aprovado. Gera um aviso
                      para a caixa de entrada do responsável pelo projeto;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>A HOMOLOGAR</h6>
                      <p>Projeto aguardando fase de vetar empresas e  homologar o envio aos superiores/orientadores;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>ATIVO</h6>
                      <p>Projeto homologado pela CATRI/Coordenador e dentro do período de validade;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>CANCELADO</h6>
                      <p>Situação na qual o projeto não será enviado para homologação;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>FINALIZADO</h6>
                      <p>Projeto encerrado, conforme validade do mesmo.</p>
                    </div>
                  </div>



                </div>`;

    this.sweetAlert.mostrar(html, 'info', 'Situação do Projeto', 'OK');
  }

  setRange(range: object) {
    this.dateRange = range;
  }

  gerarComunicado() {
    this.modalService.abre('comunicadoModal');
  }


  validacao(): void {
    console.log(this.validadorForm);
    this.markFormGroupTouched(this.validadorForm);
    if (this.validadorForm.valid) {
      this.loaderService.exibir();
      setTimeout(() => {
        this.loaderService.esconder();
        window.scrollTo(0, window.innerHeight - 200);
      }, 2000);
    }
  }


  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  filter(): void {
    this.loaderService.exibir();
    setTimeout(() => {
      this.loaderService.esconder();
    }, 2000);
  }

  pesquisarMatriculaNome() {
    this.fechaModal('comunicadoModal');
    this.modalService.abre('matriculaNomeModal');
  }

  enviarComunicado() {
    this.modalService.fecha('comunicadoModal');
    this.sweetAlert.mostrar(
      'Comunicado enviado com sucesso!',
      'success',
      'Sucesso!',
      'OK'
    );
  }

  fechaModal(id: string) {
    this.modalService.fecha(id);
  }
  fechaModalComunicado() {
    this.modalService.fecha('comunicadoModal')
  }

  gerarDadosTabela(): void {
    let limit = 10;
    if (this.carregado) {
      limit = 50;
    } else {
      limit = 10;
    }

    this.monitoramento.dados = [];
    for (let i = 0; i < limit; i++) {
      this.monitoramento.dados.push({
        id: 1,
        'Visualizar': { label: `${i}`, isLink: false },
        'Tipo': { label: `mandado  ${i}`, isLink: true },
        'Nome': { label: `mandado  ${i}`, isLink: false },
        'Número': { label: '-', isLink: false },
        'Contribuinte': { label: '-', isLink: false },
        'Órgão de Execução': { label: '-', isLink: false },
        'Supervisor': { label: '-', isLink: false },
        'Agentes Fiscais': {
          label: '-',
          isLink: false
        },
        'Situação da Ciência': {
          label: '-',
          isLink: false
        },
        'Data da Ciência': { label: '-', isLink: false },
        'Vencimento Mandados e Termos': {
          label: '-',
          isLink: false
        }
      });
    }
    this.carregado = !this.carregado;
  }

  help2() {
    const html = `<div class="help-content">
    <div class="row">
      <div class="col-12">
        <h6>PLANEJADA</h6>
        <p>Empresa selecionada pela CATRI/CEPAC para compor o projeto
         de Monitoramento Fiscal e ainda sem designação;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>VETADA</h6>
        <p>A empresa não faz mais parte do projeto, por ter sido vetada
         pelo Orientador ou pelo Supervisor, por motivo justificável.
          Antes da designação do Agente Fiscal;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>PENDENTE</h6>
        <p>Superior gerou o Procedimento Administrativo de Monitoramento Fiscal - PAM,
         a empresa foi distribuida aleatoriamente para Agentes Fiscais, pelo SIGET.
          Aguardando que o Supervisor Confirme ou Altere a designação do Agente Fiscal;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>DESIGNADA</h6>
        <p>O PAM foi emitido e o Agente Fiscal designado. Antes da emissão do
         Mandado de Monitoramento FIscal;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>ANULADA</h6>
        <p>A empresa não faz mais parte do projeto, por ter sido anulada pelo
         Orientador ou pelo Supervisor, por motivo justificável, após ter sido
          designada para Agente Fiscal. Só é possivel anular uma empresa de
           um projeto antes do Agente Fiscal emitir Mandado de Monitoramento;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>DEVOLVIDA/REJEITADA</h6>
        <p>O Agente Fiscal se declara impedido, por motivo justificável,
         de realizar o Monitoramento Fiscal e devolve a empresa do PAM. A empresa,
         com a Situação "Devolvida", será designada para outro Agente Fiscal;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>EM ABERTO</h6>
        <p>Empresa com Mandado de Monitoramento emitido pelo Agente Fiscal.
         Antes do Ciente do Contribuinte;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>EM EXECUÇÃO</h6>
        <p>Monitoramento Fiscal iniciado e dentro do prazo de validade do projeto,
         após o Contribuinte dar Ciência ao Mandado de Monitoramento Fiscal;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>VENCIDA</h6>
        <p>Encerrou-se o período de vigência do Monitoramento Fiscal, o PAM foi iniciado,
         porém o Agente Fiscal não emitiu o Termo de Encerramento;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>CONCLUÍDA</h6>
        <p>Emitido o Termo de Encerramento e dada a ciência pelo Contribuinte.</p>
      </div>
    </div>
  </div>`;

    this.sweetAlert.mostrar(
      html,
      'info',
      'Situação do Monitoramento Fiscal',
      'OK'
    );
  }

  setContributor(data: any): void {
    console.log('teste icon:', data);
    this.contributorId = data.id.replace(/[.-\s]/g, '');
    if (this.contributorId === 'icon') {
      this.router.navigate([`orientador-supervisor/visualizar`]);
    }
    // console.log('id pga: ',this.contributorId);
    // this.router.navigate([
    //     `painel-contribuinte/${this.contributorId}/analise-fiscal`
    // ]);
    // this.menuOpcoesService.setMenuStatus(true);
  }

  clearFiltro() {
    this.filtroForm.patchValue({ filtro: '' })
  }
}
