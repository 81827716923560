import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SolicitacaoService } from './solicitacao-de-medidas-de-gestao.service';
import { ModalService } from 'src/app/shared/modal/modal.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-solicitacao-de-medidas-de-gestao',
  templateUrl: './solicitacao-de-medidas-de-gestao.component.html',
  styleUrls: ['./solicitacao-de-medidas-de-gestao.component.scss']
})
export class SolicitacaoComponent implements OnInit {
  searchFilter: FormGroup;
  dados: any[] = []
  headerTable: any = [];
  options: any;
  empresaTable: any = [];
  carregado = false;
  formFiltro: FormGroup;

  solicitacao: boolean;
  visualizacao: boolean;
  loaderService: any;

  constructor(
    private solicitacaoService: SolicitacaoService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {

    //this.mensagemErro();
    //this.options = solicitacaoService;

        this.formFiltro = this.formBuilder.group({
          solicitacao: ['', [Validators.required]],
          medidas: ['', [Validators.required]],
          inscricaoEstadual: ['', [Validators.required]],
          razao: ['', [Validators.required]],
          justificativa: ['', [Validators.required]]
        });


    this.headerTable = this.solicitacaoService.getHeaderTable();
    this.empresaTable = this.solicitacaoService.getHeaderTableEmpresaModal();
    this.gerarDadosTabela(); 
    this.gerarDadosTabelaEmpresa();
    this.solicitacao = false;
    this.visualizacao = false;
    
  }

  mensagemErro() {
    Swal.fire({
      title: 'Desculpe',
      text: 'Recursos indisponíveis para o seu perfil de acesso.',
      type: 'error',
      confirmButtonText: 'OK'
    }).then(() => {
      this.router.navigate(['/painel-contribuinte']);
    });
  }

  validacaoFormulario(): void {
    console.log(this.formFiltro);
    this.markFormGroupTouched(this.formFiltro);
    if (this.formFiltro.valid) {
        this.loaderService.exibir();
        setTimeout(() => {
            this.loaderService.esconder();
            window.scrollTo(0, window.innerHeight - 200);
        }, 2000);
    }
}


  gerarDadosTabela(): void {
    let limit = 10;
    if (this.carregado) {
        limit = 50;
    } else {
        limit = 10;
    }
    
    this.headerTable.dados = [];

    this.carregado = !this.carregado;
    console.log(this.headerTable);
  }
  gerarDadosTabelaEmpresa(): void {
    let limit = 10;
    if (this.carregado) {
        limit = 50;
    } else {
        limit = 10;
    }
    
    this.empresaTable.dados = [];
    for (let i = 0; i < limit; i++) {
        this.empresaTable.dados.push({
            '': {
              'targets': 0,
              'searchable': false,
              'orderable': false,
              'className': 'dt-body-center',
              'render': function (data, type, full, meta){
                  return `<input type="checkbox" name="id[]" value="${i}">`;
              }
            },
            'Inscrição Estadual': { label: `Inscrição Estadual  ${i}`, isLink: true },
            'Razão Social': { label: `Razao  ${i}`, isLink: false },
        });
        
    }
    this.carregado = !this.carregado;
    console.log(this.empresaTable);
  }

  action(evt) {
    console.log('action', evt);
        
         this.abrirVisualizacao();
    }
  filter(): void {
    this.markFormGroupTouched(this.formFiltro);
  }

  abrirModalPesquisaEmpresa():void{
    this.modalService.abre('PesquisaEmpresaModal');
  }

  abrirSolicitacaos(){
      this.solicitacao =true;
  }
  cancelarSolicitacao(){
    this.solicitacao =false;
  }

  abrirVisualizacao(){
     this.visualizacao =true;
   }
   cancelarVisualizacao(){
     this.visualizacao =false;
   }


  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
        control.markAsTouched();

        if (control.controls) {
            this.markFormGroupTouched(control);
        }
    });
}
     salvarSolicitacao(){
      Swal.fire({
        title: 'Visualização Solicitação',
        text: `Deseja realmente salvar esta Solicitação?`,
        type: 'info',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
        
        
    })
  .then((result)=>{
    if(result){
      console.log('false');
        this.solicitacao =false;
        Swal.fire({
          title: 'Visualização Solicitação',
          text: `Salvo com sucesso!`,
          type: 'success'
        })
  }else{
    this.solicitacao =false;
    console.log('true');
  }
  });
}

}
