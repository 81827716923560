<div class="page-header">
  <div class="page-block">
      <div class="row align-items-center">
          <div class="col-md-12">
              <div class="page-header-title">
                  <h5 class="m-b-10">
                    {{title}}
                  </h5>
              </div>
              <app-breadcrumb></app-breadcrumb>
          </div>
      </div>
  </div>
</div>
<div class="main-body">
  <div class="page-wrapper">
    <ng-content></ng-content>
  </div>
</div>
