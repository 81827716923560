import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl } from "@angular/forms";

import { SelectItem } from "src/app/shared/select/select-option";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"]
})
export class SelectComponent {
  @Output() onClick = new EventEmitter<boolean>();
  @Output() onFocus = new EventEmitter<boolean>();
  @Output() onBlur = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<boolean>();

  @Input() label: string;
  @Input() textoSelecao: string = "Selecione";
  @Input() options: SelectItem[];
  @Input() textoObrigatorio: string = "Campo obrigatório";
  @Input() formGroup: FormGroup;
  @Input() nome: string;
  @Input() noLabel: boolean;

  required: any = '';
  
  constructor() {}

  get isRequired() {
    if (this.formGroup.get(this.nome).validator) {
      const validator = this.formGroup.get(this.nome).validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  eventoClick(): void {
    this.onClick.emit(true);
  }

  eventoFocus(): void {
    this.onFocus.emit(true);
  }

  eventoBlur(): void {
    this.onBlur.emit(true);
  }

  eventoChange(): void {
    this.onChange.emit(this.formGroup.controls[this.nome].value);
  }
}
