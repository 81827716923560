import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-e-charts-pie',
    templateUrl: './e-charts-pie.component.html',
    styleUrls: ['./e-charts-pie.component.scss']
})
export class CrtEchartPieComponent implements OnInit {
    public lineBasicOption: any;
    public lineAreaOption: any;
    public barBasicColumnOption: any;
    public barBasicBarOption: any;
    public pieBasicColumnOption: any;
    public pieDoughnutOption: any;
    public pieTimelineOption: any;
    public gaugeOption: any;

    @Input() name: string;
    @Input() captions: Array<string>;
    @Input() data: Array<any>;

    constructor() {}

    ngOnInit() {
        console.log('data', this.data);

        this.pieBasicColumnOption = {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
                orient: 'vertical',
                x: 'left',
                data: this.captions
            },
            color: ['#f4c22b', '#A389D4', '#3ebfea', '#04a9f5', '#1de9b6'],
            toolbox: {
                show: false,
                feature: {
                    mark: {
                        show: true
                    },
                    dataView: {
                        show: true,
                        readOnly: false
                    },
                    magicType: {
                        show: true,
                        type: ['pie', 'funnel'],
                        option: {
                            funnel: {
                                x: '25%',
                                width: '50%',
                                funnelAlign: 'left',
                                max: 1548
                            }
                        }
                    },
                    restore: {
                        show: true
                    },
                    saveAsImage: {
                        show: true
                    }
                }
            },
            calculable: true,
            series: [
                {
                    name: this.name,
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '60%'],
                    data: this.data
                }
            ]
        };
    }
}
