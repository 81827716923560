<section
    *ngIf="options && idParam"
    class="header-user-list"
    [ngClass]="{ open: open }"
>
    <div class="h-list-body">
        <a class="h-close-text" (click)="open = !open" href="javascript:">
            <i
                [ngClass]="open ? 'icon-chevrons-right' : 'icon-chevrons-left'"
                class="feather"
            ></i>
        </a>
        <div class="main-friend-cont scroll-div">
            <div class="h-list-body">
                <div class="pcoded-menu-caption">
                    <label>Navegação</label>
                    <!-- <a *ngIf="backBtn" (click)="back()">Voltar</a> -->
                </div>
                <div
                    *ngFor="let item of options"
                    class="media menu-item align-items-center justify-content-center"
                >
                    <div class="media-body">
                        <a
                            (click)="setMenu(item)"
                            [routerLink]="
                                item.url
                                    ? idParam != 0
                                        ? [
                                              '/' +
                                                  currentRoute +
                                                  '/' +
                                                  idParam +
                                                  '/' +
                                                  item.url
                                          ]
                                        : ['/' + currentRoute + '/' + item.url]
                                    : []
                            "
                            class="link"
                        >
                            <span class="float-left d-flex align-items-center">
                                <!-- <app-icone
                                    [nome]="item.icon"
                                    posicao="left"
                                ></app-icone> -->
                            </span>
                            <a class="m-0 d-inline">
                                {{ item.name }}
                            </a>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
