import { AbstractControl } from "@angular/forms";

import { CpfHelper } from "../cpf/cpf.helper";
import { CnpjHelper } from "../cnpj/cnpj.helper";
import { TelefoneHelper } from "../telefone/telefone.helper";
import { CepHelper } from "../cep/cep.helper";
import { EmailHelper } from "../email/email.helper";

export function ValidatorCPF(control: AbstractControl): { [key: string]: boolean } | null {
  const helper: CpfHelper = new CpfHelper();
  if (!helper.validate(control.value)) {
    return { notValid: true };
  }

  return null;
}

export function ValidatorCNPJ(control: AbstractControl): { [key: string]: boolean } | null {
  const helper: CnpjHelper = new CnpjHelper();
  if (!helper.validate(control.value)) {
    return { notValid: true };
  }

  return null;
}

export function ValidatorTelefone(control: AbstractControl): { [key: string]: boolean } | null {
  const helper: TelefoneHelper = new TelefoneHelper();
  if (!helper.validate(control.value)) {
    return { notValid: true };
  }

  return null;
}

export function ValidatorCep(control: AbstractControl): { [key: string]: boolean } | null {
  const helper: CepHelper = new CepHelper();
  if (!helper.validate(control.value)) {
    return { notValid: true };
  }

  return null;
}


export function ValidatorEmail(control: AbstractControl): { [key: string]: boolean } | null {
  const helper: EmailHelper = new EmailHelper();
  if (!helper.validate(control.value)) {
    return { notValid: true };
  }

  return null;
}

