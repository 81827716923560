import { Component, OnInit } from '@angular/core';
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder
} from '@angular/forms';

import { AgenteFiscalService } from './agente-fiscal.service';
import { ComunicadosService } from '../../services/comunicados/comunicados.service';
import { SweetAlertService } from '../../services/sweet-alert/sweet-alert.service';

import { PageLoaderService } from 'src/app/components/page-loader/page-loader.service';
import { ModalService } from 'src/app/shared/modal/modal.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
    selector: 'app-agente-fiscal',
    templateUrl: './agente-fiscal.component.html',
    styleUrls: ['./agente-fiscal.component.scss']
})
export class AgenteFiscalComponent implements OnInit {
    comunicado: any;
    matriculas: any = [];
    agenda: any = [];
    monitoramento: any = [];

    dateRange: object;

    formFiltro = new FormGroup({
        tipo: new FormControl('todos'),
        projetosAtivos: new FormControl('')
    });

    formMatriculaNome = new FormGroup({
        matricula: new FormControl(''),
        nome: new FormControl('')
    });

    formComunicado = new FormGroup({
        para: new FormControl(''),
        assunto: new FormControl(''),
        texto: new FormControl('')
    });
    carregado: boolean;

    constructor(
        private comunicadosService: ComunicadosService,
        private agenteFiscalService: AgenteFiscalService,
        private loaderService: PageLoaderService,
        private sweetAlert: SweetAlertService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        private router: Router
    ) {}

    ngOnInit() {

        //this.mensagemErro();

        this.matriculas = this.agenteFiscalService.getMatriculas();
        this.agenda = this.agenteFiscalService.getAgenda();
        this.monitoramento = this.agenteFiscalService.getMonitoramento();
        this.comunicado = this.comunicadosService.getComunicado();
        this.gerarAgenda();

        this.formFiltro = this.formBuilder.group({
            dataRange: ['', [Validators.required]],
            tipo: ['todos', [Validators.required]],
            periodo: ['', [Validators.required]],
            assunto: ['', [Validators.required]]
        });
    }

    mensagemErro() {
        Swal.fire({
          title: 'Desculpe',
          text: 'Recursos indisponíveis para o seu perfil de acesso.',
          type: 'error',
          confirmButtonText: 'OK'
        }).then(() => {
          this.router.navigate(['/painel-contribuinte']);
        });
      }

    validacaoFormulario(): void {
        console.log(this.formFiltro);
        this.markFormGroupTouched(this.formFiltro);
        if (this.formFiltro.valid) {
            this.loaderService.exibir();
            setTimeout(() => {
                this.loaderService.esconder();
                window.scrollTo(0, window.innerHeight - 200);
            }, 2000);
        }
    }

    markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    gerarAgenda(): void {
        let limit = 10;
        if (this.carregado) {
            limit = 50;
        } else {
            limit = 10;
        }

        this.agenda.dadosAgenda = [];
        for (let i = 2; i < limit; i++) {
            this.agenda.dadosAgenda.push({
                id: 1,
                Agenda: { label: ` Agenda` },
                'Data Inicio': { label: `${i}/08/2019` },
                'Data Fim': { label: `${i}/08/2019` },
                Identificador: {
                    label: `Incluso Empresa 06.394.594-0 - nome empresa A`,
                    isLink: false
                },
                Atividade: { label: 'Homologar Inclusão empresas' },
                'Tipo Medida de Gestão': { label: 'Homologar' },
                Solicitante: {
                    label: 'MARISTELA MYRIAN ARAUJO',
                    isLink: false
                },
                Situação: { isStatus: true, status: 'aguardando-aprovacao' },
                Aprovar: { icon: 'check', isIcon: true, isLink: true },
                Rejeitar: {
                    icon: 'x',
                    isIcon: true,
                    isLink: true
                },
                Ordem: { label: ` 423434532` }
            });
        }
        this.carregado = !this.carregado;
    }
    action(evt) {
        console.log('action', evt);
        if (evt.data.Rejeitar) {
            console.log('rejeitar', evt.data.Rejeitar);
            Swal.fire({
                title: 'Rejeitar Solicitação!',
                text: `Deseja realmente rejeitar inclusão desta empresa no projeto?`,
                type: 'info',
                showCancelButton: true,
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim'
            }).then(result => {
                console.log('REJEITADO SUCCESS!');
            });
        }
    }

    help() {
        const html = `<div class="help-content">
                      <div class="row">
                        <div class="col-12">
                          <h6>PLANEJADA</h6>
                          <p>Empresa selecionada pela CATRI/CEPAC para compor o projeto
                           de Monitoramento Fiscal e ainda sem designação;</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h6>VETADA</h6>
                          <p>A empresa não faz mais parte do projeto, por ter sido vetada
                           pelo Orientador ou pelo Supervisor, por motivo justificável.
                            Antes da designação do Agente Fiscal;</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h6>PENDENTE</h6>
                          <p>Superior gerou o Procedimento Administrativo de Monitoramento Fiscal - PAM,
                           a empresa foi distribuida aleatoriamente para Agentes Fiscais, pelo SIGET.
                            Aguardando que o Supervisor Confirme ou Altere a designação do Agente Fiscal;</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h6>DESIGNADA</h6>
                          <p>O PAM foi emitido e o Agente Fiscal designado. Antes da emissão do
                           Mandado de Monitoramento FIscal;</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h6>ANULADA</h6>
                          <p>A empresa não faz mais parte do projeto, por ter sido anulada pelo
                           Orientador ou pelo Supervisor, por motivo justificável, após ter sido
                            designada para Agente Fiscal. Só é possivel anular uma empresa de
                             um projeto antes do Agente Fiscal emitir Mandado de Monitoramento;</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h6>DEVOLVIDA/REJEITADA</h6>
                          <p>O Agente Fiscal se declara impedido, por motivo justificável,
                           de realizar o Monitoramento Fiscal e devolve a empresa do PAM. A empresa,
                           com a Situação "Devolvida", será designada para outro Agente Fiscal;</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h6>EM ABERTO</h6>
                          <p>Empresa com Mandado de Monitoramento emitido pelo Agente Fiscal.
                           Antes do Ciente do Contribuinte;</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h6>EM EXECUÇÃO</h6>
                          <p>Monitoramento Fiscal iniciado e dentro do prazo de validade do projeto,
                           após o Contribuinte dar Ciência ao Mandado de Monitoramento Fiscal;</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h6>VENCIDA</h6>
                          <p>Encerrou-se o período de vigência do Monitoramento Fiscal, o PAM foi iniciado,
                           porém o Agente Fiscal não emitiu o Termo de Encerramento;</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h6>CONCLUÍDA</h6>
                          <p>Emitido o Termo de Encerramento e dada a ciência pelo Contribuinte.</p>
                        </div>
                      </div>
                    </div>`;

        this.sweetAlert.mostrar(
            html,
            'info',
            'Situação do Monitoramento Fiscal',
            'OK'
        );
    }

    setRange(range: object) {
        this.dateRange = range;
    }

    gerarComunicado() {
        this.modalService.abre('comunicadoModal');
    }

    filter(): void {
        this.loaderService.exibir();
        setTimeout(() => {
            this.loaderService.esconder();
        }, 2000);
    }

    pesquisarMatriculaNome() {
        this.modalService.abre('matriculaNomeModal');
    }

    enviarComunicado() {
        this.modalService.fecha('comunicadoModal');
        this.sweetAlert.mostrar(
            'Comunicado enviado com sucesso!',
            'success',
            'Sucesso!',
            'OK'
        );
    }
    fechaModal(id: string) {
        this.modalService.fecha(id);
    }
    fecharModalComunicado() {
        this.modalService.fecha('comunicadoModal');
    }

    //   fechaMatriculaModal(){
    //     Swal.fire({
    //       title: 'Tem Certeza?',
    //       text: `Tem Certeza que deseja sair?`,
    //       type: 'warning',
    //       showCancelButton: true,
    //       cancelButtonText: 'Não',
    //       confirmButtonText: 'Sim'
    //   }).then(result => {
    //         this.modalService.abre('comunicadoModal');
    //   });
    //  }
}
