import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CERTIFICADOS } from '../../shared/certificados/certificados.mock';

@Injectable({
  providedIn: 'root'
})
export class CertificadoService {

  url: string = 'https://webpki.lacunasoftware.com/api/Signature/Start';

  constructor(private http: HttpClient) {}

  gerarAssinatura(thumbprint: string): Observable<any> {

    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Content-Type', 'application/json');

    const params = new HttpParams();
    params.set('certificate', thumbprint);

    return this.http.post<string>(this.url, {params}, {headers});
  }

  gerarNonce(length: number = 40): string {
    let arr = new Uint8Array(length / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, (dec) => {
      return ('0' + dec.toString(16)).substr(-2);
    }).join('');
  }

  gerarMock(): any[] {
    return CERTIFICADOS;
  }
}