    <form 
        id="geocoding_form1" 
        method="post" 
        *ngIf="pesquisa">
        <div 
            class="input-group input-group-button mb-3">
            <input 
                class="form-control" 
                id="address" 
                placeholder="Digite o endereço"
                type="text" >
            <span 
                class="input-group-addon" 
                id="basic-addon1">
                <button 
                    class="btn btn-primary m-0">Pesquisar</button>
            </span>
        </div>
    </form>
    <div 
        class="set-map"
        [id]="id"
        [style.height.px]="height">
    </div>