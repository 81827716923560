import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from 'src/app/components/components.module';
import { NaoEncontradoComponent } from './nao-encontrado.component';

const items = [
  NaoEncontradoComponent
];

@NgModule({
  declarations: items,
  imports: [CommonModule, RouterModule, ComponentsModule],
  exports: items,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NaoEncontradoModule {}
