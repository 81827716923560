<div
  [style.height]="height"
  class="progress">
  <div
    [ngClass]="{
      'bg-success': cor == 'success',
      'btn-secondary': cor == 'secondary',
      'bg-info': cor == 'info',
      'bg-warning': cor == 'warning',
      'bg-danger': cor == 'danger',
      'progress-bar-striped': mostrarListras,
      'progress-bar-animated': mostrarAnimacao
    }"
    class="progress-bar"
    role="progressbar"
    [style.width]="porcentagem + '%'">
    <span *ngIf="mostrarPorcentagem">
      {{porcentagem | number: '2.0-2'}}%
    </span>
  </div>
</div>