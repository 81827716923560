import { Component } from '@angular/core';
import { PageLoaderService } from './page-loader.service';

@Component({
    selector: 'app-page-loader',
    templateUrl: './page-loader.component.html',
    styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent {
    constructor(public pageLoaderService: PageLoaderService) {}
}
