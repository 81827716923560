import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ElementRef
} from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
    @Output() onClick = new EventEmitter<boolean>();
    @Output() onFocus = new EventEmitter<boolean>();
    @Output() onBlur = new EventEmitter<boolean>();
    @Output() onChange = new EventEmitter<string>();
    @Output() onKeyup = new EventEmitter<string>();

    @Input() nome: string;
    @Input() placeholder: string = '';
    @Input() type: string = 'text';
    @Input() observacoes: string;
    @Input() label: string;
    @Input() noLabel: boolean;
    @Input() textoInvalido: string = '';
    @Input() textoObrigatorio: string = 'Campo obrigatório';
    @Input() formGroup: FormGroup;
    @Input() mask: string;

    valorAntigo: any;

    /** VALOR **/
    _valor: string;
    get valor(): string {
        return this._valor;
    }

    @Input('valor')
    set valor(value: string) {
        this.formGroup.get(this.nome).setValue(value);
        this._valor = value;
    }

    /** DISABLED **/
    @Input('disabled')
    set disabled(value: boolean) {
        const control = this.formGroup.get(this.nome);
        if (control) {
            value !== false ? control.disable() : control.enable();
        }
    }

    /** READONLY **/
    @Input('readonly')
    set readonly(value: boolean) {
        const control = this.formGroup.get(this.nome);
        if (control) {
            value !== false ? control.disable() : control.enable();
        }
    }

    /** CNPJ **/
    _cnpj: boolean;
    get cnpj(): boolean {
        return this._cnpj;
    }

    @Input('cnpj')
    set cnpj(value: boolean) {
        this._cnpj = true;
    }

    /** CPF **/
    _cpf: boolean;
    get cpf(): boolean {
        return this._cpf;
    }

    @Input('cpf')
    set cpf(value: boolean) {
        this._cpf = true;
    }

    /** CEP **/
    _cep: boolean;
    get cep(): boolean {
        return this._cep;
    }

    @Input('cep')
    set cep(value: boolean) {
        this._cep = true;
    }

    /** Telefone **/
    _telefone: boolean;
    get telefone(): boolean {
        return this._telefone;
    }

    @Input('telefone')
    set telefone(value: boolean) {
        this._telefone = true;
    }

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        this.generateMask();
    }

    generateMask(): void {
        let name;
        let textoErro;

        switch (true) {
            case this.cnpj:
                this.mask = '00.000.000/0000-00';
                name = 'CNPJ';
                break;

            case this.cpf:
                this.mask = '000.000.000-00';
                name = 'CPF';
                break;

            case this.cep:
                this.mask = '00000-000';
                name = 'CEP';
                break;

            case this.telefone:
                this.mask = '(00) 0000-00009';
                textoErro = 'O telefone deve conter entre 10 e 11 dígitos';
                name = 'Telefone';
                break;

            default:
                this.mask = this.mask || '';
                name = this.gerarNome(this.nome);
                break;
        }

        this.textoInvalido = textoErro || `${name} não é válido`;
        this.placeholder = this.placeholder || name;
        this.label = this.label || name;
    }

    eventoClick(): void {
        this.onClick.emit(true);
    }

    eventoFocus(): void {
        this.onFocus.emit(true);
    }

    eventoBlur(): void {
        this.onBlur.emit(true);
    }

    eventoChange(): void {
        this.onChange.emit(this.formGroup.controls[this.nome].value);
    }

    eventoKeyup(): void {
        const valor = this.formGroup.controls[this.nome].value
        if (this.valorAntigo !== valor) {
            this.onKeyup.emit(valor);
            this.valorAntigo = valor;
        }
    }

    get isRequired() {
        if (this.formGroup.get(this.nome).validator) {
            const validator = this.formGroup
                .get(this.nome)
                .validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }

        return false;
    }

    private gerarNome(nome: string): string {
        if (nome) {
            nome = nome.charAt(0).toUpperCase() + nome.substr(1).toLowerCase();
        }

        return nome;
    }
}
