import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { ChartComponent } from 'src/app/components/chart/chart.component';
import { OfflineService } from 'src/app/services/offline/offline.service';

declare var GMaps: any;

@Component({
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements AfterViewInit {
    @ViewChild('chart1', { read: ChartComponent }) chart1: ChartComponent;
    @ViewChild('chart2', { read: ChartComponent }) chart2: ChartComponent;
    @ViewChild('chart3', { read: ChartComponent }) chart3: ChartComponent;
    @ViewChild('chart4', { read: ChartComponent }) chart4: ChartComponent;

    constructor(public newsService: OfflineService) {
        newsService.verificaConexao();
    }

    ctx: any;
    value: string = '';
    cpf: string = '41286827809';
    cnpj: string = '00000000000191';
    cep: string = '04904904';
    telefone: string = '11987498938';
    email: string = 'teste@teste.com';
    label: any = '';

    ngAfterViewInit() {
        setTimeout(() => {
            this.chart1.rotulo = [
                'Segunda',
                'Terça',
                'Quarta',
                'Quinta',
                'Sexta'
            ];
            this.ctx = this.chart1.exemploMockup();

            this.chart2.rotulo = [
                'Segunda',
                'Terça',
                'Quarta',
                'Quinta',
                'Sexta'
            ];
            this.ctx = this.chart2.exemploMockup();

            this.chart3.rotulo = [
                'Segunda',
                'Terça',
                'Quarta',
                'Quinta',
                'Sexta'
            ];
            this.ctx = this.chart3.exemploMockup();

            this.chart4.rotulo = [
                'Segunda',
                'Terça',
                'Quarta',
                'Quinta',
                'Sexta'
            ];
            this.ctx = this.chart4.exemploMockup();
        }, 100);
    }

    printValue() {
        // console.log(this.value);
    }
}
