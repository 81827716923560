import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.html'
})
export class InputFileComponent {

  @Input() textoSeleione: string = 'Selecione um arquivo...';
  @Input() multiple: boolean = false;
  @Input() files: any[] = [];

  onChange(event) {
    const files = event.target.files;
    if (files.length) this.files = event.target.files;
  }

  get fileNames() {
    return Array.prototype.map.call(this.files, file => file.name);
  }
}
