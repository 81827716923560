import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-dragndrop',
  templateUrl: './file-dragndrop.component.html'
})
export class FileDragndropComponent {

  @Input() id: string = `inputDragndrop${new Date().getTime()}`;
  @Input() action: string;
}
