 import { Component, OnInit } from '@angular/core';


 @Component({
   selector: 'app-e-charts-lines',
   templateUrl: './e-charts-lines.component.html',
   styleUrls: ['./e-charts-lines.component.scss']
 })
 export class CrtEchartComponent implements OnInit {
   public lineBasicOption: any;
   public lineAreaOption: any;
   public barBasicColumnOption: any;
   public barBasicBarOption: any;
   public pieBasicColumnOption: any;
   public pieDoughnutOption: any;
   public pieTimelineOption: any;
   public gaugeOption: any;

   constructor() { }

   ngOnInit() {
     this.lineBasicOption = {
       tooltip: {
         trigger: 'axis'
       },
       xAxis: {
         type: 'category',
         data: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
       },
       yAxis: {
         type: 'value'
       },
       color: '#04a9f5',
       series: [{
         data: [1, 5, 3, 6, 4, 8, 10, 11, 13, 0, 20, 40],
         type: 'line',
       }]
     };
   }
 }
