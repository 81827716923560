import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PageLoaderService {
    public carregando = new BehaviorSubject<boolean>(false);

    exibir() {
        this.carregando.next(true);
    }

    esconder() {
        this.carregando.next(false);
    }
}
