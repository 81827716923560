<div class="auth-wrapper">
    <div class="auth-content">
        <div class="card margin__card">
            <form [formGroup]="loginForm" (submit)="login($event)">
                <div class="card-body text-center">
                    <div class="mb-4">
                        <img src="./assets/images/Logo_Prodata.svg" />
                    </div>
                    <h3 class="mb-4">Acesso</h3>
                    <app-input
                        [formGroup]="loginForm"
                        [noLabel]="true"
                        nome="cpf"
                        cpf
                        (onKeyup)="validarCPF()"
                    ></app-input>

                    <app-input
                        [formGroup]="loginForm"
                        [noLabel]="true"
                        type="password"
                        mask="9999999999"
                        nome="senha"
                    ></app-input>

                    <app-button
                        cor="secondary"
                        class="mr-2"
                        nome="voltar"
                        routerLink="/acesso"
                        tipo="button"
                    >
                        Voltar
                    </app-button>

                    <app-button
                        [desativado]="loginForm.invalid"
                        nome="enviar"
                        tipo="submit"
                    >
                        Entrar
                    </app-button>

                    <p class="my-4 text-muted">
                        Esqueceu seus dados de acesso?
                        <a routerLink="/recuperacao-senha">Podemos ajudar</a>
                    </p>
                </div>
            </form>
        </div>
    </div>

    <!-- <app-alert
        *ngIf="alerta.show"
        [cor]="alerta.cor"
        (clickDismiss)="alerta = {}"
        [texto]="alerta.texto"
        [id]="alerta.id"
        [close]="true"
    ></app-alert> -->
</div>
