export class CpfHelper {

  validate(cpf: string) {
    const tamanho = cpf ? cpf.length : 0;
    return tamanho == 0 || (!this.isRepeatingNumbersCpf(cpf) && this.isValidCPF(cpf));
  }

  private mod11(num) {
    return num % 11;
  }

  private isEqual(a) {
    return b => b === a;
  }

  private mergeDigits(num1, num2) {
    return `${num1}${num2}`;
  }

  private getTwoLastDigits(cpf) {
    return `${cpf[9]}${cpf[10]}`;
  }

  private getCpfNumeral(cpf) {
    return cpf.substr(0, 9).split('');
  }

  private isRepeatingNumbersCpf(str) {
    return str.split('').every((elem) => elem === str[0]);
  }

  private toSumOfProducts(multiplier) {
    return (result, num, i) => result + (num * multiplier--);
  }

  private getSumOfProducts(list, multiplier) {
    return list.reduce(this.toSumOfProducts(multiplier), 0);
  }

  private getValidationDigit(multiplier) {
    return (cpf) => this.getDigit(this.mod11(this.getSumOfProducts(cpf, multiplier)));
  }

  private getDigit(num) {
    return (num > 1) ?
      11 - num :
      0;
  }

  private isValidCPF(cpf) {
    const CPF = this.getCpfNumeral(cpf);
    const firstDigit = this.getValidationDigit(10)(CPF);
    const secondDigit = this.getValidationDigit(11)(CPF.concat(firstDigit));

    return this.isEqual(this.getTwoLastDigits(cpf))(this.mergeDigits(firstDigit, secondDigit));
  }
}
