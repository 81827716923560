import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable()
export class ContribuinteService {

  private apiUrl = environment.API_URL;

  constructor(private http: HttpClient) { }

  // getContribuintes(pagina) {
  //   return this.http.get(
  //     `https://prodata.giss.com.br/api/contribuinte/cadastro/param?pagina=${pagina}&tamanhoPagina=5`
  //   )
  // }

  getContribuintes(searchParams) {
    const params = this.setParams(searchParams)
    return this.http.get(
      `${this.apiUrl}/contribuinte/param`, { params }
    )
  }

  getContribuinte(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/id/${codigo}`
    )
  }

  getQuantitativosEventuais() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/contar/tipoContribuinte/eventual`
    );
  }

  getAnaliseFiscalContribuinte(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/analiseFiscal/id/${codigo}`
    )
  }

  getSocio(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/socio/id/${codigo}`
    )
  }

  getSocios(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/socio/contribuinte/${codigo}`
    )
  }

  getTelefoneSocio(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/telefone?codigo=${codigo}`
    )
  }

  getDadosComplementares(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/contribuinte/dadosComplementares/${codigo}`
    )
  }

  getEndereco(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/cadastro/endereco/${codigo}`
    )
  }

  getEnderecos(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/cadastro/endereco/listarEnderecos/${codigo}`
    )
  }

  getContadorAdministrador(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/procurador/buscarContadorAdministrador/${codigo}`
    )
  }

  getProcuradores(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/procurador/buscarProcuracoesPorContribuinte/${codigo}`
    )
  }

  filtrarContribuintes(seletorFiltro, dadoFiltro) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/cadastro/param?tipo=${seletorFiltro}&dado=${dadoFiltro}`
    )
  }

  getImoveis(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/imovel/${codigo}`
    )
  }

  editarImovel(codigo, obj) {
    return this.http.put(
      `${this.apiUrl}/contribuinte/imovel/${codigo}`, obj
    )
  }

  getTipoUsoImovel() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/imovel/listarTiposImoveis`
    );
  }

  getListaBic() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/solicitacao/tiposSolicitacaoProdata`
    )
  }

  getGed() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/ged/listarTipoGeds`
    )
  }

  getLegendasIndicadores() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/listarLegendasIndicadores`
    );
  }

  getIndicador(codigo: string) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/indicador/${codigo}`
    );
  }

  getDadosSolicitacoes(contribuinte, pagina, tamanhoPagina, protocolo = null) {
    let params = new HttpParams()
      .set('contribuinte', contribuinte)
      .set('pagina', pagina.toString())
      .set('tamanhoPagina', tamanhoPagina.toString());

    if (protocolo) {
      params = params.set('protocolo', protocolo);
    }

    return this.http.get(`${this.apiUrl}/contribuinte/solicitacao/buscarPorContribuinte`, { params });
  }


  getDadosMovimentacoes(codigo, pagina, tamanhoPagina) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/solicitacao/movimentacao?pagina=${pagina}&tamanhoPagina=${tamanhoPagina}&solicitacao=${codigo}`
    )
  }

  getDadosDetalhes(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/solicitacao/movimentacao/id/${codigo}`
    )
  }

  getFundamentacoes() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/solicitacao/movimentacao/tiposDeFundamentacoesLegais`
    )
  }

  getListaSituacao() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/solicitacao/movimentacao/tiposDeMovimentacao`
    )
  }

  solicitarMovimentacao(obj) {
    return this.http.post(
      `${this.apiUrl}/contribuinte/solicitacao/movimentacao`, obj
    )
  }

  criarSolicitacao(obj) {
    return this.http.post(
      `${this.apiUrl}/contribuinte/solicitacao`, obj
    )
  }

  criarObservacao(obj) {
    return this.http.post(
      `${this.apiUrl}/contribuinte/solicitacao/movimentacao/observacao`, obj
    )
  }

  criarFundamentacao(obj) {
    return this.http.post(
      `${this.apiUrl}/contribuinte/solicitacao/movimentacao/fundamentacaoLegal`, obj
    )
  }

  criarDocumento(obj) {
    return this.http.post(
      `${this.apiUrl}/contribuinte/solicitacao/movimentacao/documento`, obj
    )
  }

  obterDocumentos(codigo, searchParams) {
    const params = this.setParams(searchParams)
    return this.http.get(
      `${this.apiUrl}/contribuinte/ged/filtrar/contribuinte/${codigo}`, { params }
    )
  }

  adicionarDocumentoGed(obj) {
    return this.http.post(
      `${this.apiUrl}/contribuinte/ged`, obj
    )
  }

  buscarArquivosDesassociados(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/ged/buscarArquivosDesassociados?contribuinte=${codigo}`
    )
  }

  associarDocumentos(obj) {
    return this.http.post(
      `${this.apiUrl}/contribuinte/ged/associarGeds`, obj
    )
  }

  consultaProtocoloAdminstrativo(protocolo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/solicitacao/movimentacao/consultaProtocoloAdministrativo/?protocoloAdministrativo=${protocolo}`
    )
  }
  getListaCnae(params?) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/listarCnae`,
      { params: params }
    )
  }

  getListaEstabelecimentos() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/listarCategoriasEstabelecimento`
    )
  }

  getListaRegimeRecolhimento() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/listarTipoRegimeRecolhimento`
    )
  }

  getListaNaturezaJuridica() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/listarNaturezaJuridica`
    )
  }

  alterarPerfilCadastro(obj, id) {
    return this.http.put(
      `${this.apiUrl}/contribuinte/cadastro/alterarPerfilCadastro/${id}`, obj
    );
  }

  getRedeSim(codigo, searchParams) {
    const params = this.setParams(searchParams)
    return this.http.get(
      `${this.apiUrl}/contribuinte/redeSim/historicoEvento/contribuinte/${codigo}`,
      { params }
    )
  }

  getTipoEventos() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/redeSim/listaEventos`
    )
  }

  getRedeSimCadastro(searchParams) {
    const params = this.setParams(searchParams)
    return this.http.get(
      `${this.apiUrl}/contribuinte/redeSim/historicoEvento/cadastro`, { params }
    )
  }

  getTipoEfeitos() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/redeSim/listaTipoEfeito`
    )
  }

  getQuantidadeContribuintesMenuCadastro() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/contar/tipoContribuinte/contribuinte`
    )
  }

  getSituacoesIe() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/listarSituacaoIE`
    )
  }

  getSituacoesCpfCnpj() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/listarTipoSituacaoCnpj`
    )
  }

  getOutrasEmpresasContador(seqCadastro, contributorId) {
    return this.http.get(`${this.apiUrl}/contribuinte/procurador/buscarOutrasEmpresasContador/${seqCadastro}/${contributorId}`);
  }

  getOutrasEmpresasSocio(seqCadastro, contributorId) {
    return this.http.get(`${this.apiUrl}/contribuinte/socio/cadastro/socios/${seqCadastro}/${contributorId}`);
  }

  validaHigienizacaoContribuinte(codigo) {
    return this.http.get(`${this.apiUrl}/contribuinte/validaContribuinteAnaliseFiscal/${codigo}`);
  }

  higienizarContribuinte(codigo) {
    return this.http.put(`${this.apiUrl}/contribuinte/higienizarContribuinte/${codigo}`, {});
  }

  getHistoricoSituacaoIe(id) {
    return this.http.get(`${this.apiUrl}/contribuinte/cadastro/historicoSituacao/${id}`);
  }

  getDividaAtiva(codigo) {
    return this.http.get(`${this.apiUrl}/contribuinte/dividaAtiva/${codigo}`);
  }

  adicionarObservacao(obj) {
    return this.http.post(`${this.apiUrl}/contribuinte/observacaoContribuinte`, obj);
  }

  getObservacao(id) {
    return this.http.get(`${this.apiUrl}/contribuinte/observacaoContribuinte/${id}`);
  }

  // ----- Bens Móveis -----
  getBensMoveis(id) {
    return this.http.get(`${this.apiUrl}/contribuinte/bemMovel/${id}`);
  }

  getDetalhesBensMoveis(id) {
    return this.http.get(`${this.apiUrl}/contribuinte/bemMovel/listarBemVeiculo/${id}`);
  }

  getListarOrigemCadastros(){
    return this.http.get(
      `${this.apiUrl}/contribuinte/listarOrigemCadastros`
    );
  }

  setParams(searchParams) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

}
