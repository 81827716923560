import { Menu } from '../menu/menu';

export const MENUS: Menu[] = [
  {
    id: 1,
    name: 'Consultar',
    icon: 'list',
    url: '/exemplo',
  } as Menu,
  {
    id: 2,
    name: 'Adicionar',
    icon: 'edit',
    url: 'adicionar',
    children: [
      {
        id: 21,
        name: 'Sub-Menu',
        url: ''
      }
    ]
  } as Menu
];
