import { AfterViewInit, Component, Injector, Input, OnInit, ViewChild, forwardRef } from "@angular/core";
import { DatePipe } from "@angular/common";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";

import { DateTimeEndModel } from "./date-time-end.model";
import { NgbDateStruct, NgbDatepicker, NgbPopover, NgbPopoverConfig, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

import { noop } from "rxjs";

@Component({
  selector: 'app-datetime-end-picker',
  templateUrl: './datetime-end-picker.component.html',
  styleUrls: ['./datetime-end-picker.component.scss'],
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeEndPickerComponent),
      multi: true
    },
  ],
})

export class DatetimeEndPickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {

  @Input() dateString: string;

  @Input() inputDatetimeFormat = "d/M/yyyy H:mm";

  @Input() hourStep = 1;

  @Input() minuteStep = 1;

  @Input() secondStep = 30;

  @Input() seconds = false;

  @Input() disabled = false;

  private showTimePickerToggle = false;

  private datetime: DateTimeEndModel = new DateTimeEndModel();
  private firstTimeAssign = true;

  @ViewChild(NgbPopover, { static: true })
  private popover: NgbPopover;

  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  ngControl: NgControl;

  constructor(private config: NgbPopoverConfig, private inj: Injector) {
    config.autoClose = 'outside';
    config.placement = 'auto';
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
  }

  ngAfterViewInit(): void {
    this.popover.hidden.subscribe(($event) => {
      this.showTimePickerToggle = false;
    });
  }

  writeValue(newModel: string) {
    if (newModel) {
      this.datetime = Object.assign(
        this.datetime,
        DateTimeEndModel.fromLocalString(newModel)
      );
      this.dateString = newModel;
      this.setDateStringModel();
    } else {
      this.datetime = new DateTimeEndModel();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleDateTimeState($event) {
    this.showTimePickerToggle = !this.showTimePickerToggle;
    $event.stopPropagation();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChange($event: any) {
    const value = $event.target.value;
    const dt = DateTimeEndModel.fromLocalString(value);

    if (dt) {
      this.datetime = dt;
      this.setDateStringModel();
    } else if (value.trim() === '') {
      this.datetime = new DateTimeEndModel();
      this.dateString = '';
      this.onChange(this.dateString);
    } else {
      this.onChange(value);
    }
  }

  onDateChange($event: string | NgbDateStruct, dp: NgbDatepicker) {
    const date = new DateTimeEndModel($event);

    if (!date) {
      this.dateString = this.dateString;
      return;
    }

    if (!this.datetime) {
      this.datetime = date;
    }

      this.datetime.year = date.year;
      this.datetime.month = date.month;
      this.datetime.day = date.day;

      const adjustedDate = new Date(this.datetime.toString());
      if (this.datetime.timeZoneOffset !== adjustedDate.getTimezoneOffset()) {
        this.datetime.timeZoneOffset = adjustedDate.getTimezoneOffset();
      }

      this.setDateStringModel();
  }

  onTimeChange(event: NgbTimeStruct) {
    this.datetime.hour = event.hour;
    this.datetime.minute = event.minute;
    this.datetime.second = event.second;

    this.setDateStringModel();
  }

  setDateStringModel() {
    this.dateString = this.datetime.toString();

    if (!this.firstTimeAssign) {
      this.onChange(this.dateString);
    } else {
      if (this.dateString !== null) {
        this.firstTimeAssign = false;
      }
    }
  }

  inputBlur($event) {
    this.onTouched();
  }
}