import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class IntegracaoService {

  private apiUrl = environment.API_URL;

  constructor(private http: HttpClient) {

  }

  integracaoGovBr(code: any, state: any) {
    return this.http.get(`${this.apiUrl}/integracao-monitoramento/${code}/${state}`);
  }

  getToken() {
    return this.http.get(
      `${this.apiUrl}/integracao-monitoramento/validarToken`
    )
  }
}