import { Component, Input, OnInit } from '@angular/core';

declare var GMaps: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  @Input() id: string;
  @Input() lat: number;
  @Input() lng: number;
  @Input() scrollwheel: boolean = false;
  @Input() pesquisa: boolean = false;
  @Input() height: number = 400;

  constructor() {}

  ngOnInit() {
    const mapa = new GMaps({
        el: '.set-map',
        lat: this.lat,
        lng: this.lng,
        scrollwheel: this.scrollwheel,
        mapTypeControlOptions: {
            mapTypeIds: ['hybrid', 'roadmap', 'satellite', 'terrain', 'osm']
        }
    });
  }

  marcador(map: any, lat: number, lng: number, title: string, content: any) {
    map.addMarker({
      lat: lat,
      lng: lng,
      title: title,
      infoWindow: {
          content: content
      }
    });
  }

  overlay(map: any, lat: number, lng: number, content: any) {
    map.drawOverlay({
      lat: lat,
      lng: lng,
      content: content
    });
  }

}
