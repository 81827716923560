import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { isSet } from 'src/app/shared/is/is-set';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() cor: string = 'siget-primary';
  @Input() nome: string;
  @Input() valor: string;
  @Input() url: string;
  @Input() margin: string = 'mr-0';
  @Input() tipo: string = 'button';
  @Input() toggle: string;
  @Input() target: string;
  @Output() clicked = new EventEmitter<boolean>();

  @Input() desativado: boolean = false;
  @Input() outline: boolean;
  @Input() square: boolean;
  @Input() rounded: boolean;
  @Input() glow: boolean;
  @Input() shadow: boolean;
  @Input() large: boolean;
  @Input() small: boolean = true;
  @Input() icon: boolean;

  classes: String[];

  constructor() {}

  ngOnInit() {
    this.adicionaClasses();
  }

  adicionaClasses(): void {
    this.classes = [`btn-${this.cor}`];

    if(isSet(this.outline)) this.classes = [`btn-outline-${this.cor}`];
    if(isSet(this.glow)) this.classes.push(`btn-glow-${this.cor}`);
    if(isSet(this.square)) this.classes.push(`btn-square`);
    if(isSet(this.rounded)) this.classes.push(`rounded`);
    if(isSet(this.shadow)) this.classes.push(`shadow-1`);
    if(isSet(this.large)) this.classes.push(`btn-lg`);
    if(isSet(this.icon)) this.classes.push(`btn-icon`);
    if(isSet(this.margin)) this.classes.push(this.margin);
    if(isSet(this.small)) this.classes.push(`btn-sm`);
  }

  eventoClick() {
    this.clicked.emit(true);
  }
}
