<div class="form-group"
     [ngClass]="{ 'login-group': noLabel }"
     [formGroup]="formGroup">
  <div class="d-flex flex-column">
    <div class="d-flex flex-row">
      <label class="mr-2" [for]="nome" *ngIf="label && !noLabel" [id]="nome">
      {{ label }}
      <small class="required" *ngIf="isRequired">
        *
      </small>
      </label>
      <textarea class="form-control form-control-sm flex-fill"
              (blur)="eventoBlur()"
              (click)="eventoClick()"
              (change)="eventoChange()"
              (focus)="eventoFocus()"
              (keyup)="eventoKeyup()"
              [formControlName]="nome"
              [id]="nome"
              [name]="nome"
              [rows]="linhas"
              [placeholder]="placeholder"
              [required]="isRequired"
              [ngClass]="{ 
                'is-invalid': !formGroup.controls[nome].valid &&
                formGroup.controls[nome].touched
              }">
      {{ valor }}
      </textarea>
    </div>
    <div class="d-flex flex-column justify-content-end">
      <small class="form-text text-muted" *ngIf="observacoes">
        {{ observacoes }}
      </small>
      <div class="invalid-feedback"
          *ngIf="!formGroup.controls[nome].valid && formGroup.controls[nome].touched">
        <span class="error" [hidden]="!formGroup.controls[nome].errors.notValid">
            {{ textoInvalido }}
        </span>
        <span class="error" [hidden]="!formGroup.controls[nome].errors.required">
            {{ textoObrigatorio }}
        </span>
      </div>
    </div>
  </div>
</div>
