import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import { Observable } from "rxjs";

import { UserService } from "src/app/shared/user/user.service";

@Injectable({
  providedIn: 'root'
})

export class IntegracaoGuard implements CanActivate {


  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.userService.isLogged()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}