<div class="btn-group">
    <button
        [ngClass]="{
            'btn-primary': cor === 'primary',
            'btn-secondary': cor === 'secondary',
            'btn-success': cor === 'success',
            'btn-warning': cor === 'warning',
            'btn-danger': cor === 'danger',
            'btn-info': cor === 'info',

            'btn-outline-primary': cor === 'primary' && outline,
            'btn-outline-secondary': cor === 'secondary' && outline,
            'btn-outline-success': cor === 'success' && outline,
            'btn-outline-warning': cor === 'warning' && outline,
            'btn-outline-danger': cor === 'danger' && outline,
            'btn-outline-info': cor === 'info' && outline
        }"
        aria-haspopup="true"
        aria-expanded="true"
        class="btn  dropdown-toggle"
        data-toggle="dropdown"
        type="button"
    >
        {{ texto }}
    </button>
    <div class="dropdown-menu" x-placement="bottom-start">
        <a
            (click)="item.acao()"
            class="dropdown-item"
            href="javascript:"
            *ngFor="let item of itens"
            >{{ item.nome }}</a
        >
    </div>
</div>
