import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-nav-tabs',
    templateUrl: './nav-tabs.component.html',
    styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent {
    @Output() updateTab = new EventEmitter();

    @Input() navItens: Array<any>;

    onTab(tab: string) {
        this.updateTab.emit(tab);
    }
}
