import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../core/auth/auth.service';

import { PlatformDetectorService } from '../../core/platform-detector/platform-detector.service';
import { ValidatorCPF } from 'src/app/shared/validators/custom-validators';

import { PostsService } from '../../services/posts.service';

@Component({
  templateUrl: './loading.component.html'
})
export class LoadingComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;

  posts: any;
  loadingMessage: any;
  errorMessage: any;

  @ViewChild('userNameInput') userNameInput: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private platformDetectorService: PlatformDetectorService,
    private postsService: PostsService
  ) {}

  ngOnInit(): void {
    document.querySelector('.pcoded-footer').classList.add('navbar-login-page');

    this.getPosts();
  }

  ngOnDestroy() {
    document.querySelector('.pcoded-footer').classList.remove('navbar-login-page');
  }

  getPosts() {
    this.loadingMessage = true;

    this.postsService.getAllPosts()
      .subscribe((data: any) => {
        this.posts = data;
        this.loadingMessage = false;
      },
        (err: any) => {
          this.errorMessage = "There are no posts pulled from the server!";
          this.loadingMessage = false;
        })
  }
}
