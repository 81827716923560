import { Component, Input, EventEmitter, Output } from '@angular/core';

import { Button } from 'src/app/shared/button/button';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent {
    @Input()
    titulo: String;

    @Input()
    footer: String;

    @Input()
    note: String;

    @Input()
    noteIcon: String;

    @Input()
    buttons: Button[] = [];

    @Output() onClick = new EventEmitter();

    eventoClick(): void {
        this.onClick.emit(true);
    }

    constructor() {}
}
