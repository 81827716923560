<div class="auth-wrapper">
  <div class="auth-content">
    <div class="card">
      <div class="card-body text-center">
        <div class="mb-4">
          <img src="./assets/images/Logo_Prodata.svg" />
        </div>

        <h3 class="mb-4">Certificado Digital</h3>

        <div class="my-5" *ngIf="loading">
          <p class="mt-5 mb-1 text-muted">Carregando certificados.</p>
          <p class="mb-5 text-muted">Aguarde...</p>
        </div>
        <div class="my-5" *ngIf="!loading && certificados.length">
          <p class="mb-4">
            Por favor, escolha com qual certificado deseja efetuar o acesso:
          </p>

          <div class="d-flex flex-column">
              <div class="my-1 certificado rounded d-flex flex-column align-items-left text-left" *ngFor="let certificado of certificados" (click)="login(certificado)">
                    <p class="font-weight-bold text-truncate">
                      {{certificado.empresa}}
                    </p>
                    <p class="font-weight-lighter text-truncate">
                      {{certificado.nome}}
                    </p>
                    <p class="font-weight-lighter text-truncate">
                      {{certificado.email}}
                    </p>
              </div>
          </div>
        </div>

        <app-modal titulo="Instalação Necessária" id="instalacao">
          <div class="modal-body">
            <p>Não encontramos o componente necessário para a leitura do(s) certificado(s) instalado no seu dispositivo.</p>
            <p>Deseja instalar o componente agora?</p>
          </div>
          <div class="modal-footer">
            <app-button (click)="fecharModalInstalacao()" cor="btn-light">
              Cancelar
            </app-button>
            <app-button (click)="redirecionarParaInstalacao()">
              Sim, quero instalar
            </app-button>
          </div>
        </app-modal>

        <p class="my-4 text-muted"> Quer acessar usando CPF e senha? <a routerLink="/login">Clique aqui</a></p>

        <p class="my-4 text-muted">Esqueceu seus dados de acesso? <a routerLink="/recuperacao-senha">Podemos ajudar</a></p>
      </div>
    </div>
  </div>
</div>