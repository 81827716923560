import { Component, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';

import { Chart } from 'src/app/shared/chart/chart';

declare var Chart: any;

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnDestroy {

  /** Template para o elemento canvas */
  @ViewChild('elemento') elemento: ElementRef;

  /** Contexto do canvas */
  private contexto: CanvasRenderingContext2D;

  @Input() altura: number = 50;
  @Input() largura: number = 100;
  @Input() nome: string;
  @Input() id: string;
  @Input() rotulo: string[];
  @Input() tipo: string = 'bar';
  @Input() titulo: string;

  ctx: any;
  dados: any;
  grafico: any;
  inicializado: boolean;
  responsive: boolean;

  constructor() {}

  exemploMockup() {

    this.setContexto();

    const tema1 = this.geraTema(this.ctx, '#1de9b6', '#1dc4e9', 0, 300, 0, 0);
    const tema2 = this.geraTema(this.ctx, '#899FD4', '#A389D4', 0, 300, 0, 0);

    const dadosChart = this.setDados(tema1, tema2);
    this.getDados(dadosChart);
    this.geraGrafico(this.ctx, this.tipo, this.dados, 20);
  }

  setContexto() {

    this.inicializado = true;
    this.ctx = this.getContexto();
    return this.ctx;

  }

  setDados(tema1: any, tema2: any): Chart[] {
    const dadosChart: Chart[] = [
      {
        label: 'Semana 1',
        data: [25, 45, 74, 85, 90],
        borderColor: tema1,
        backgroundColor: tema1,
        hoverborderColor: tema1,
        hoverBackgroundColor: tema1
      }, {
        label: 'Semana 2',
        data: [30, 52, 65, 65, 70],
        borderColor: tema2,
        backgroundColor: tema2,
        hoverborderColor: tema2,
        hoverBackgroundColor: tema2,
      }, {
        label: 'Semana 3',
        data: [70, 80, 95, 100, 101],
        borderColor: tema2,
        backgroundColor: tema2,
        hoverborderColor: tema2,
        hoverBackgroundColor: tema2,
      }
    ];

    return dadosChart;
  }

  getDados(dadosChart: Chart[]) {
    this.dados = {
      labels: this.rotulo,
      datasets: dadosChart
    };
  }

  geraTema(ctx: any, cor1: any, cor2: any, x0: number, y0: number, x1: number, y1: number) {
    const tema = ctx.createLinearGradient(x0, y0, x1, y1);
    tema.addColorStop(0, cor1);
    tema.addColorStop(1, cor2);
    return tema;
  }

  geraGrafico(ctx: any, tipo: any, dados: any, valueSpacing: number) {
    this.grafico = new Chart(ctx, {
      type: tipo,
      data: dados,
      options: {
          barValueSpacing: valueSpacing
      }
    });
  }

  atualiza() {
    if(this.grafico) {
        this.grafico.update();
    }
  }

  reinicia() {
    if(this.grafico) {
        this.grafico.destroy();
        this.exemploMockup();
    }
  }

  ngOnDestroy() {
    if(this.grafico) {
        this.grafico.destroy();
        this.inicializado = false;
        this.grafico = null;
    }
  }

  getContexto() {
    return (this.elemento.nativeElement as HTMLCanvasElement).getContext('2d');
  }

  public chartClick(e: any): void {
    console.log(e);
  }

  public chartHover(e: any): void {
    console.log(e);
  }
}