declare var jQuery: any;

import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";

@Directive({
  selector: "table[datatable]"
})
export class DataTableDirective implements OnDestroy, OnInit {
  @Input()
  dtOptions:any = {};

  @Input()
  dtTrigger: Subject<any>;

  dtInstance: Promise<any>;

  private dt: any;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.dtTrigger) {
      this.dtTrigger.subscribe(() => {
        this.displayTable();
      });
      this.dtTrigger.next();
    } else {
      this.displayTable();
    }
  }

  ngOnDestroy(): void {
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.dt) {
      this.dt.destroy(true);
    }
  }

  private displayTable(): void {
    
    if (this.dtInstance) {
      this.dt.destroy();
    }
    
    this.generateInstance();
  }

  private generateInstance(): void {
    this.dtInstance = new Promise((resolve, reject) => {
      Promise.resolve(this.dtOptions).then(dtOptions => {
        setTimeout(() => {
          this.dt = jQuery(this.el.nativeElement)
            .DataTable(dtOptions)
            .page.len(dtOptions.pageLength)
            .draw();
          resolve(this.dt);
        });
      });
    });
  }
}