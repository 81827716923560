import { Component, OnInit, Input, ElementRef } from '@angular/core';

declare var jQuery: any;

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
    @Input() id: string;
    @Input() valor: any;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        jQuery(`#${this.id}`)
            .datepicker({
                format: 'dd/mm/yyyy'
                // autoclose: true
            })
            .on('changeDate', e => {
                this.valor = this.el.nativeElement.firstChild.value;
            });
    }
}
