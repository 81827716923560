import { Injectable } from '@angular/core';
import Swal, { SweetAlertType } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  api(dados: any): void {
    const tipo = this.trataTipo(dados.codigo);
    this.mostrar(dados.solucao, tipo, dados.mensagem);
  }

  mostrar(
    texto: string,
    tipo: SweetAlertType,
    titulo: string = null,
    confirmButtonText: string = 'Fechar'
  ): void {
    Swal.fire({
      title: titulo,
      html: texto,
      type: tipo,
      confirmButtonText: confirmButtonText
    });
  }

  private trataTipo(codigo: string): SweetAlertType {
    let tipo = null;
    switch (codigo.charAt(0)) {
      case 'A':
        tipo = 'warning';
        break;
      case 'E':
        tipo = 'error';
        break;
      case 'S':
        tipo = 'success';
        break;
      default:
        tipo = 'info';
        break;
    }

    return tipo;
  }
}
