import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataTableDirective } from './datatable/datatable.directive';
import { FormValidateDirective } from './form-validate/form-validate.directive';

const itens = [
  DataTableDirective,
  FormValidateDirective
];

@NgModule({
  imports: [ CommonModule ],
  declarations: itens,
  exports: itens
})
export class DirectivesModule {}