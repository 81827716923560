<!-- 
    MORE OPTIONS

    [theme]="'bootstrap'"
    [height]="400"
    [pageable]="true"
    [pageSize]="25"
    [showAggregates]="true"
    [showSubAggregates]="true"
    [aggregatesHeight]="70"
    [sortable]="true"
>
-->
<div style="margin-bottom: 45px;">
    <div class="dt-buttons btn-group">
        <button
            (click)="csvExportClick()"
            class="btn btn-secondary buttons-csv buttons-html5 btn-dt btn-siget-primary"
        >
            <span>CSV</span>
        </button>
        <button
            (click)="excelExportClick()"
            class="btn btn-secondary buttons-excel buttons-html5 btn-dt btn-siget-primary"
        >
            <span>Excel</span>
        </button>
        <button
            (click)="pdfExportClick()"
            class="btn btn-secondary buttons-pdf buttons-html5 btn-dt btn-siget-primary"
        >
            <span>PDF</span>
        </button>
    </div>
</div>
<div id="print">
    <jqxTreeGrid
        [width]="'100%'"
        [source]="dataAdapter"
        [altRows]="true"
        [columnsResize]="true"
        [columns]="tableData.columns"
        [columnGroups]="tableData.columnGroups"
        [ready]="ready"
        #TreeGrid
    >
    </jqxTreeGrid>
</div>

<!-- <div style="margin-top: 20px;">
    <button class="btn btn-primary" (click)="export()">
        test export
    </button>
</div> -->
