import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
    @Input() cor: string = 'primary';
    @Input() id: string;
    @Input() texto: string;
    @Input() close: boolean;

    @Output() clickDismiss = new EventEmitter();

    closeAlert() {
        this.clickDismiss.emit();
    }
}
