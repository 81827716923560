import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localePt from '@angular/common/locales/pt';

import { ExportAsModule } from 'ngx-export-as';
import { TreeviewModule } from 'ngx-treeview';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

import { CoreModule } from './core/core.module';
import { ComponentsModule } from './components/components.module';
import { InMemoryDataService } from './in-memory-data.service';

import { LoaderInterceptorService } from './services/loader-interceptor/loader-interceptor.service';
import { ContribuinteService } from 'src/app/services/contribuinte/contribuinte.service';
import { AgenteFiscalComponent } from './pages/agente-fiscal/agente-fiscal.component';
import { CatriCoordenadorComponent } from './pages/catri-coordenador/catri-coordenador.component';
import { CatriCepacComponent } from './pages/catri-cepac/catri-cepac.component';
import { CorexCoordenadorComponent } from './pages/corex-coordenador/corex-coordenador.component';
import { MandadoETermosComponent } from './pages/mandado-e-termos/mandado-e-termos.component';
import { HistoricoDeJustificativasComponent } from './pages/historico-de-justificativas/historico-de-justificativas.component';
import { SolicitacaoComponent } from './pages/solicitacao-de-medidas-de-gestao/solicitacao-de-medidas-de-gestao.component';
import { jqxTreeGridModule } from 'jqwidgets-ng/jqxtreegrid';
import { NgxMaskModule } from 'ngx-mask';
import { GestaoInstituicaoService } from './services/gestao-instituicao/gestao-instituicao.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EnderecoService } from './services/endereco/endereco.service';
import { DropdownModule } from 'primeng/dropdown';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DataTablesModule } from 'angular-datatables';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';


registerLocaleData(localePt, 'pt-br');

@NgModule({
    declarations: [AppComponent, AgenteFiscalComponent,CatriCoordenadorComponent, CatriCepacComponent, CorexCoordenadorComponent, MandadoETermosComponent, HistoricoDeJustificativasComponent, SolicitacaoComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ExportAsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        jqxTreeGridModule,
        // HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
        //      dataEncapsulation: false
        //  }),
        AppRoutingModule,
        NgxMaskModule.forRoot(),
        CoreModule,
        ComponentsModule,
        CKEditorModule,
        PdfViewerModule,
        DpDatePickerModule,
        TreeviewModule.forRoot(),
        DropdownModule,
        DataTablesModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule
    ],
    providers: [
        ContribuinteService,
        GestaoInstituicaoService,
        EnderecoService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptorService,
            multi: true
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LOCALE_ID, useValue: 'pt-br' },
        { provide:  DEFAULT_CURRENCY_CODE, useValue: 'BRL'},
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
