import { Injectable } from '@angular/core';

declare var Offline: any;

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  constructor() {}

  verificaConexao() {
    Offline.check();
  }
}
