import {
    Component,
    Input,
    ViewChild,
    ViewEncapsulation,
    OnInit
} from '@angular/core';

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

import { jqxTreeGridComponent } from 'jqwidgets-ng/jqxtreegrid';

declare var $: any;

@Component({
    selector: 'app-row-group-table',
    templateUrl: './row-group-table.component.html',
    styleUrls: ['./row-group-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RowGroupTableComponent implements OnInit {
    @ViewChild('TreeGrid') treeGrid: jqxTreeGridComponent;

    @Input() tableData: any;

    // exportAsConfig: ExportAsConfig = {
    //     type: 'pdf',
    //     elementId: 'print'
    // };

    source: any;

    dataAdapter: any;

    constructor(private exportAsService: ExportAsService) {}

    ngOnInit() {
        this.source = {
            dataType: 'json',
            dataFields: this.tableData.dataFields,
            hierarchy: {
                root: 'children'
            },
            localData: this.tableData.data,
            id: 'id'
        };

        this.dataAdapter = new jqx.dataAdapter(this.source);
    }

    // export() {
    //     this.exportAsService.save(this.exportAsConfig, 'My File Name');
    // }

    getWidth(): any {
        if (document.body.offsetWidth < 850) {
            return '100%';
        }

        return 850;
    }

    ready(): void {
        // this.treeGrid.expandRow(1);
        // this.treeGrid.expandRow(2);
    }

    excelExportClick(): void {
        this.treeGrid.exportData('xls');
    }

    csvExportClick(): void {
        this.treeGrid.exportData('csv');
    }

    pdfExportClick(): void {
        this.treeGrid.exportData('pdf');
    }
}
