export interface MenuChild {
    id: number;
    name: string;
    url: string;
    icon?: string;
    children?: MenuChild[];
}

export interface Menu {
    id: number;
    name: string;
    url: string;
    children?: MenuChild[];
}

export class ActiveMenu {
    id: number;
    name: string;
    url: string;
    children?: MenuChild[];
}
