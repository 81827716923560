import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Menu } from '../menu/menu';
import { MENUS } from './menu-opcoes.mock';

@Injectable({
  providedIn: 'root'
})
export class MenuOpcoesService {
  menuStatus: Observable<boolean>;
  private open = new Subject<any>();

  constructor() {
    this.menuStatus = this.open.asObservable();
  }

  all(): Observable<Menu[]> {
    return Observable.create(function(observer: any) {
      observer.next(MENUS);
    });
  }

  setMenuStatus(data: boolean): void {
    this.open.next(data);
  }
}
