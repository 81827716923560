<div class="table-responsive">
    <div class="loader" [ngClass]="{ active: loading }">
        <p>Carregando...</p>
    </div>
    <table
        class="display table nowrap"
        datatable
        [dtTrigger]="reload"
        [dtOptions]="options"
        id="table"
        [ngClass]="classes"
        style="width: 100%;"
    >
        <thead>
            <tr>
                <th
                    *ngFor="let field of fields"
                    [attr.rowspan]="field.row ? 2 : null"
                    [attr.colspan]="field.childs ? field.childs.length : null"
                >
                    {{ field.label }}
                </th>
            </tr>
            <tr>
                <ng-container *ngFor="let field of fields">
                    <th *ngFor="let child of field.childs">
                        {{ child.label }}
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let dataValue of data">
                <ng-container *ngFor="let field of fields">
                    <td *ngIf="!field.childs">
                        <a
                            *ngIf="
                                dataValue[field.label].isLink &&
                                !dataValue[field.label].isDownload
                            "
                            (click)="
                                openOptions.emit({
                                    id: dataValue[field.label].label,
                                    data: dataValue
                                })
                            "
                            >{{ dataValue[field.field].label || '-' }}
                        </a>

                        <a
                            *ngIf="
                                dataValue[field.label].isIcon &&
                                dataValue[field.label].isDownload
                            "
                            href="{{ dataValue[field.label].downloadUrl }}"
                            download="{{ dataValue[field.label].label }}"
                            target="_blank"
                            class="td-icon"
                        >
                            <i
                                class="{{
                                    'feather icon-' +
                                        dataValue[field.label].icon
                                }}"
                            ></i>
                        </a>

                        <i
                            *ngIf="
                                dataValue[field.label].isIcon &&
                                !dataValue[field.label].isDownload
                            "
                            class="td-icon {{
                                'feather icon-' + dataValue[field.label].icon
                            }}"
                        ></i>

                        <span
                            *ngIf="
                                !dataValue[field.label].isLink &&
                                !dataValue[field.label].isDownload &&
                                !dataValue[field.label].isIcon
                            "
                        >
                            {{ dataValue[field.label].label || '-' }}
                        </span>
                    </td>
                    <ng-container *ngIf="field.childs">
                        <td
                            *ngFor="let fieldChild of field.childs"
                            [class.text-danger]="
                                dataValue[field.label + fieldChild.label]
                                    .negative
                            "
                        >
                            <a
                                *ngIf="
                                    dataValue[field.label + fieldChild.label]
                                        .isLink &&
                                    !dataValue[field.label + fieldChild.label]
                                        .isDownload
                                "
                                (click)="
                                    openOptions.emit({
                                        id:
                                            dataValue[
                                                field.label + fieldChild.label
                                            ].label,
                                        data: dataValue
                                    })
                                "
                            >
                                <span
                                    [class.text-danger]="fieldChild.negative"
                                    >{{
                                        dataValue[field.field].label || '-'
                                    }}</span
                                >
                            </a>

                            <a
                                *ngIf="
                                    dataValue[field.label + fieldChild.label]
                                        .isIcon &&
                                    dataValue[field.label + fieldChild.label]
                                        .isDownload
                                "
                                href="{{
                                    dataValue[field.label + fieldChild.label]
                                        .downloadUrl
                                }}"
                                download="{{
                                    dataValue[field.label + fieldChild.label]
                                        .label
                                }}"
                                target="_blank"
                                class="td-icon"
                            >
                                <i
                                    class="{{
                                        'feather icon-' +
                                            dataValue[
                                                field.label + fieldChild.label
                                            ].icon
                                    }}"
                                ></i>
                            </a>

                            <i
                                *ngIf="
                                    dataValue[field.label + fieldChild.label]
                                        .isIcon &&
                                    !dataValue[field.label + fieldChild.label]
                                        .isDownload
                                "
                                class="td-icon {{
                                    'feather icon-' +
                                        dataValue[
                                            field.label + fieldChild.label
                                        ].icon
                                }}"
                            ></i>

                            <span
                                *ngIf="
                                    !dataValue[field.label + fieldChild.label]
                                        .isLink &&
                                    !dataValue[field.label + fieldChild.label]
                                        .isDownload &&
                                    !dataValue[field.label + fieldChild.label]
                                        .isIcon
                                "
                            >
                                <span [class.text-danger]="fieldChild.negative">
                                    {{
                                        dataValue[
                                            field.label + fieldChild.label
                                        ].label || '-'
                                    }}
                                </span>
                            </span>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </tbody>
        <tfoot>
            <tr *ngIf="calcColumn === 'all'">
                <th>
                    Total:
                </th>
                <ng-container
                    *ngFor="let field of (fields | slice: 1); let i = index"
                >
                    <ng-container *ngIf="field.childs">
                        <th *ngFor="let child of field.childs">
                            <span
                                *ngIf="
                                    allTotal[field.label + child.label] !==
                                        NaN &&
                                    allTotal[field.label + child.label].mask ===
                                        'number'
                                "
                                [class.text-danger]="
                                    allTotal[field.label + child.label].label <
                                    0
                                "
                            >
                                {{
                                    allTotal[field.label + child.label] !== NaN
                                        ? allTotal[field.label + child.label]
                                              .label
                                        : ''
                                }}</span
                            >
                            <span
                                *ngIf="
                                    allTotal[field.label + child.label] !==
                                        NaN &&
                                    allTotal[field.label + child.label].mask ===
                                        'money'
                                "
                                [class.text-danger]="
                                    allTotal[field.label + child.label].label <
                                    0
                                "
                            >
                                {{
                                    allTotal[field.label + child.label] !== NaN
                                        ? (allTotal[field.label + child.label]
                                              .label | currency: 'BRL')
                                        : ''
                                }}
                            </span>
                        </th>
                    </ng-container>
                    <ng-container *ngIf="!field.childs">
                        <th>
                            <span
                                *ngIf="
                                    allTotal[field.label] &&
                                    allTotal[field.label].mask === 'number'
                                "
                                [class.text-danger]="
                                    allTotal[field.label].label < 0
                                "
                            >
                                {{
                                    allTotal[field.label]
                                        ? allTotal[field.label].label
                                        : ''
                                }}</span
                            >
                            <span
                                *ngIf="
                                    allTotal[field.label] &&
                                    allTotal[field.label].mask === 'money'
                                "
                                [class.text-danger]="
                                    allTotal[field.label].label < 0
                                "
                            >
                                {{
                                    allTotal[field.label]
                                        ? (allTotal[field.label].label
                                          | currency: 'BRL')
                                        : ''
                                }}
                            </span>
                        </th>
                    </ng-container>
                </ng-container>
            </tr>
            <tr *ngIf="calcColumn && calcColumn !== 'all'">
                <th [attr.colspan]="calcColumn.position">
                    Total:
                </th>
                <th *ngIf="calcColumn.mascara === 'real'">
                    {{ totalCalc | currency: 'BRL' }}
                </th>
                <th *ngIf="calcColumn.mascara === 'number'">{{ totalCalc }}</th>
            </tr>
        </tfoot>
    </table>
</div>
