import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CEP, TipoComplemento, TipoEndereco } from "src/app/shared/interface/endereco";

import { environment } from "src/environments/environment";
@Injectable()
export class EnderecoService {

  private apiUrl = environment.API_URL;

  constructor(private http: HttpClient) {

  }

  getCep(cep) {
    return this.http.get<CEP>(
    `${this.apiUrl}/contribuinte/produtorRural/obterCep/${cep}`
    );
  }

  getTipoEndereco(): Observable<Array<TipoEndereco>> {
    return this.http.get<Array<TipoEndereco>>(
      `${this.apiUrl}/contribuinte/endereco/tipoEndereco/listaRegistros`
    );
  }

  getTipoComplemento(): Observable<Array<TipoComplemento>> {
    return this.http.get<Array<TipoComplemento>>(
      `${this.apiUrl}/contribuinte/endereco/tipoComplemento/listaRegistros`
    );
  }

  getPaises(): Observable<any> {
    return this.http.get<Array<TipoComplemento>>(
      `${this.apiUrl}/contribuinte/produtorRural/listarPaises`
    );
  }
}

