import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from '../../loader/loader';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();

    constructor() {}

    exibir() {
        this.loaderSubject.next(<LoaderState>{ show: true });
    }

    esconder() {
        this.loaderSubject.next(<LoaderState>{ show: false });
    }
}
