<div class="form-group"  [formGroup]="formGroup">
  <label 
    *ngIf="label"
    [for]="id"
    [id]="id">
    {{label}}
    <small
      *ngIf="isRequired"
      class="required">
      *
    </small>
  </label>
  <select
    (blur)="eventoBlur()"
    class="form-control"
    (click)="eventoClick()"
    (focus)="eventoFocus()"
    [formControlName]="name"
    [id]="id"
    multiple="multiple"
    [name]="name"
    [ngClass]="{ 'is-invalid': !formGroup.controls[name].valid && formGroup.controls[name].touched }"
    [required]="required">
    <option value="" selected>
      {{textoSelecao}}
    </option>
    <option
      *ngFor="let option of options"
      [ngValue]="option">
      {{option.texto}}
    </option>
  </select>
  <div
    *ngIf="formGroup.controls[name].invalid"
    class="invalid-feedback">
    <span
      class="error"
      [hidden]="!formGroup.controls[name].errors.required">
      {{textoObrigatorio}}
    </span>
  </div>
</div>