import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

import { DirectivesModule } from 'src/app/directives/directives.module';

import { LogoComponent } from './logo/logo.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { IconeComponent } from './icone/icone.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumb.component';
import { PaginacaoComponent } from './paginacao/paginacao.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { NavTabsComponent } from './nav-tabs/nav-tabs.component';
import { TabContentComponent } from './tab-content/tab-content.component';
import { ContainerComponent } from './container/container.component';
import { CrtEchartComponent } from './e-charts-lines/e-charts-lines.component';
import { CrtEchartPieComponent } from './e-charts-pie/e-charts-pie.component';
import { CrtEchartPieBigComponent } from './e-charts-pie-big/e-charts-pie-big.component';
import { ConteudoComponent } from './conteudo/conteudo.component';
import { ContentComponent } from './content/content.component';
import { ModalComponent } from './modal/modal.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ComplexHeaderTableComponent } from './complex-header-table/complex-header-table.component';
import { CardComponent } from './card/card.component';
import { TabelaComponent } from './tabela/tabela.component';
import { RowGroupTableComponent } from './row-group-table/row-group-table.component';
import { AlertComponent } from './alert/alert.component';
import { ButtonComponent } from './button/button.component';
import { ButtonCheckboxComponent } from './button-checkbox/button-checkbox.component';
import { ButtonRadioComponent } from './button-radio/button-radio.component';
import { InputComponent } from './input/input.component';
import { SelectComponent } from './select/select.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioComponent } from './radio/radio.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DatepickerIntervalComponent } from './datepicker-interval/datepicker-interval.component';
import { MenuOpcoesComponent } from './menu-opcoes/menu-opcoes.component';
import { ChartComponent } from './chart/chart.component';
import { CrtEchartModalComponent } from './e-charts-modal/e-charts-modal.component';
import { CrtEchartMoneyComponent } from './e-charts-money/e-charts-money.component';
import { CrtEchartInitComponent } from './e-charts-init/e-charts-init.component';
import { InputFileComponent } from './input-file/input-file';
import { FileDragndropComponent } from './file-dragndrop/file-dragndrop.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { RangerSliderComponent } from './ranger-slider/ranger-slider.component';
import { WizardComponent } from './wizard/wizard.component';
import { FooterComponent } from './footer/footer.component';
import { MapComponent } from './map/map.component';
import { HeaderLoginComponent } from './header-login/header-login.component';
import { LoaderComponent } from './loader/loader.component';
import { TextareaComponent } from './textarea/textarea.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { jqxTreeGridModule } from 'jqwidgets-ng/jqxtreegrid';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import { DatetimeEndPickerComponent } from './datetime-end-picker/datetime-end-picker.component';
import { LoadingMessageComponent } from './loading-message/loading-message.component';
import { FiltroComponent } from './filtro/filtro.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';

const items = [
    LogoComponent,
    HeaderComponent,
    HeaderLoginComponent,
    MenuComponent,
    IconeComponent,
    PageLoaderComponent,
    BreadcrumbsComponent,
    PaginacaoComponent,
    ProgressBarComponent,
    NavTabsComponent,
    TabContentComponent,
    ContainerComponent,
    ContentComponent,
    ConteudoComponent,
    CrtEchartPieComponent,
    CrtEchartPieBigComponent,
    ModalComponent,
    DropdownComponent,
    CardComponent,
    TabelaComponent,
    AlertComponent,
    ButtonComponent,
    ButtonCheckboxComponent,
    ButtonRadioComponent,
    InputComponent,
    SelectComponent,
    CheckboxComponent,
    RadioComponent,
    DatepickerComponent,
    DatepickerIntervalComponent,
    MenuOpcoesComponent,
    ChartComponent,
    CrtEchartComponent,
    InputFileComponent,
    FileDragndropComponent,
    MultiSelectComponent,
    RangerSliderComponent,
    WizardComponent,
    FooterComponent,
    MapComponent,
    LoaderComponent,
    CrtEchartModalComponent,
    ComplexHeaderTableComponent,
    TextareaComponent,
    RowGroupTableComponent,
    CrtEchartMoneyComponent,
    CrtEchartInitComponent,
    DatetimePickerComponent,
    DatetimeEndPickerComponent,
    LoadingMessageComponent,
    FiltroComponent
];

@NgModule({
    declarations: items,
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        jqxTreeGridModule,
        NgxMaskModule.forRoot(),
        NgxEchartsModule,
        FontAwesomeModule,
        NgbModule,
        DpDatePickerModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatSelectModule,
        MatOptionModule,
        MatFormFieldModule
    ],
    exports: items,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(faCalendar, faClock);
    }
}
