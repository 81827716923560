<div class="custom-control custom-radio">
    <input
        (blur)="eventoBlur()"
        (change)="eventoChange()"
        [checked]="selecionado"
        class="custom-control-input"
        [disabled]="desativado"
        [name]="name"
        [id]="id"
        (focus)="eventoFocus()"
        type="radio"
    />
    <label class="custom-control-label" [for]="id">{{ texto }}</label>
</div>
