import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoadingComponent } from './loading.component';
import { ComponentsModule } from 'src/app/components/components.module';

const items = [
  LoadingComponent
];

@NgModule({
  declarations: items,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule
  ],
  exports: items,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoadingModule {}
