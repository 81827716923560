<div class="auth-wrapper">
  <div class="auth-content">
    <div class="card">
      <form [formGroup]="recuperacaoForm" (submit)="recuperaSenha()">

        <div class="card-body text-center">
          <div class="mb-4">
            <i class="feather icon-unlock auth-icon"></i>
          </div>
          <h3 class="mb-4">Recuperação de Senha</h3>

          <app-input [formGroup]="recuperacaoForm" [noLabel]="true" nome="cpf" cpf></app-input>

          <app-input [formGroup]="recuperacaoForm" [noLabel]="true" nome="email" email></app-input>

          <app-button cor="secondary" class="mr-2" nome="voltar" routerLink="/login" tipo="button">
            Voltar
          </app-button>

          <app-button [desativado]="recuperacaoForm.invalid" nome="enviar" tipo="submit">
            Enviar
          </app-button>
        </div>
      </form>
    </div>
  </div>
</div>
