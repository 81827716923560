import { Component, Input } from '@angular/core';

import { DropdownItem } from '../../shared/dropdown/dropdown-item';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {

  @Input() texto: string;
  @Input() cor: string = 'primary';
  @Input() outline: boolean = false;
  @Input() itens: DropdownItem[];
}
