import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-paginacao',
  templateUrl: './paginacao.component.html',
  styleUrls: ['./paginacao.component.scss']
})
export class PaginacaoComponent implements OnChanges {
  
  @Output() primeiraPagina = new EventEmitter();
  @Output() voltarPagina = new EventEmitter();
  @Output() proximaPagina = new EventEmitter();
  @Output() ultimaPagina = new EventEmitter();
  @Input() totalElementos: number;
  @Input() tamanhoPagina: number;
  @Input() filtro: boolean = false;
  pagina: number = 1;
  quantidadeDePaginas: number;

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.totalElementos?.currentValue != changes.totalElementos?.previousValue
      || changes.tamanhoPagina?.currentValue != changes.tamanhoPagina?.previousValue
      || changes.filtro?.currentValue != changes.filtro?.previousValue
    ) {
      this.pagina = 1;
      this.tamanhoPagina = this.tamanhoPagina;
      this.totalElementos = this.totalElementos;
      this.quantidadeDePaginas = this.totalElementos / this.tamanhoPagina;
      this.quantidadeDePaginas = Math.trunc(this.quantidadeDePaginas);
      if (this.totalElementos % this.tamanhoPagina > 0 || this.totalElementos == 0) {
        this.quantidadeDePaginas += 1;
      }
    }
  }

  voltarTodasPaginas() {
    this.pagina = 1;
    this.primeiraPagina.emit(this.pagina);
    this. scroll();
  }

  voltarUmaPagina() {
    this.pagina -= 1;
    this.voltarPagina.emit(this.pagina);
    this. scroll();
  }

  passarUmaPagina() {
    this.pagina += 1;
    this.proximaPagina.emit(this.pagina);
    this. scroll();
  }

  passarTodasPaginas() {
    this.pagina = this.quantidadeDePaginas;
    this.ultimaPagina.emit(this.pagina);
    this. scroll();
  }

  scroll(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  resetarPagina() {
    this.pagina = 1;
  }
  
}
