/**
 * @Directive({ selector: 'componente' }) class MeuComponente {
 *   @Input() @BooleanField() campo: boolean;
 * }
 *
 * Permite você usar:
 *   <component campo>
 * ou:
 *   <component campo="true">
 */
function booleanFieldFactory() {
    return function booleanFieldMetadata(target: any, key: string): void {
      const defaultValue = target[key];
      const localKey = `__md_private_symbol_${key}`;
      target[localKey] = defaultValue;
  
      Object.defineProperty(target, key, {
        get() { return (<any>this)[localKey]; },
        set(value: boolean) {
          (<any>this)[localKey] = value != null && `${value}` !== 'false';
        }
      });
    };
  }
  
  export { booleanFieldFactory as BooleanField };