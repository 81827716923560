import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxMaskModule } from 'ngx-mask';

import { DashboardComponent } from './dashboard.component';
import { ComponentsModule } from 'src/app/components/components.module';

const items = [
  DashboardComponent
];

@NgModule({
  declarations: items,
  imports: [CommonModule, RouterModule, ComponentsModule, CKEditorModule, NgxMaskModule.forRoot()],
  exports: items,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule {}
