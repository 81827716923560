import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';

@Directive({
  selector: 'form[validate]'
})
export class FormValidateDirective {

  @Input() formGroup: NgForm;

  constructor(private el: ElementRef) {}
  
  @HostListener('submit', ['$event'])
  onSubmit(e) {
    e.preventDefault();

    if (this.isInvalid()) {
      for (let control in this.formGroup.controls) {
        this.formGroup.controls[control].markAsTouched();
      }

      this.setFocusFirstError();
    }
  }

  private isInvalid(): boolean {
    return (this.formGroup != undefined && this.formGroup.invalid);
  }

  private setFocusFirstError(): void {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
    if (invalidControl) {
      const input = invalidControl.querySelector('input');
      const select = invalidControl.querySelector('select');
      const textarea = invalidControl.querySelector('textarea');

      if (input) {
        input.focus();
      } else if (select) {
        select.focus();
      } else if (textarea) {
        textarea.focus();
      }
    }
  }
}
