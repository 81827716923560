export class BreadcrumbItem {

  url: string;
  texto: string;
  icone: string;

  constructor(url, texto, icone = "") {
    this.url = url;
    this.texto = texto;
    this.icone = icone;
  }
}
