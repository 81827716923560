    <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label class="btn" [ngClass]="classes">
            <input 
            (blur)="eventoBlur()"
            (change)="eventoChange()"
            [checked]="selecionado" 
            (click)="eventoClick()"
            [disabled]="desativado"
            (focus)="eventoFocus()"
            [id]="id" 
            [name]="name" 
            type="checkbox"  />
            {{texto}}
        </label>
    </div>