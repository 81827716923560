<div class="form-group" [formGroup]="formGroup">
    <div class="d-flex flex-column">
        <div class="d-flex flex-row">
            <label class="mr-2" *ngIf="label && !noLabel" [for]="nome" [id]="nome">
                {{ label }}
                <small *ngIf="isRequired" class="required">
                    *
                </small>
            </label>
            <select
                class="form-control form-control-sm"
                [id]="nome"
                [name]="nome"
                [ngClass]="{
                    'is-invalid':
                        !formGroup.controls[nome].valid &&
                        formGroup.controls[nome].touched
                }"
                (click)="eventoClick()"
                (focus)="eventoFocus()"
                (blur)="eventoBlur()"
                [required]="required"
                [formControlName]="nome"
            >
                <option value="" selected *ngIf="textoSelecao">
                    {{ textoSelecao }}
                </option>
                <option *ngFor="let option of options" [ngValue]="option.value">
                    {{ option.texto }}
                </option>
            </select>
        </div>
        <div class="d-flex flex-column justify-content-end">
            <div class="invalid-feedback error"
                    *ngIf="!formGroup.controls[nome].valid && formGroup.controls[nome].touched"
                >
                <span [hidden]="!formGroup.controls[nome].errors.required">
                    {{ textoObrigatorio }}
                </span>
            </div>
        </div>
    </div>
</div>
