import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { BooleanField } from 'src/app/shared/boolean-field/boolean-field.decorator';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent {
  @Output() onClick = new EventEmitter<boolean>();
  @Output() onFocus = new EventEmitter<boolean>();
  @Output() onBlur = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<string>();
  @Output() onKeyup = new EventEmitter<string>();

  @Input() linhas: number;
  @Input() nome: string;
  @Input() placeholder: string = '';
  @Input() observacoes: string;
  @Input() label: string;
  @Input() @BooleanField() noLabel: boolean;
  @Input() textoInvalido: string = '';
  @Input() textoObrigatorio: string = 'Campo obrigatório';
  @Input() formGroup: FormGroup;

  valorAntigo: any;

  /** VALOR **/
  _valor: string;
  get valor(): string {
      return this._valor;
  }

  @Input('valor')
  set valor(value: string) {
    this.formGroup.get(this.nome).setValue(value);
    this._valor = value;
  }

  /** DISABLED **/
  @Input('disabled')
  set disabled(value: boolean) {
      const control = this.formGroup.get(this.nome);
      if (control) {
          value !== false ? control.disable() : control.enable();
      }
  }

  /** READONLY **/
  @Input('readonly')
  set readonly(value: boolean) {
      const control = this.formGroup.get(this.nome);
      if (control) {
          value !== false ? control.disable() : control.enable();
      }
  }

  constructor() { }

  eventoClick(): void {
    this.onClick.emit(true);
  }

  eventoFocus(): void {
    this.onFocus.emit(true);
  }

  eventoBlur(): void {
    this.onBlur.emit(true);
  }

  eventoChange(): void {
    this.onChange.emit(this.formGroup.controls[this.nome].value);
  }

  eventoKeyup(): void {
    const valor = this.formGroup.controls[this.nome].value
    if (this.valorAntigo !== valor) {
        this.onKeyup.emit(valor);
        this.valorAntigo = valor;
    }
  }

  get isRequired() {
      if (this.formGroup.get(this.nome).validator) {
          const validator = this.formGroup
              .get(this.nome)
              .validator({} as AbstractControl);
          if (validator && validator.required) {
              return true;
          }
      }

      return false;
  }

}
