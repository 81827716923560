import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AcessoComponent } from './acesso.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAdjust, faBars, faUser } from '@fortawesome/free-solid-svg-icons';

const items = [
  AcessoComponent
];

@NgModule({
  declarations: items,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ComponentsModule,
    FontAwesomeModule,
    ReactiveFormsModule
  ],
  exports: items,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AcessoModule { 
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faAdjust,
      faUser,
      faBars
    )
  }
}
