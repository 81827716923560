import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ValidatorCPF, ValidatorEmail } from 'src/app/shared/validators/custom-validators';
import { SweetAlertService } from 'src/app/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-recuperacao-senha',
  templateUrl: './recuperacao-senha.component.html',
  styleUrls: ['./recuperacao-senha.component.scss']
})
export class RecuperacaoSenhaComponent implements OnInit {

  recuperacaoForm: FormGroup;
  @ViewChild('userNameInput') userNameInput: ElementRef<HTMLInputElement>;

  constructor(
    private sweetAlertService: SweetAlertService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.recuperacaoForm = this.formBuilder.group({
      cpf: [
        '',
        [
          Validators.required,
          ValidatorCPF
        ]
      ],
      email: [
        '', 
        [
          Validators.required,
          ValidatorEmail
        ]
      ]
    });
  }

  recuperaSenha(): void {
    const cpf = this.recuperacaoForm.get('cpf').value;
    const email = this.recuperacaoForm.get('email').value;

    if (cpf === '22233366638' && email === "cestech@cestech.com.br") {
      this.sweetAlertService.api({
          codigo: 'S001',
          mensagem: 'Dados validados com sucesso.',
          solucao: 'Cheque seu e-mail para prosseguir com a recuperação dos dados de acesso.'
      });

      setTimeout(() => {
          this.router.navigate(['/login']);
      }, 2000);
    } else {
      this.sweetAlertService.api({
          codigo: 'E007',
          mensagem: 'Dados informados não localizados em nossos registros.',
          solucao: 'Favor verificar os dados informados e tente novamente.'
      });

      this.recuperacaoForm.reset();
    }
  }
}
