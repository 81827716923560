<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"
        *ngFor="let item of itens; let last = last">
      <a [routerLink]="!last ? item.url : null">
        <app-icone
          *ngIf="item.icone"
          [nome]="item.icone">
        </app-icone>
        {{item.texto}}
      </a>
    </li>
  </ol>
</nav>
