import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../shared/menu/menu.service';

@Component({
    selector: 'app-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {
    openMenu = true;

    constructor(private menuService: MenuService) {}

    ngOnInit() {
        this.menuService.openMenu.subscribe(data => {
            this.openMenu = data;
        });
    }
}
