export const CERTIFICADOS: any[] = [
  {
    keyUsage: {
      crlSign: false,
      dataEncipherment: false,
      decipherOnly: false,
      digitalSignature: true,
      encipherOnly: false,
      keyAgreement: false,
      keyCertSign: false,
      keyEncipherment: true,
      nonRepudiation: true
    },
    subjectName:
      "C E S TECHNOLOGIES ELABORACAO DE PROGRAMAS DE COM:07756254000183",
    issuerName: "AC BR RFB G3",
    email: "fabio.silva@cesgrupo.com.br",
    validityEnd: "2019-07-20T02:59:00.000Z",
    validityStart: "2016-07-20T03:00:00.000Z",
    thumbprint: "L4Wj9cD0K8U8X5MASA0bYgppDrJ+NZwvytUlGan1bB4=",
    canRemove: false,
    pkiBrazil: {
      rgEmissorUF: "SP",
      certificateType: "A3",
      isPessoaFisica: false,
      rgNumero: "25885951",
      cnpj: "07756254000183",
      responsavel: "LUIS FABIO CAVALCANTI DA SILVA",
      cpf: "28726579839",
      rgEmissor: "SSP",
      nis: null,
      isPessoaJuridica: true,
      oabNumero: null,
      oabUF: null,
      companyName: "C E S TECHNOLOGIES ELABORACAO DE PROGRAMAS DE COM",
      dateOfBirth: "1980-02-11T03:00:00.000Z",
      isAplicacao: false
    },
    pkiItaly: {
      codiceFiscale: null
    }
  },
  {
    keyUsage: {
      crlSign: false,
      dataEncipherment: false,
      decipherOnly: false,
      digitalSignature: true,
      encipherOnly: false,
      keyAgreement: false,
      keyCertSign: false,
      keyEncipherment: true,
      nonRepudiation: true
    },
    subjectName:
      "C E S TECHNOLOGIES ELABORACAO DE PROGRAMAS DE COM:07756254000183",
    issuerName: "AC BR RFB G3",
    email: "thais.martins@cestech.com.br",
    validityEnd: "2019-07-20T02:59:00.000Z",
    validityStart: "2016-07-20T03:00:00.000Z",
    thumbprint: "L4Wj9cD0K8U8X5MASA0bYgppDrJ+NZwvytUlGan1bB4=",
    canRemove: false,
    pkiBrazil: {
      rgEmissorUF: "SP",
      certificateType: "A3",
      isPessoaFisica: false,
      rgNumero: "25885951",
      cnpj: "07756254000183",
      responsavel: "THAIS MARTINS",
      cpf: "28726579839",
      rgEmissor: "SSP",
      nis: null,
      isPessoaJuridica: true,
      oabNumero: null,
      oabUF: null,
      companyName: "C E S TECHNOLOGIES ELABORACAO DE PROGRAMAS DE COM",
      dateOfBirth: "1980-02-11T03:00:00.000Z",
      isAplicacao: false
    },
    pkiItaly: {
      codiceFiscale: null
    }
  }
];
