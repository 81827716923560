import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoaderService } from '../../services/loader/loader.service';
import { LoaderState } from '../../loader/loader';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
    show = false;
    private subscription: Subscription;
    constructor(private loaderService: LoaderService) {}

    ngOnInit() {
        this.subscription = this.loaderService.loaderState.subscribe(
            (state: LoaderState) => {
                console.log('state', state);
                this.show = state.show;
            }
        );
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
