<div class="navbar pcoded-header navbar-expand-lg navbar-light row align-items-center" style="width: 100%">
  <div class="footer-section col">
    <ul class="navbar-nav mr-auto">
      <li>
          <img src="./assets/images/fotor_2023-5-16_23_6_38.png" class="header-img" />
      </li>
    </ul>
  </div>
  <div class="footer-section col" style="text-align: right;">
      <img src="./assets/images/logo_sfera.png" alt="Logo SFERA" title="Logo SFERA" style="height: 100px;">
  </div>
</div>
