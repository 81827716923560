import { Component, OnInit } from '@angular/core';

import { HistoricoDeJustificativasService } from './historico-de-justificativas.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Router } from '@angular/router';

import { ComunicadosService } from '../../services/comunicados/comunicados.service';
import { SweetAlertService } from '../../services/sweet-alert/sweet-alert.service';

import { PageLoaderService } from 'src/app/components/page-loader/page-loader.service';
import { ModalService } from 'src/app/shared/modal/modal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-historico-de-justificativas',
  templateUrl: './historico-de-justificativas.component.html',
  styleUrls: ['./historico-de-justificativas.component.scss']
})
export class HistoricoDeJustificativasComponent implements OnInit {
  searchFilter: FormGroup;
  comunicado: any;
  agenda: any = [];
  matriculas: any = [];
  monitoramento: any = [];
  monitoramentoEmpresa: any = [];
  contributorId = '';
  carregado: boolean;

  formCiencia: FormGroup;
  dateRange: object;

  constructor(
    private historicoDeJustificativasrService: HistoricoDeJustificativasService,
    private loaderService: PageLoaderService,
    private sweetAlert: SweetAlertService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {

    //this.mensagemErro();

    this.formCiencia = this.formBuilder.group({
      data: ['', [Validators.required]],
      servidor: ['', [Validators.required]],
      inscricaoEstadual: ['', [Validators.required]],
      pam: ['', [Validators.required]],
      projeto: ['todos'],
      origem: ['todos']
    });

    this.agenda = this.historicoDeJustificativasrService.getAgenda();
    this.matriculas = this.historicoDeJustificativasrService.getMatriculas();
    this.monitoramento = this.historicoDeJustificativasrService.getMonitoramento();
    this.monitoramentoEmpresa = this.historicoDeJustificativasrService.getMonitoramentoEmpresa();
  }

  mensagemErro() {
    Swal.fire({
      title: 'Desculpe',
      text: 'Recursos indisponíveis para o seu perfil de acesso.',
      type: 'error',
      confirmButtonText: 'OK'
    }).then(() => {
      this.router.navigate(['/painel-contribuinte']);
    });
  }

  help() {
    const html = `<div class="help-content">
                  <div class="row">
                    <div class="col-12">
                      <h6>EM DESENVOLVIMENTO</h6>
                      <p>Situação de criação de um novo projeto onde se está na fase de
                       inclusão/seleção/exclusão a refinamento de filtros de empresa;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>AGUARDO APROVAÇÃO</h6>
                      <p>Projeto enviado à CATRI/Coordenador para que seja aprovado;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>NÃO APROVADO</h6>
                      <p>Projeto analisado pela CATRI/Coordenador e não aprovado. Gera um aviso
                      para a caixa de entrada do responsável pelo projeto;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>A HOMOLOGAR</h6>
                      <p>Projeto aguardando fase de vetar empresas e  homologar o envio aos superiores/orientadores;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>ATIVO</h6>
                      <p>Projeto homologado pela CATRI/Coordenador e dentro do período de validade;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>CANCELADO</h6>
                      <p>Situação na qual o projeto não será enviado para homologação;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>FINALIZADO</h6>
                      <p>Projeto encerrado, conforme validade do mesmo.</p>
                    </div>
                  </div>



                </div>`;

    this.sweetAlert.mostrar(html, 'info', 'Situação do Projeto', 'OK');
  }

  setRange(range: object) {
    this.dateRange = range;
  }

  gerarComunicado() {
    this.modalService.abre('comunicadoModal');
  }

  gerarMonitoramento(): void {
    let limit = 10;
    if (this.carregado) {
      limit = 50;
    } else {
      limit = 10;
    }

    this.monitoramento.dados = [];
    for (let i = 2; i < limit; i++) {
      this.monitoramento.dados.push({
        id: 1,

        Origem: {
          label: `INTERBELLE DE PRODUTOS DE BELEZA`,
          isLink: true
        },
        'Justificado por': {
          isStatus: true,
          status: 'aguardando-aprovacao'
        },
        Quando: {
          label: `INTERBELLE DE PRODUTOS DE BELEZA`,
          isLink: true
        },
        'Inscrição Estadual': { label: `07.022.495/0001-07`, isLink: false },
        'Razão Social': { label: `07.022.495/0001-07`, isLink: false },
        'N° da PAM': { label: `07.022.495/0001-07`, isLink: false },
        Projeto: { label: `07.022.495/0001-07`, isLink: false },
        'N° Mandado/Termo': {
          label: `07.022.495/0001-07`,
          isLink: false
        },
        'Teor da Justificativa': {
          label: `07.022.495/0001-07`,
          isLink: false
        }
      });
    }
    this.carregado = !this.carregado;
  }

  validacao(): void {
    console.log(this.formCiencia);
    this.markFormGroupTouched(this.formCiencia);
    if (this.formCiencia.valid) {
      this.loaderService.exibir();
      setTimeout(() => {
        this.loaderService.esconder();
        window.scrollTo(0, window.innerHeight - 200);
      }, 2000);
    }
  }

  // pesquisarMatriculaNome() {
  //     this.fechaModal('comunicadoModal');
  //     this.modalService.abre('matriculaNomeModal');
  // }

  fechaModal(id: string) {
    this.modalService.fecha(id);
  }
  fechaModalComunicado() {
    this.modalService.fecha('comunicadoModal');
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  pesquisarMatriculaNome() {
    this.fechaModal('comunicadoModal');
    this.modalService.abre('matriculaNomeModal');
  }

  setContributor(data: any): void {
    console.log('teste icon:', data);
    this.contributorId = data.id.replace(/[.-\s]/g, '');
    if (this.contributorId === 'icon') {
      this.router.navigate([`orientador-supervisor/visualizar`]);
    }
  }
}
