<app-card titulo="Página Não Encontrada">
  <div class="d-flex flex-row justify-content-center align-items-center mt-5 mb-5">
      <app-icone nome="exclamation-triangle" tamanho="10x" class="icone"></app-icone>
  </div>
  <div class="d-flex flex-row justify-content-center align-items-center mt-5">
    <h2 class="title">404</h2>
  </div>
  <div class="d-flex flex-row justify-content-center align-items-center mt-2">
    <p>Por favor, verifique a url digitada.</p>
  </div>
  <div class="d-flex flex-row justify-content-center align-items-center mt-5 mb-5">
    <app-button nome="Home" url="/dashboard">
      <app-icone nome="arrow-left" posicao="left"></app-icone>
      Voltar para o Dashboard
    </app-button>
  </div>
</app-card>