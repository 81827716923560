import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

import { Menu, ActiveMenu } from './menu';
import { MENUS } from './menu.mock';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    openMenu: Observable<any>;
    private menuStatus = new Subject<any>();

    private menuObject = new BehaviorSubject<any>(new ActiveMenu());
    activeMenu = this.menuObject.asObservable();

    private breadcrumbMenu = new BehaviorSubject<any>(new Array());
    breadcrumbMenuStatus = this.breadcrumbMenu.asObservable();

    private breadcrumb = [];

    constructor() {
        this.openMenu = this.menuStatus.asObservable();
    }

    all(): Observable<Menu[]> {
        return Observable.create(function(observer: any) {
            observer.next(MENUS);
        });
    }

    setMenu(menu: any): void {
        console.log('menu');
        if (menu.level === 1) {
            this.breadcrumb = [];
        }
        if (!this.breadcrumb.includes(menu) && menu.children.length > 0) {
            this.breadcrumb.push(menu);
        }
        this.menuObject.next(menu);
        this.breadcrumbMenu.next(this.breadcrumb);
    }

    backMenu(): void {
        const newMenu = this.breadcrumb[
            this.breadcrumb.indexOf(this.activeMenu.source['_value']) - 1
        ];
        this.breadcrumb.splice(
            this.breadcrumb.indexOf(this.activeMenu.source['_value']),
            1
        );
        this.setMenu(newMenu);
    }

    setStatus(status: boolean): void {
        this.menuStatus.next(status);
    }
}
