import {
    Component,
    Input,
    ElementRef,
    OnInit,
    ChangeDetectorRef,
    DoCheck,
    Output,
    EventEmitter
} from '@angular/core';

import { DataTableOptions } from 'src/app/shared/tabela/tabela-options';
import { TableField } from 'src/app/shared/complex-header-table/table-field';
import { isSet } from 'src/app/shared/is/is-set';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-complex-header-table',
    templateUrl: './complex-header-table.component.html',
    styleUrls: ['./complex-header-table.component.scss']
})
export class ComplexHeaderTableComponent implements OnInit, DoCheck {
    @Input()
    striped: boolean;

    @Input()
    hover: boolean;

    @Input()
    calcColumn: any;

    @Input('footer')
    footer: boolean;

    @Input()
    fields: TableField[] = [];

    @Input()
    data: any[] = [];

    @Output() openOptions = new EventEmitter();

    reload: Subject<any> = new Subject();
    classes: String[] = [];
    allTotal = [];
    loading: boolean = false;
    options: any;
    dadosPrev: any;
    totalCalc = 0;

    constructor(private el: ElementRef, private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (isSet(this.footer)) this.footer = true;
        this.options = DataTableOptions;
        this.dadosPrev = this.data;
        this.gerarClasses();

        if (this.calcColumn && typeof this.calcColumn === 'object') {
            this.data.forEach(dado => {
                const value = dado[this.calcColumn.campo].label
                    .split(',')[0]
                    .replace('.', '');
                this.totalCalc += Number(value);
            });

            this.fields.forEach(campo => {
                if (campo.label === this.calcColumn.nome) {
                    this.calcColumn['position'] = this.fields.indexOf(campo);
                }
            });
        }

        if (this.calcColumn === 'all') {
            this.fields.forEach(campo => {
                this.data.forEach(dado => {
                    if (campo.childs) {
                        campo.childs.forEach(child => {
                            const value = dado[campo.label + child.label].label
                                .split(',')[0]
                                .replace('.', '');
                            if (Number(value)) {
                                if (this.allTotal[campo.label + child.label]) {
                                    this.allTotal[campo.label + child.label][
                                        'label'
                                    ] += Number(value);
                                } else {
                                    this.allTotal[campo.label + child.label] = {
                                        label: Number(value),
                                        mask:
                                            dado[campo.label + child.label].mask
                                    };
                                }
                            }
                        });
                    } else {
                        const value = dado[campo.label].label
                            .split(',')[0]
                            .replace('.', '');
                        if (Number(value) !== NaN) {
                            if (this.allTotal[campo.label]) {
                                this.allTotal[campo.label]['label'] += Number(
                                    value
                                );
                            } else {
                                this.allTotal[campo.label] = {
                                    label: Number(value),
                                    mask: dado[campo.label].mask
                                };
                            }
                        }
                    }
                });
            });
        }
    }

    ngDoCheck(): void {
        if (JSON.stringify(this.dadosPrev) !== JSON.stringify(this.data)) {
            this.dadosPrev = this.data;
            this.reload.next();
        }

        this.cd.markForCheck();
    }

    private gerarClasses(): void {
        if (isSet(this.striped)) this.classes.push('table-striped');
        if (isSet(this.hover)) this.classes.push('table-hover');
    }
}
