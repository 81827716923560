<div class="container-paginacao">
  <button 
    class="botao"
    (click)="voltarTodasPaginas()"
    [disabled]="pagina == 1"
    [ngClass]="{ disabled: pagina == 1, 'botao-proibido': pagina == 1}"
    >Primeira
  </button>
  <button 
    class="botao"
    (click)="voltarUmaPagina()"
    [disabled]="pagina == 1"
    [ngClass]="{ disabled: pagina == 1, 'botao-proibido': pagina == 1}"
    >Anterior
  </button>
  <p 
    class="mt-2 paginas"
    >{{ pagina }} de {{ quantidadeDePaginas }}
  </p>
  <button 
    class="botao"
    (click)="passarUmaPagina()"
    [disabled]="pagina == quantidadeDePaginas"
    [ngClass]="{ disabled: pagina == quantidadeDePaginas, 'botao-proibido': pagina == quantidadeDePaginas }"
    >Próxima
  </button>
  <button
    class="botao"
    (click)="passarTodasPaginas()"
    [disabled]="pagina == quantidadeDePaginas"
    [ngClass]="{ disabled: pagina == quantidadeDePaginas, 'botao-proibido': pagina == quantidadeDePaginas }"
    >Última
  </button>
</div>