import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-e-charts-pie-big',
    templateUrl: './e-charts-pie-big.component.html',
    styleUrls: ['./e-charts-pie-big.component.scss']
})
export class CrtEchartPieBigComponent implements OnInit {
    public lineBasicOption: any;
    public lineAreaOption: any;
    public barBasicColumnOption: any;
    public barBasicBarOption: any;
    public pieBasicColumnOption: any;
    public pieDoughnutOption: any;
    public pieTimelineOption: any;
    public gaugeOption: any;

    constructor() {}

    ngOnInit() {
        this.pieBasicColumnOption = {
            tooltip: {
                trigger: 'item',
                formatter: '{d}%'
            },
            legend: {
                orient: 'vertical',
                x: 'left',
                data: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ]
            },
            color: ['#f4c22b', '#A389D4', '#3ebfea', '#04a9f5', '#1de9b6'],
            toolbox: {
                show: false,
                feature: {
                    mark: {
                        show: true
                    },
                    dataView: {
                        show: true,
                        readOnly: false
                    },
                    magicType: {
                        show: true,
                        type: ['pie', 'funnel'],
                        option: {
                            funnel: {
                                x: '25%',
                                width: '50%',
                                funnelAlign: 'left',
                                max: 1548
                            }
                        }
                    },
                    restore: {
                        show: true
                    },
                    saveAsImage: {
                        show: true
                    }
                }
            },
            calculable: true,
            series: [
                {
                    show: false,
                    name: 'Webpage',
                    type: 'pie',
                    radius: '70%',
                    center: ['50%', '60%'],
                    data: [
                        {
                            value: 335,
                            name: '33,04%'
                        },
                        {
                            value: 310,
                            name: '70,57%'
                        }
                    ]
                }
            ]
        };
    }
}
