<nav class="pcoded-navbar" [ngClass]="{ 'navbar-collapsed': !openMenu }">
    <div class="navbar-wrapper">
        <div class="navbar-brand header-logo">
            <a class="b-brand" routerLink="/dashboard">
                <app-logo></app-logo>
            </a>
            <a
                class="menu-icon"
                [ngClass]="{ on: !openMenu }"
                (click)="toggleMenu()"
            >
                <span></span>
            </a>
        </div>
        <div class="navbar-content scroll-div">
            <ul class="nav pcoded-inner-navbar">
                <li class="nav-item pcoded-menu-caption">
                    <label>Navegação</label>
                </li>
                <li
                    class="nav-item"
                    *ngFor="let menu of menus"
                    (click)="setActive(menu)"
                    [ngClass]="{
                        'active pcoded-trigger': menu.url === currentUrl
                    }"
                >
                    <a
                        class="nav-link"
                        [routerLink]="menu.url != 'sair' ? [menu.url] : []"
                        (click)= "menu.url == 'sair' ? validarSaida() : null"
                        href="javascript:"
                    >
                        <span class="pcoded-micon">
                            <app-icone [nome]="menu.icon"></app-icone>
                        </span>
                        <span class="pcoded-mtext">
                            {{ menu.name }}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
