import { Injectable } from '@angular/core';
import { TabelaCampo } from 'src/app/shared/tabela/tabela-campo';

@Injectable({
    providedIn: 'root'
})
export class CorexCoordenadorService {
    campos: TabelaCampo[];
    constructor() {}
    getAgenda() {
        return {
            campos: [
                {
                    nome: 'Data Inicio',
                    campo: 'Data Inicio'
                },
                {
                    nome: 'Data Fim',
                    campo: 'Data Fim'
                },
                {
                    nome: 'Agenda',
                    campo: 'Agenda'
                },
                {
                    nome: 'Identificador',
                    campo: 'Identificador'
                },
                {
                    nome: 'Atividade',
                    campo: 'Atividade'
                },
                {
                    nome: 'Solicitante',
                    campo: 'Solicitante'
                },
                {
                    nome: 'Tipo Medida de Gestão',
                    campo: 'Tipo Medida de Gestão'
                },
                {
                    nome: 'Situação',
                    campo: 'Situação'
                },
                {
                    nome: 'Aprovar',
                    campo: 'Aprovar'
                },
                {
                    nome: 'Rejeitar',
                    campo: 'Rejeitar'
                },
                {
                    nome: 'Ordem',
                    campo: 'Ordem'
                }
            ],
            dados: []
        };
    }
    getMatriculas() {
        return {
            campos: [
                {
                    nome: 'Nº da Matrícula',
                    campo: 'matricula'
                },
                {
                    nome: 'Nome',
                    campo: 'nome'
                }
            ],
            dados: []
        };
    }

    getMonitoramento() {
        return {
            campos: [
                {
                    nome: 'Situação do Projeto',
                    campo: 'Situação do Projeto'
                },
                {
                    nome: 'Tipo de Projeto',
                    campo: 'Tipo de Projeto'
                },
                {
                    nome: 'Nome do Projeto',
                    campo: 'Nome do Projeto'
                },
                {
                    nome: 'Validade do Projeto',
                    campo: 'Validade do Projeto'
                },
                {
                    nome: 'Total Empresas',
                    campo: 'Total Empresas'
                },
                {
                    nome: 'Empresas Destribuidas',
                    campo: 'Empresas Destribuidas'
                },
                {
                    nome: 'Quantidade Empresas em Aberto',
                    campo: 'Quantidade Empresas em Aberto'
                },
                {
                    nome: 'Quantidade Empresas a Monitorar',
                    campo: 'Quantidade Empresas a Monitorar'
                },
                {
                    nome: 'Quantidade Empresas em Execução',
                    campo: 'Quantidade Empresas em Execução'
                },
                {
                    nome: 'Quantidade Empresas Designadas',
                    campo: 'Quantidade Empresas Designadas'
                },
                {
                    nome: 'Quantidade Empresas Devolvidas e Pendentes',
                    campo: 'Quantidade Empresas Devolvidas e Pendentes'
                },
                {
                    nome: 'Quantidade Empresas Anuladas/Vetadas',
                    campo: 'Quantidade Empresas Anuladas/Vetadas'
                },
                {
                    nome: 'Quantidade Empresas Vencidas',
                    campo: 'Quantidade Empresas Vencidas'
                },
                {
                    nome: 'Critérios',
                    campo: 'Critérios'
                }
            ]
        };
    }

    getMonitoramentoEmpresa() {
        return {
            campos: [
                {
                    nome: 'Situação Anterior da empresa',
                    campo: 'Situação Anterior da empresa'
                },
                {
                    nome: 'Situação Atual da Empresa no Monitoramento',
                    campo: 'Tipo de Projeto'
                },
                {
                    nome: 'Inscrição Estadual',
                    campo: 'Inscrição Estadual'
                },
                {
                    nome: 'Não Contribuinte',
                    campo: 'Não Contribuinte'
                },
                {
                    nome: 'Razão Social',
                    campo: 'Razão Social'
                },
                {
                    nome: 'Nº PAM',
                    campo: 'Nº PAM'
                },
                {
                    nome: 'Agente Fiscal',
                    campo: 'Agente Fiscal'
                },
                {
                    nome: 'Supervisor',
                    campo: 'Supervisor'
                },
                {
                    nome: 'Órgão Executor do Monitoramento Fiscal',
                    campo: 'Órgão Executor do Monitoramento Fiscal'
                }
            ],
            dados: []
        };
    }
}
