<div class="input-group mr-2">
  <input
    [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'"
    class="form-control"
    (blur)="inputBlur($event)"
    [ngModel]="dateString | date:inputDatetimeFormat"
    (change)="onInputChange($event)"
    [disabled]="disabled"
  >

  <div class="input-group-append">
    <button
      class="btn btn-outline-secondary pt-1 pb-1"
      [ngbPopover]="calendarContent"
      [disabled]="disabled"
      type="button"
    >
      <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
    </button>
  </div>
</div>

<ng-template #calendarContent>
	<div>
		<div *ngIf="!showTimePickerToggle">
			<ngb-datepicker 
        id="dp" #dp name="datepicker" 
        [ngModel]="datetime" 
        [startDate]="datetime"
				(ngModelChange)="onDateChange($event, dp)" 
        ></ngb-datepicker>
			<button
        class="btn btn-block btn-outline-secondary"
        [disabled]="!datetime?.day || disabledTime"
        [ngbPopover]="timePickerContent"
        type="button"
        (click)="toggleDateTimeState($event)"
      >
        <fa-icon [icon]="['fas', 'clock']"></fa-icon>
      </button>
		</div>
		<div *ngIf="showTimePickerToggle">
			<button
        class="btn btn-block btn-outline-secondary"
        [ngbPopover]="calendarContent"
        type="button"
        (click)="toggleDateTimeState($event)"
      >
        <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
      </button>
			<div class="mt-auto">
				<ngb-timepicker #tp name="timepicker" [ngModel]="datetime" (ngModelChange)="onTimeChange($event)"
					[seconds]="seconds" [hourStep]="hourStep" [minuteStep]="minuteStep" [secondStep]="secondStep">
				</ngb-timepicker>
			</div>
		</div>
	</div>
</ng-template>