<div class="container">
  <div class="container-imagens">
    <img class="logo-sfera" src="../../../assets/images/logo_sfera.png" alt="Logo SFERA">
    <img src="../../../assets/images/Gov.br_logo.svg" alt="Logo Gov.br">
  </div>
  <div class="container-conteudo">
    <a href="{{ urlIntegracao }}">
      <button class="login-btn" type="button"><fa-icon [icon]="['fas', 'user']" aria-hidden="true"></fa-icon><span class="padding-img">Entrar com o gov.br</span>
      </button>
    </a>
    <p class="problemas">Em caso de problemas com o acesso gov.br,<a href="https://atendimento.servicos.gov.br/pt" class="link"> clique aqui</a></p>
    <p>As informações contidas nos sistemas informatizados da Administração Pública estão protegidas por sigilo. Todo acesso é monitorado e controlado.</p>

    <section class="links__container">
      <a href="https://appdistribution.firebase.dev/i/e6f4b0ce78ef7255" target="_blank">
        <img class="img-link" src="./assets/images/google-play-badge.png" alt="Link Google Play"
          title="Aplicativo DEC">
      </a>
      <div class="ml-4">
        <a href="https://apps.apple.com/app/sfera-an%C3%A1lise-fiscal/id6504629288" target="_blank">
          <img src="./assets/images/app-store-fundo-preto.svg" alt="Link Apple Store" title="Aplicativo DEC">
        </a>
      </div>
    </section>
  
  </div>
</div>
