<div class="row">
    <div class="col-xl-6 col-md-12">
        <div
            echarts
            [options]="lineBasicOption"
            [autoResize]="true"
            [style.width]="'750px'"
            [style.height]="'500px'"
        ></div>
    </div>
</div>
