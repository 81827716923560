import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-message',
  templateUrl: './loading-message.component.html',
  styleUrls: ['./loading-message.component.scss']
})
export class LoadingMessageComponent implements OnInit {
  @Input() mensagem = '';
  @Input() dados;
  
  constructor() { }

  ngOnInit(): void {
  }

}
