<div
    class="pcoded-main-container"
    [ngClass]="{ 'navbar-collapsed': !openMenu }"
>
    <div class="pcoded-wrapper">
        <div class="pcoded-content">
            <div class="pcoded-inner-content">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
