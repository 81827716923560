
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GestaoInstituicaoService {

  private apiUrl = environment.API_URL;

  constructor(private http: HttpClient) { }


  salvarDadosInstitucionais(data: any) {
    return this.http.put(`${this.apiUrl}/dominios/instituicao`, data);
  }

  getInstituicoes() {
    return this.http.get(`${this.apiUrl}/dominios/instituicao`);
  }

  getInstituicaoAtiva() {
    return this.http.get(`${this.apiUrl}/dominios/instituicao/ativos`);
  }

  getInstituicao(id: number) {
    return this.http.get(`${this.apiUrl}/dominios/instituicao/id/${id}`);
  }

  getJuntaComercial(id) {
    return this.http.get(`${this.apiUrl}/dominios/instituicao/habilitacaoJuntaComercial/${id}`);
  }

}
