import { Component, Input, OnInit } from '@angular/core';

import { WizardItem } from './wizard-item';

declare var jQuery: any;

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  @Input() id: string;
  @Input() itens: WizardItem[];

  constructor() {}

  ngOnInit() {

    setTimeout(function() {
      jQuery('#' + this.id).smartWizard({
          theme: 'default',
          transitionEffect: 'fade',
          autoAdjustHeight: false,
          useURLhash: false,
          showStepURLhash: false
      });
      }, 700);

      jQuery("#theme_selector").on("change", function() {
        jQuery('#' + this.id).smartWizard("theme", jQuery(this).val());
          return true;
      });

      jQuery('#' + this.id + ' .sw-toolbar').appendTo(jQuery('#' + this.id + ' .sw-container'));
      jQuery("#theme_selector").change();
    }
}
