<div class="alert {{ 'alert-' + cor }}" [id]="id" role="alert">
    {{ texto }}
    <button
        aria-label="Close"
        class="close"
        (click)="closeAlert()"
        type="button"
        *ngIf="close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
