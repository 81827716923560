import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormGroup, AbstractControl } from "@angular/forms";

import { SelectItem } from "src/app/shared/select/select-option";

@Component({
  selector: "app-multi-select",
  templateUrl: "./multi-select.component.html",
  styleUrls: ["./multi-select.component.scss"]
})
export class MultiSelectComponent {
  @Output() onClick = new EventEmitter<boolean>();
  @Output() onFocus = new EventEmitter<boolean>();
  @Output() onBlur = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<boolean>();

  @Input() id: string;
  @Input() label: string;
  @Input() textoSelecao: string = "Selecione";
  @Input() options: SelectItem[];
  @Input() textoObrigatorio: string = "Campo obrigatório";
  @Input() formGroup: FormGroup;
  @Input() name: string;

  required: any = '';

  constructor(private el: ElementRef) {}

  get isRequired() {
    if (this.formGroup.get(this.name).validator) {
      const validator = this.formGroup.get(this.name).validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  eventoClick(): void {
    this.onClick.emit(true);
  }

  eventoFocus(): void {
    this.onFocus.emit(true);
  }

  eventoBlur(): void {
    this.onBlur.emit(true);
  }

  eventoChange(): void {
    this.onChange.emit(this.formGroup.controls[this.name].value);
  }
}
