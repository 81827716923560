import { Component, OnInit } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { AuthService } from "src/app/core/auth/auth.service";

import { IntegracaoService } from "src/app/services/integracao/integracao.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-integracao',
  templateUrl: './integracao.component.html',
  styleUrls: ['./integracao.component.scss']
})
export class IntegracaoComponent implements OnInit {

  code: any;
  state: any;
  integracaoParams = {};
  loading: boolean = false;
  
  constructor(
    private router: Router,
    private integracaoService: IntegracaoService,
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {
    this.loading = true;
    let obj
    obj = localStorage.getItem('integracaoParams');
    this.integracaoParams = JSON.parse(obj);
    console.log(this.integracaoParams);
    this.code = this.integracaoParams['code'];
    this.state = this.integracaoParams['state'];
    this.authService.authenticate(this.code, this.state).subscribe(
      res => {
        Swal.fire({
          title: 'Autenticado com sucesso',
          text: 'Aguarde, checando autorização.',
          showCancelButton: false,
          imageHeight: 100,
          imageUrl: './assets/images/loader/loader.svg',
          imageAlt: 'Loader'
        })
        this.router.events.subscribe((event: Event) => {
          if (event instanceof NavigationEnd) {
            Swal.close();
          }
        })
      },
      err => {
        Swal.fire({
          type: 'error',
          title: 'Ops!',
          text: 'Não foi possível autenticar.',
        }).then(() => {
          this.logoutGovBr()

        })
      },
      () => {
        this.router.navigate(['']);
        Swal.close();
      }
    );
  }

  logoutGovBr() {
    localStorage.removeItem('integracaoParams');

    window.location.href = "https://sso.acesso.gov.br/logout";
    window.location.href = "https://prodata.sefaz.to.gov.br";
  }

  // validarCadastro() {
  //   if (this.dadosFormatados.autorizado == true) {
      // this.router.navigate(['/painel-contribuinte'])
    // } else {
    //   Swal.fire({
    //     type: 'warning',
    //     text: this.dadosFormatados.mensagem
    //   });

    //   setTimeout(() => {
        // this.router.navigate(['/acesso']);
      // }, 2000);
    // }
  // }
}