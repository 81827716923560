import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { UserService } from '../../shared/user/user.service';
import { User } from 'src/app/shared/user/user';
import { environment } from 'src/environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private apiUrl = environment.API_URL;
    
    constructor(private http: HttpClient, private userService: UserService) {}

    authenticate(code, state) {
            // return this.http.get(`https://prodata.giss.com.br/api/integracao-monitoramento/validarToken`, { observe: 'response' }).pipe(
            return this.http.get(`${this.apiUrl}/integracao-monitoramento/${code}/${state}`, { observe: 'response' }).pipe(
                tap(res => {
                    const user = res.body as User;
                    if (user.token) {
                        this.userService.setToken(user.token);
                    }
                })
            );
    }

    autenticarComCertificado() {
        return this.http.get('/api/user', { observe: 'response' }).pipe(
            tap(res => {
                const user = res.body as User;
                this.userService.setToken(user.token);
            })
        );
    }
}
