<button
    class="btn"
    (click)="eventoClick()"
    data-toggle="toggle"
    data-target="target"
    [disabled]="desativado"
    [id]="nome"
    [name]="nome"
    [ngClass]= "classes"
    [routerLink]="url ? [url] : []"
    [type]="tipo">
  <ng-content></ng-content>
</button>
