<div class="custom-file">
  <input 
    (change)="onChange($event)"
    class="custom-file-input" 
    type="file" 
    id="validatedCustomFile" 
    [multiple]="multiple" >
  <label class="custom-file-label" for="validatedCustomFile">
    <span *ngIf="!fileNames.length; else nomeArquivos">
      {{textoSeleione}}
    </span>
    <ng-template #nomeArquivos>
      <span *ngFor="let fileName of fileNames; index as i">
        {{fileName}}<span *ngIf="fileNames.length !== i + 1">, </span>
      </span>
    </ng-template>
  </label>
</div>