<footer class="pcoded-footer" [ngClass]="classes">

  <div class="footer-container row align-items-center">
    <div class="footer-section col">
      <app-logo [image]="false"></app-logo>
    </div>
    <div class="footer-section col">
      <p>
        Desenvolvido por <br>
        <a href="http://www.eiconbrasil.com.br" target="_blank">Eicon Soluções em Conhecimento Público e Privado Ltda.</a>
      </p>
    </div>
    <div class="footer-section col">
      <p>{{ version }}</p>
    </div>  
  </div>  

</footer>