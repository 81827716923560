import { Injectable } from '@angular/core';
import { TabelaCampo } from 'src/app/shared/tabela/tabela-campo';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoService {
    campos: TabelaCampo[];
  constructor() { }
  
    getHeaderTable() {
        return {
            campos: [
                {
                    nome: '',
                    campo:'',
                },
                {
                    nome: 'Cód. da Solicitação',
                    campo:'Cód. da Solicitação'
                },
                {
                    nome: 'Inscrição Estadual',
                    campo:'Inscrição Estadual'
                },
                {
                    nome: 'Razão Social',
                    campo:'Razão Social'
                },
                {
                    nome: 'Solicitante',
                    campo:'Solicitante'
                },
                {
                    nome: 'Situação Solicitação',
                    campo:'Situação Solicitação'
                }
            ]
        }
    }
    getHeaderTableEmpresaModal() {
        return {
            campos: [
                {
                    nome: '',
                    campo:'',
                },
               
                {
                    nome: 'Inscrição Estadual',
                    campo:'Inscrição Estadual'
                },
                {
                    nome: 'Razão Social',
                    campo:'Razão Social'
                },
                
            ]
        }
    }
 
}
