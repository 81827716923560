import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef
} from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-datepicker-interval',
    templateUrl: './datepicker-interval.component.html',
    styleUrls: ['./datepicker-interval.component.scss']
})
export class DatepickerIntervalComponent implements OnInit {
    @Input() id: string;
    @Output() range = new EventEmitter();

    @ViewChild('startDate') startDate: ElementRef;
    @ViewChild('endDate') endDate: ElementRef;

    constructor() {}

    ngOnInit() {
        $('#datepicker_interval')
            .datepicker({
                forceParse: false,
                format: 'dd/mm/yyyy',
                language: 'pt-br'
            })
            .on('changeDate', e => {
                console.log('e', e);
                this.range.emit({
                    start: this.startDate.nativeElement.value,
                    end: this.endDate.nativeElement.value
                });
            });
    }
}
