import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from 'src/app/components/components.module';
import { LoginCertificadoComponent } from './login-certificado.component';

const items = [
  LoginCertificadoComponent
];

@NgModule({
  declarations: items,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule
  ],
  exports: items,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoginCertificadoModule {}
