    <input 
        [id]="id"
        data-slider-id="[id]Slider"
        type="text" 
        [disabled]="desativado"
        data-slider-min="[min]" 
        data-slider-max="[max]" 
        data-slider-step="[step]" 
        data-slider-value="[value]" />

