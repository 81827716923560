<app-page-loader></app-page-loader>

<ng-container *ngIf="!isLogin; else login">
    <!-- <ng-container> -->
    <app-menu></app-menu>
    <app-header></app-header>
    <app-container>
        <router-outlet (activate)="onActivate($event)"></router-outlet>
    </app-container>

    <app-footer></app-footer>
</ng-container>

<ng-template #login>
    <app-loader></app-loader>
    <app-header-login></app-header-login>
    <router-outlet (activate)="onActivate($event)"></router-outlet>

    <app-footer full="true"></app-footer>
</ng-template>
