<div class="modal fade">
  <div
    [ngClass]="classes"
    class="modal-dialog">
    <div class="modal-content">
  
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">{{titulo}}</h5>
        <button type="button" class="close" (click)="confirmarFechamento == true ? confirmaFechamento() : fecha()">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <ng-content></ng-content>
  
    </div>
  </div>
</div>
