<ul class="nav nav-tabs">
    <li *ngFor="let navItem of navItens" class="nav-item">
        <a
            [ngClass]="{
                active: navItem.active,
                'text-uppercase': navItem.uppercase
            }"
            class="nav-link"
            (click)="onTab(navItem.routerLink)"
        >
            {{ navItem.texto }}
        </a>
    </li>
</ul>
