<div class="input-daterange input-group" id="datepicker_interval">
    <input
        #startDate
        type="text"
        class="form-control form-control-sm text-left"
        placeholder="Data Inicial"
        name="start"
        autocomplete="off"
        mask="00/00/0000"
    />
    <input
        #endDate
        type="text"
        class="form-control form-control-sm text-right"
        placeholder="Data Final"
        name="end"
        autocomplete="off"
        mask="00/00/0000"
    />
</div>
