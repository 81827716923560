import { Component, OnInit } from '@angular/core';

import { CatriCoordenadorService } from './catri-coordenador.service';
import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators
} from '@angular/forms';

import { Router } from '@angular/router';

import { ComunicadosService } from '../../services/comunicados/comunicados.service';
import { SweetAlertService } from '../../services/sweet-alert/sweet-alert.service';

import { PageLoaderService } from 'src/app/components/page-loader/page-loader.service';
import { ModalService } from 'src/app/shared/modal/modal.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-catri-coordenador',
    templateUrl: './catri-coordenador.component.html',
    styleUrls: ['./catri-coordenador.component.scss']
})
export class CatriCoordenadorComponent implements OnInit {
    searchFilter: FormGroup;
    comunicado: any;
    agenda: any = [];
    matriculas: any = [];
    monitoramento: any = [];
    monitoramentoEmpresa: any = [];
    formPesquisaEmpresa: any = [];
    gerarDados: any = [];
    dados: any[] = [];
    contributorId = '';

    filtroForm: FormGroup;
    carregado = false;

    dateRange: object;

    formFiltro = new FormGroup({
        tipo: new FormControl('todos'),
        projetosAtivos: new FormControl('')
    });

    formMatriculaEmpresa = new FormGroup({
        projetosTipos: new FormControl(''),
        situacaoMonitoramento: new FormControl('')
    });

    formMatriculaNome = new FormGroup({
        matricula: new FormControl(''),
        nome: new FormControl('')
    });

    formComunicado = new FormGroup({
        para: new FormControl(''),
        assunto: new FormControl(''),
        texto: new FormControl('')
    });

    constructor(
        private CatriCoordenadorService: CatriCoordenadorService,
        private comunicadosService: ComunicadosService,
        private loaderService: PageLoaderService,
        private sweetAlert: SweetAlertService,
        private modalService: ModalService,
        private router: Router,
        private formBuilder: FormBuilder
    ) {}

    ngOnInit() {
        this.searchFilter = new FormGroup({
            filter: new FormControl('2019')
        });

        this.agenda = this.CatriCoordenadorService.getAgenda();
        this.comunicado = this.comunicadosService.getComunicado();
        this.matriculas = this.CatriCoordenadorService.getMatriculas();
        this.monitoramento = this.CatriCoordenadorService.getMonitoramento();
        this.monitoramentoEmpresa = this.CatriCoordenadorService.getMonitoramentoEmpresa();
        this.gerarDadosTabela();
        this.gerarAgenda();
        this.formPesquisaEmpresa = this.formBuilder.group({
            pesquisaEmpresa: ['selecione', [Validators.required]],
            doc: ['', [Validators.required]],
            nome: ['', [Validators.required]],
            razao: ['', [Validators.required]],
            cpf: ['', [Validators.required]],
            situacaoMonitoramento: ['planejada', [Validators.required]]
        });
    }

    help() {
        const html = `<div class="help-content">
                  <div class="row">
                    <div class="col-12">
                      <h6>EM DESENVOLVIMENTO</h6>
                      <p>5 novo projeto onde se está na fase de
                       inclusão/seleção/exclusão a refinamento de filtros de empresa;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>AGUARDO APROVAÇÃO</h6>
                      <p>Projeto enviado à CATRI/Coordenador para que seja aprovado;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>NÃO APROVADO</h6>
                      <p>Projeto analisado pela CATRI/Coordenador e não aprovado. Gera um aviso
                      para a caixa de entrada do responsável pelo projeto;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>A HOMOLOGAR</h6>
                      <p>Projeto aguardando fase de vetar empresas e  homologar o envio aos superiores/orientadores;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>ATIVO</h6>
                      <p>Projeto homologado pela CATRI/Coordenador e dentro do período de validade;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>CANCELADO</h6>
                      <p>Situação na qual o projeto não será enviado para homologação;</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>FINALIZADO</h6>
                      <p>Projeto encerrado, conforme validade do mesmo.</p>
                    </div>
                  </div>



                </div>`;

        this.sweetAlert.mostrar(html, 'info', 'Situação do Projeto', 'OK');
    }

    setRange(range: object) {
        console.log('range', range);
        // this.dateRange = range;
    }

    gerarComunicado() {
        this.modalService.abre('comunicadoModal');
    }

    filter(): void {
        this.loaderService.exibir();
        setTimeout(() => {
            this.loaderService.esconder();
        }, 2000);
    }

    pesquisarMatriculaNome() {
        this.fechaModal('comunicadoModal');
        this.modalService.abre('matriculaNomeModal');
    }

    enviarComunicado() {
        this.modalService.fecha('comunicadoModal');
        this.sweetAlert.mostrar(
            'Comunicado enviado com sucesso!',
            'success',
            'Sucesso!',
            'OK'
        );
    }

    fechaModal(id: string) {
        this.modalService.fecha(id);
    }
    fechaModalComunicado() {
        this.modalService.fecha('comunicadoModal');
    }

    gerarDadosTabela(): void {
        let limit = 10;
        if (this.carregado) {
            limit = 50;
        } else {
            limit = 10;
        }

        this.monitoramento.dados = [];
        for (let i = 0; i < limit; i++) {
            this.monitoramento.dados.push({
                id: 1,
                'Situação do Projeto': { label: `${i}`, isLink: false },
                'Tipo de Projeto': { label: `projeto  ${i}`, isLink: false },
                'Nome do Projeto': { label: `projeto  ${i}`, isLink: true },
                'Validade do Projeto': { label: '-', isLink: false },
                'Total Empresas': { label: '-', isLink: false },
                'Empresas Destribuidas': { label: '-', isLink: false },
                'Quantidade Empresas em Aberto': { label: '-', isLink: false },
                'Quantidade Empresas a Monitorar': {
                    label: '-',
                    isLink: false
                },
                'Quantidade Empresas em Execução': {
                    label: '-',
                    isLink: false
                },
                'Quantidade Empresas Designadas': { label: '-', isLink: false },
                'Quantidade Empresas Devolvidas e Pendentes': {
                    label: '-',
                    isLink: false
                },
                'Quantidade Empresas Anuladas/Vetadas': {
                    label: '-',
                    isLink: false
                },
                'Quantidade Empresas Vencidas': {
                    label: '-',
                    isLink: false
                },
                Critérios: {
                    isLink: true,
                    isIcon: true,
                    icon: 'search'
                }
            });
        }
        this.carregado = !this.carregado;
    }
    gerarAgenda(): void {
        let limit = 10;
        if (this.carregado) {
            limit = 50;
        } else {
            limit = 10;
        }

        this.agenda.dadosAgenda = [];
        for (let i = 2; i < limit; i++) {
            this.agenda.dadosAgenda.push({
                id: 1,
                'Data Inicio': { label: `${i}/08/2019` },
                'Data Fim': { label: `${i}/08/2019` },
                Agenda: { label: `Agendado`, isLink: false },
                Identificador: {
                    label: `Incluso Empresa 06.394.594-0 - nome empresa A`,
                    isLink: false
                },
                Atividade: { label: 'Homologar Inclusão empresas' },
                Solicitante: {
                    label: 'MARISTELA MYRIAN ARAUJO',
                    isLink: false
                },
                'Tipo Medida de Gestão': {
                    label: 'MARISTELA MYRIAN ARAUJO',
                    isLink: false
                },
                Situação: { isStatus: true, status: 'em-desenvolvimento' },
                Aprovar: { icon: 'check', isIcon: true, isLink: true },
                Rejeitar: {
                    icon: 'x',
                    isIcon: true,
                    isLink: true
                },
                Ordem: { label: '3346', isLink: false }
            });
        }
        this.carregado = !this.carregado;
    }

    action(evt) {
        console.log('action', evt);
        if (evt.data.Aprovar) {
            console.log('aprovar', evt.data.Aprovar);
            Swal.fire({
                title: 'Aprovar Solicitação!',
                text: `Deseja realmente aprovar inclusão desta empresa no projeto?`,
                type: 'info',
                showCancelButton: true,
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim'
            }).then(result => {
                console.log('APROVADO SUCCESS!');
            });
        } else if (evt.data.Rejeitar) {
            console.log('aprovar', evt.data.Rejeirar);
            Swal.fire({
                title: 'Rejeirar Solicitação!',
                text: `Deseja realmente rejeitar inclusão desta empresa no projeto?`,
                type: 'info',
                showCancelButton: true,
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim'
            }).then(result => {
                console.log('REJEITADO SUCCESS!');
            });
        }
    }

    help2() {
        const html = `<div class="help-content">
    <div class="row">
      <div class="col-12">
        <h6>PLANEJADA</h6>
        <p>Empresa selecionada pela CATRI/CEPAC para compor o projeto
         de Monitoramento Fiscal e ainda sem designação;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>VETADA</h6>
        <p>A empresa não faz mais parte do projeto, por ter sido vetada
         pelo Orientador ou pelo Supervisor, por motivo justificável.
          Antes da designação do Agente Fiscal;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>PENDENTE</h6>
        <p>Superior gerou o Procedimento Administrativo de Monitoramento Fiscal - PAM,
         a empresa foi distribuida aleatoriamente para Agentes Fiscais, pelo SIGET.
          Aguardando que o Supervisor Confirme ou Altere a designação do Agente Fiscal;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>DESIGNADA</h6>
        <p>O PAM foi emitido e o Agente Fiscal designado. Antes da emissão do
         Mandado de Monitoramento FIscal;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>ANULADA</h6>
        <p>A empresa não faz mais parte do projeto, por ter sido anulada pelo
         Orientador ou pelo Supervisor, por motivo justificável, após ter sido
          designada para Agente Fiscal. Só é possivel anular uma empresa de
           um projeto antes do Agente Fiscal emitir Mandado de Monitoramento;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>DEVOLVIDA/REJEITADA</h6>
        <p>O Agente Fiscal se declara impedido, por motivo justificável,
         de realizar o Monitoramento Fiscal e devolve a empresa do PAM. A empresa,
         com a Situação "Devolvida", será designada para outro Agente Fiscal;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>EM ABERTO</h6>
        <p>Empresa com Mandado de Monitoramento emitido pelo Agente Fiscal.
         Antes do Ciente do Contribuinte;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>EM EXECUÇÃO</h6>
        <p>Monitoramento Fiscal iniciado e dentro do prazo de validade do projeto,
         após o Contribuinte dar Ciência ao Mandado de Monitoramento Fiscal;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>VENCIDA</h6>
        <p>Encerrou-se o período de vigência do Monitoramento Fiscal, o PAM foi iniciado,
         porém o Agente Fiscal não emitiu o Termo de Encerramento;</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6>CONCLUÍDA</h6>
        <p>Emitido o Termo de Encerramento e dada a ciência pelo Contribuinte.</p>
      </div>
    </div>
  </div>`;

        this.sweetAlert.mostrar(
            html,
            'info',
            'Situação do Monitoramento Fiscal',
            'OK'
        );
    }

    setContributor(data: any): void {
        console.log('teste icon:', data);
        this.contributorId = data.id.replace(/[.-\s]/g, '');
        if (this.contributorId === 'icon') {
            this.router.navigate([`orientador-supervisor/visualizar`]);
        }
        // console.log('id pga: ',this.contributorId);
        // this.router.navigate([
        //     `painel-contribuinte/${this.contributorId}/analise-fiscal`
        // ]);
        // this.menuOpcoesService.setMenuStatus(true);
    }

    filtraMatriculaNome() {
      console.log('teste');
    }
}
